import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MyAccountComponent } from './my-account/my-account.component';
import { AccountRoutingModule } from './account-routing.module';
import { MyOrdersComponent } from './my-orders/my-orders.component';
import { MyAddressComponent } from './my-address/my-address.component';
import { ManagePaymentComponent } from './manage-payment/manage-payment.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SharedModule } from '../shared/shared.module';
import { MyReturnsComponent } from './my-returns/my-returns.component';
import { ReturnsListComponent } from './returns-list/returns-list.component';
import { PhonePipe } from '../shared/validation-utilities/phonenumber.pipe';
import { PhoneMaskDirective } from '../shared/validation-utilities/phone-mask.directive';
import { TrackOrderComponent } from './track-order/track-order.component';
import { TrackOrderByNumberComponent } from './track-order-by-number/track-order-by-number.component';

@NgModule({
  declarations: [
    MyAccountComponent,
    MyOrdersComponent,
    MyAddressComponent,
    ManagePaymentComponent,
    MyReturnsComponent,
    ReturnsListComponent,
    PhonePipe,
    PhoneMaskDirective,
    TrackOrderComponent,
    TrackOrderByNumberComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    AccountRoutingModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    SharedModule
  ],
  exports:[
    PhoneMaskDirective, PhonePipe]
})
export class AccountModule { }
