import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/shared/services/config.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataService } from 'src/app/shared/services/data.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DomSanitizer, Title } from '@angular/platform-browser';
declare let $: any;

@Component({
  selector: 'app-my-returns',
  templateUrl: './my-returns.component.html',
  styleUrls: ['./my-returns.component.css']
})
export class MyReturnsComponent implements OnInit {
  currentUser: any;
  returnItemDetails: any;
  imgPath: { url: string; extension: string; defaultImageURL: string; };
  reasonListParams: any[] =
    [
      { id: 0, claimSubCode: "Default", claimSubDescription: "Choose a reason" },
      { id: 1, claimSubCode: "Damaged", claimSubDescription: "Damaged due to poor packaging" },
      { id: 2, claimSubCode: "Wrong", claimSubDescription: "Wrong Item was sent" },
      { id: 3, claimSubCode: "Defective", claimSubDescription: "Item Defective" }
    ];
  claimDetails: { "customerNumber": string; "orderNumber": number; "itemNumber": string; "quantity": number; "comment": string; "claimSubCode": any; "claimSubDescription": any; };
  paymentList: any;
  reasonValue: any;
  maxQty: any;
  claimDetailsForItem: any;
  creditCardSelected: any[];
  tempFile: any;
  returnItemList: any = [];
  systemDetails: string;
  constructor(private _dataService: DataService, private spinner: NgxSpinnerService, private _sanitizer: DomSanitizer, public titleService : Title) {
    this.currentUser = JSON.parse(localStorage.getItem('currentHFGUser'));
    this.returnItemDetails = JSON.parse(localStorage.getItem('selectedReturnOrder'));
    this.titleService.setTitle('My Returns | Homeful Gifts');
    // this.imgPath = this.config_service.getImageUrl();
    this.claimDetails = { "customerNumber": this.currentUser.customerNumber, "orderNumber": this.returnItemDetails.orderNumber, "itemNumber": this.returnItemDetails.itemNumber, "quantity": 1, "comment": "", "claimSubCode": "choose", "claimSubDescription": "" };
    // this.maxQty = this.returnItemDetails.quantity;
    this.maxQty = this.returnItemDetails.claimQty;
    this.reasonValue = this.reasonListParams[0];
    this.systemDetails = localStorage.getItem('systemUniqueIP');
  }

  ngOnInit(): void {
    this.getItemtobeClaimedDetails();
  }

  getItemtobeClaimedDetails(){
    this.spinner.show();
    var req = {
      "customerNumber": this.currentUser.customerNumber,
      "orderNumber": this.returnItemDetails.orderNumber,
      "itemNumber": this.returnItemDetails.itemNumber
    }

    this._dataService.post('Claims/GetClaimItemDetails', JSON.stringify(req))
      .subscribe((response) => {
        this.claimDetailsForItem = response.data[0];
        this.getPaymentCardDetails();
        this.spinner.hide();
      }, (error) => {
        this.spinner.hide();
      });
  }
  ngAfterViewInit() {

    $("#retunrs-summary").hide();
    $(".payment-area").hide();
    $(".continue-summary").hide();
    $(".continue-next").hide();
    $(".continue").click(function () {
      $(".payment-area").show();
      $(".returns-b").hide();
      $(".continue").hide();
      $(".continue-next").show();
    });
    $(".continue-next").click(function () {
      setTimeout(function() {
        $(".payment-area").hide();
        $("#refund").hide();
        $(".returns-b").hide();
        $("#retunrs-summary").show();
        $(".continue").hide();
        $(".continue-next").hide();
        $(".continue-summary").show();
      }, 1500); // Delay time in milliseconds (1000 milliseconds = 1 second)
    });
  }

  onSelectionReason(value, alerts) {

    this.claimDetails.claimSubCode = value.claimSubCode;
    this.claimDetails.claimSubDescription = value.claimSubDescription;
  }

  getPaymentCardDetails() {
    this.spinner.show();
    this.creditCardSelected = [];
    var req = {
      "customerNumber": this.currentUser.customerNumber,
      "deviceID": this.systemDetails
    }
    this._dataService.post('Payments/GetHFGCustomerExistingCCs', JSON.stringify(req))
      .subscribe((response) => {
        this.paymentList = response.data;


        this.paymentList.forEach(item => {
          // item.isChecked = index == 0 ? true : false;
          item.expDt = item.expDt.slice(0, 3) + item.expDt.slice(-2);
          item.expDateFull = new Date("01/" + item.expDt);
          if (item.ccMasked == this.claimDetailsForItem.creditCard.replace("L", "")) {
            item.isChecked = true;
            this.creditCardSelected.push(item)
          }
          else {
            item.isChecked = false;
          }

          // alert(item.expDateFull);
        });

        this.spinner.hide();
      }, (error) => {
        this.spinner.hide();
      });
  }

  addClaim() {
    if (this.claimDetails.comment.length > 1) {
      this.spinner.show();
      this.claimDetails.quantity = this.returnItemDetails.claimQty;
      var imagesList = [];
      if(this.returnItemList.length > 0){
        this.returnItemList.forEach(element => {
          imagesList.push({
            "fileString": element.tempFile.replace(/^data:image\/[a-z]+;base64,/, ""),
            "fileName": element.FileName
          })
        });
      }
      var req = {
        "customerNumber": this.currentUser.customerNumber,
        "orderNumber": this.returnItemDetails.orderNumber,
        "itemNumber": this.returnItemDetails.itemNumber,
        "quantity": this.returnItemDetails.quantity,
        "comment": this.claimDetails.comment,
        "claimSubCode": this.claimDetails.claimSubCode,
        "claimSubDescription": this.claimDetails.claimSubDescription,
        "imagesList": imagesList
      };

      this._dataService.post('Claims/InsertUpdateClaimDetails', JSON.stringify(req))
        .subscribe((response) => {
          this.onClaimSubmitted();
        }, (error) => {
          this.spinner.hide();
        });
    }
    else {
      this._dataService.alert('Please add a valid Comment',"",'info');
    }
  }

  claimQtyCheck() {

    if (this.maxQty < (this.returnItemDetails.quantity - this.returnItemDetails.itemClaimed)) {
      this._dataService.alert('Return Quantity is greater than Ordered Quantity',"",'info');

    }
  }
  onClaimSubmitted() {
    var req = {
      "emailAddress": this.currentUser.loginEmailAddress,
      "customerNumber": this.currentUser.customerNumber,
      "customerName": this.currentUser.firstName,
      "orderNumber": this.returnItemDetails.orderNumber,
      "itemNumber": this.returnItemDetails.itemNumber,
      "reportType": "return"
    }
    this._dataService.post('Report/SendClaimEmail', req)
      .subscribe((response) => {
        this.spinner.hide();
      }, (error) => {
        this.spinner.hide();
        // this._dataService.alert(error.error.message,"","error");
      })
  }
  onFileUpload(event: Event) {
    // Cast the event target to HTMLInputElement to access the files property
    const inputElement = event.target as HTMLInputElement;

    // Convert FileList to Array of Files
    const fileArray: File[] = Array.from(inputElement.files || []);

    fileArray.forEach((element, index) => {
      const fileName = element.name;
      const tmppath = URL.createObjectURL(element);

      // Ensure returnItemList has an item at the current index
      if (!this.returnItemList[index]) {
        this.returnItemList[index] = {}; // Initialize with an empty object
      }

      this.getBase64(element).then(base64 => {
        this.returnItemList[index].Image = base64.toString().replace(/^data:image\/[a-z]+;base64,/, "");
        this.returnItemList[index].ImageUrl = this._sanitizer.bypassSecurityTrustUrl(tmppath);
        this.returnItemList[index].FileName = fileName;
        this.returnItemList[index].tempFile = base64;
      });
    });
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
  errorHandlerForItemImages(event: any) {
    event.target.src = event.target.src.replace("hfg_images/ConsolidatedImages", "images");
  }
}
