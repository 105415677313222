import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap, switchMap, filter } from 'rxjs/operators';
import { ConfigService } from './config.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { ConfirmCartNavComponent } from '../confirm-cart-nav/confirm-cart-nav.component';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

const xmlOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/xml' })
};

let currentUser = JSON.parse(localStorage.getItem('currentHFGUser'));

@Injectable()
export class DataService {

    _base_Url: string = null;
    _YIS_base_Url: string = null;
    _show_Url: string = null;
    constructor(private http: HttpClient, private config_service: ConfigService, public dialog: MatDialog, private updates: SwUpdate) {
        this._base_Url = config_service.getApiUrl();
        this._YIS_base_Url = config_service.getYISApiUrl();
        this._show_Url = config_service.getShowUrl();
        this.updates.versionUpdates.pipe(
            filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY')
        ).subscribe(event => {
            this.promptUser();
        });
    }

    /** GET All */
    getAll(url: string): Observable<any> {
        return this.http.get<any>(this._base_Url + url)
            .pipe(
                tap(data => { return data; }),
                catchError(this.handleError)
            );
    }

    /** GET All from YIS5 services*/
    getAllYIS(url: string): Observable<any> {
        return this.http.get<any>(this._YIS_base_Url + url)
            .pipe(
                tap(data => { return data; }),
                catchError(this.handleError)
            );
    }
    /** GET By Id */
    get(url: string, id: any): Observable<any> {
        return this.http.get<any>(this._base_Url + url + '/' + id)
            .pipe(
                tap(data => { return data; }),
                catchError(this.handleError)
            );
    }

    /** POST */
    post(url: string, req: any): Observable<any> {
        return this.http.post<any>(this._base_Url + url, req, httpOptions)
            .pipe(
                tap(data => { return data; }),
                catchError(this.handleError)
            );
    }

    /** DELETE */
    Delete(url: string, id: any): Observable<any> {
        return this.http.delete<any>(this._base_Url + url + '/' + id)
            .pipe(
                tap(data => { return data; }),
                catchError(this.handleError)
            );
    }

    /** PUT */
    Put(url: string, req: any): Observable<any> {
        return this.http.put(this._base_Url + url, req, httpOptions)
            .pipe(
                tap(data => { return data; }),
                catchError(this.handleError)
            );
    }

    /** POST */
    PostFiles(url: string, req: any): Observable<any> {
        return this.http.post<any>(this._base_Url + url, req)
            .pipe(
                tap(data => { return data; }),
                catchError(this.handleError)
            );
    }

    // PostObject(url: string, req: any): Observable<any> {
    //     return this.http.post<any>(this._base_Url + url, req, httpOptions)
    //         .pipe(
    //             tap(data => { return data; }),
    //             catchError(this.handleError)
    //         );
    // }

    alert(message: string, title: string, type: string, duration?: number) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = false;
        dialogConfig.minWidth = "300px";
        dialogConfig.width = "300px";
        dialogConfig.restoreFocus = false;

        dialogConfig.data = {
            title: title,
            message: message,
            type: type,
            time: duration ?? 3000
        };
        let dialogRef = this.dialog.open(AlertDialogComponent, dialogConfig);
        return dialogRef.afterClosed();
    }

    confirm(message: string, title: string) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = false;
        dialogConfig.width = "300px";
        dialogConfig.height = "125px";
        dialogConfig.restoreFocus = false;
        dialogConfig.data = {
            title: title,
            message: message,
            time: 2000
        };
        let dialogRef = this.dialog.open(ConfirmCartNavComponent, dialogConfig);
        return dialogRef.afterClosed();
    }
    PostDownloadFile(url: string, req: any): Observable<any> {
        var options: any = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            responseType: 'blob',
            observe: 'response'
        }
        return this.http.post(this._base_Url + url, req, options)
            .pipe(
                tap(data => { return data; }),
                catchError(this.handleError)
            );
    }

    GetDownloadFile(url: string): Observable<any> {
        return this.http.get(this._base_Url + url, { responseType: 'blob' })
            .pipe(
                tap(data => { return data; }),
                catchError(this.handleError)
            );
    }

    DownloadFile(url: string,): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/pdf');
        return this.http.get(url, { headers: headers, responseType: 'blob' as 'json' });
    }

    GetXml(url: string): Observable<any> {
        //
        return this.http.get<any>(this._show_Url + url, xmlOptions)
            .pipe(
                tap(data => { return data; }),
                catchError(this.handleError)
            );
    }

    notifyCustomer(customerNumber: string, itemNumber: string, emailAddress: string, phoneNumber: string): Observable<any> {
        const requestPayload = {
            strCustomerNumber: customerNumber,
            strItemNumber: itemNumber,
            strNotifyType: "AVA",
            strEmailAddress: emailAddress,
            strPhoneNumber: phoneNumber
        };

        return this.http.post<any>(this._base_Url + "Items/NotifyCustomerForSelectItem", requestPayload, httpOptions)
            .pipe(
                tap(data => { return data; }),
                catchError(this.handleError)
            );
    }

    subscriptionEmail(emailAddress: string): Observable<any> {
        return this.http.get<any>(this._base_Url + "Items/InsertSubscriberEmail/" + emailAddress)
            .pipe(
                tap(data => { return data; }),
                catchError(this.handleError)
            );
    }



    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param error - error object
     */

    // private handleError(error: HttpErrorResponse) {
    //     if (error.error instanceof ErrorEvent) {
    //         // A client-side or network error occurred. Handle it accordingly.
    //         console.error('An error occurred:', error.error.message);
    //     } else {
    //         // The backend returned an unsuccessful response code.
    //         // The response body may contain clues as to what went wrong,
    //         // console.error(
    //         //     `Backend returned code ${error.status}, ` +
    //         //     `body was: ${error.error}`);
    //         console.log(error);
    //     }
    //     // return an observable with a user-facing error message
    //     return throwError(error);
    // };
    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            //   console.error(
            //     `Backend returned code ${error.status}, ` +
            //     `body was: ${error.error}`);
            console.log(error);
        }
        // return an observable with a user-facing error message
        return throwError(error);
    };
    promptUser(): void {
        if(document.location.hostname != "localhost"){
            if (confirm('A new version of the app is available. Load it?')) {
                this.updates.activateUpdate().then(() => document.location.reload());
            } else {
                setTimeout(() => {
                    this.promptUser();
                }, 60000);
            }
        }
    }
    getTableData(data: any, value: any): Observable<any> {
        if (value === "Pending") {
            if (data === "Reqsent") {
                return this.http.post(`${this._base_Url}/Order/GetHomefulOrders`, { Type: "INC", SubType: "SUP", StartDate: "", EndDate: "" })
            }
            else {
                return this.http.post(`${this._base_Url}/Order/GetHomefulOrders`, { Type: "INC", SubType: "HFG", StartDate: "", EndDate: "" })
            }
        }
        else if (value === "InProgress") {
            return this.http.post(`${this._base_Url}/Order/GetHomefulOrders`, { Type: "PRO", StartDate: "", EndDate: "" })
        }
        else if (value === "Invoiced") {
            return this.http.post(`${this._base_Url}/Order/GetHomefulOrders`, { Type: "INV", StartDate: "", EndDate: "" })
        }
        else if (value === "Cancelled Orders") {
            return this.http.post(`${this._base_Url}/Order/GetHomefulOrders`, { Type: "CAN", StartDate: "", EndDate: "" });
        }
        else {
            return this.http.post(`${this._base_Url}/Order/GetHomefulOrders`, { Type: "ALL", StartDate: "", EndDate: "" })
        }
    }
    getProductData() {
        return this.http.get(`${this._base_Url}/Order/GetAllItemDetails`)
    }

    getOrderDetails(id: any, num: any,) {
        return this.http.get(`${this._base_Url}/Order/AdminGetOrderDetails/${num}/${id}`)
    }

    updateOrders() {
        return this.http.get(`https://www.youngsinc.com/HomefulGifts_SERVICES_TEST/order/TransferCustomerOrdersToSuppliers`);
    }
    cancelItem(customerNum: any, orderNum: any, itemNum: any, reason: any) {
        return this.http.post(`${this._base_Url}/inventory/AdminCancelOrderItem`, {
            customerNumber: customerNum,
            orderNumber: orderNum,
            itemNumber: itemNum,
            reason: reason,
        });
    }
    downloadReport(body: any): Observable<any> {
        return this.http.post(`${this._base_Url}/Report/GenerateOrderDetailReport`, body, { responseType: 'blob' })
    }
    updateExcel(data: any): Observable<any> {
        return this.http.post(`${this._base_Url}/inventory/UpdateInventoryForSelectedVendorItem`, data)
    }
}
