<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
<div>
    <div class="breadcrumb-area pt-10 pb-10 bg-gray-3">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12 nopadding">
                    <div class="breadcrumb-content text-left">
                        <ul>
                            <li>
                                <a routerLink="/">Home</a>
                            </li>
                            <li>
                                <!-- <a href="#">My Account</a> -->
                                <a href="javascript:void(0);" routerLink="/account/my-account">My Account</a>

                            </li>
                            <li class="active">Manage Payments </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="shop-area pt-15 pb-100 section-padding-1 section-product">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-3 col-plgc-3 nopadding">
                    <div class="sidebar-style mr-30">

                        <div class="sidebar-widget">
                            <h4 class="title-1">My Account </h4>
                            <div class="sidebar-widget-list sidebar-category mt-20">
                                <ul>
                                    <li>
                                        <a routerLink="/account/my-account">Account Info</a>
                                    </li>
                                    <li>
                                        <a routerLink="/account/my-orders">Orders</a>
                                    </li>
                                    <li>
                                        <a routerLink="/account/my-address">Manage Addresses</a>
                                    </li>
                                    <li class="active">
                                        <a routerLink="/account/manage-payment">Manage Payments</a>
                                    </li>
                                    <!-- <li>
                                        <a routerLink="/account/my-returns">My Returns</a>
                                    </li> -->
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-lg-9 col-plgc-9 nopadding">
                    <div class="row">
                        <div class="col-lg-8 col-xl-7">
                            <div class="billing-info-wrap payment-area">
                                <!-- <div class="address-title">
                                    <h3 class="title-1">Payment Methods</h3>
                                    <div *ngIf="!isAddNewCard" class="cust-btn-2 address-add-btn pull-right" style="padding-top: 20px;">
                                        <a class="" id="AddNewCard" (click)="isAddNewCard =!isAddNewCard">Add New
                                            Card</a>
                                    </div>
                                </div> -->

                                <div class="address-title overflow-hidden" style="padding-top: 20px">
                                    <h3 class="title-1 pull-left">Payment Methods</h3>
                                    <div *ngIf="!isAddNewCard && !isEditCC" class="cust-btn-2 address-add-btn pull-right">
                                        <a class="" (click)="isAddNewCard = !isAddNewCard">Add Card</a>&nbsp;&nbsp;
                                    </div>
                                </div>

                                <div class="cards-list">
                                    <p>We accept following credit cards:&nbsp;&nbsp;
                                        <img class="d-inline-block align-middle" src="assets/images/cards.png"
                                            style="width: 180px;" alt="Credit Cards"></p>
                                </div>
                                <div class="payment-options">
                                    <div class="cards-list" *ngIf="!isAddNewCard&&!isEditCC">
                                        <div style="padding-left: 50%" *ngIf="paymentList?.length == 0">No Cards found
                                        </div>
                                        <table>
                                            <tbody>
                                                @for(cardDetails of paymentList;track cardDetails;let i = $index){
                                                <tr>
                                                    <div>
                                                        <td width="10">
                                                        </td>
                                                        <td width="390">
                                                            <h4 class="cc-number">{{cardDetails.creditCard_}}
                                                                <span class="recommended_txt"
                                                                    *ngIf="i==0">&nbsp;&nbsp;(recommended)</span>
                                                            </h4>
                                                            <span>{{cardDetails.cardName| slice:0:10}} </span><span
                                                                class="exp">{{cardDetails.expDt}}</span>
                                                            <!-- <div class="form-group mt-10">
                                                                                                        <input type="text" class="form-control" id="cvv"
                                                                                                            placeholder="CVV" style="width:100px;">
                                                                                                    </div> -->
                                                        </td>
                                                        <td>
                                                            <!-- <img src="assets/images/visa.png" /> -->
                                                            <img *ngIf="cardDetails.type=='VIS'" src="assets/images/visa.png" />
                                                            <img *ngIf="cardDetails.type=='MAS'" src="assets/images/mastercard.png" />
                                                            <img *ngIf="cardDetails.type=='DIS'" src="assets/images/discover.png" />
                                                            <img *ngIf="cardDetails.type=='AMX'" src="assets/images/americaexpress.png" />
                                                        </td>
                                                        <td class="product-remove" width="140">
                                                            <a href="javascript:void(0)" class="pull-right trash"
                                                                (click)="onDeleteClick(cardDetails)"
                                                                *ngIf="cardDetails.canDelete !=0"><i
                                                                    class="fa fa-trash"></i></a>
                                                            <a href="javascript:void(0)" class="pull-right edit"
                                                                (click)="onEditCC(cardDetails)"><i
                                                                    class="fa fa-edit"></i></a>
                                                        </td>
                                                    </div>
                                                </tr>
                                                }</tbody>
                                        </table>
                                    </div>
                                    <div class="cards-list" id="NewCard" *ngIf="isAddNewCard">
                                        <form class="mt-10" [formGroup]="paymentForm">
                                            <h4>Enter Card Details</h4>
                                            <div class="row">
                                                <div class="col-lg-6 pull-left">
                                                    <div class="form-group">
                                                        <label>Card Number<sup>*</sup></label>
                                                        <input type="text" class="form-control" id="cardnumber"
                                                            formControlName="cardnumber" placeholder="Card Number"
                                                            name="cardnumber" maxlength="19" [(ngModel)]="cardNumber"
                                                            (ngModelChange)="onCreditCardChange($event)"
                                                            (keypress)="numberOnly($event)">
                                                        <show-errors [control]="paymentForm.controls.cardnumber">
                                                        </show-errors>

                                                    </div>
                                                </div>
                                                <div class="col-lg-6 pull-left">
                                                    <div class="form-group">
                                                        <label>Full name<sup>*</sup></label>
                                                        <input type="text" class="form-control" id="fullname"
                                                            formControlName="fullname" placeholder="Full Name"
                                                            name="fullname" maxlength="30">
                                                        <show-errors [control]="paymentForm.controls.fullname">
                                                        </show-errors>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                            <div class="col-lg-4 pull-left">
                                                <div class="form-group">
                                                    <label>Expiry Date<sup>*</sup></label>
                                                    <input type="text" class="form-control" id="expirydate"
                                                        placeholder="MM/YY" [(ngModel)]="expirydate" name="expirydate"
                                                        formControlName="expirydate" (keypress)="numberOnly($event)"
                                                        (ngModelChange)="expiryDateInput(expirydate?.length)"
                                                        maxlength="5">
                                                    <show-errors [control]="paymentForm.controls.expirydate">
                                                    </show-errors>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 pull-left">
                                                <div class="form-group">
                                                    <label>CVV<sup>*</sup></label>
                                                    <input type="text" class="form-control" id="cvv"
                                                        formControlName="cvv" placeholder="CVV" name="cvv" maxlength="3"
                                                        (keypress)="numberOnly($event)">
                                                    <show-errors [control]="paymentForm.controls.cvv">
                                                    </show-errors>
                                                </div>
                                            </div>
                                            <div class="clearfix"></div>
                                            <div class="col-lg-12">
                                            <div class="checkout-account-toggle address-add-btn-2 mb-30">
                                                <div class="cust-btn">
                                                    <button class="btn-hover checkout-btn" type="submit"
                                                        (click)="addNewCreditCard()"
                                                        [disabled]="!paymentForm.valid || selectedBillAddress?.length==0 || !isValidExpiryDate">
                                                        Add Card</button>&nbsp;
                                                    <button class="btn-hover checkout-btn cancel-btn" type="submit"
                                                        (click)="isAddNewCard =!isAddNewCard;cancelClick()">Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                            </div>
                                            <div></div>
                                            <table class="table table-responsive">
                                                <tbody>
                                                    @for (billAddress of bilAddressInfolist| slice:0:15;track billAddress;let index = $index ) {
                                                    <tr>
                                                        <td width="10%">
                                                            <label class="container">
                                                                <input style="width: 20px" type="checkbox"
                                                                    name="check{{index}}"
                                                                    [(ngModel)]="billAddress.isSelected"
                                                                    (change)="deactivate(index, billAddress.isSelected)"
                                                                    [ngModelOptions]="{standalone: true}" />
                                                                <span class="checkmark"></span>
                                                            </label>
                                                            <!-- <label>
                                                                <input type="radio" class="option-input radio" name="example" id="{{billAddress.hgCustomerAddressID}}" name="radio{{index}}"
                                                                    [(ngModel)]="selectedBillAddress?.hgCustomerAddressID" [value]="billAddress?.hgCustomerAddressID" />
                                                            </label> -->
                                                        </td>
                                                        <!-- <td width="10%">
                                                            <label>
                                                                <input type="radio" class="option-input radio"
                                                                    name="example"
                                                                    [checked]="billAddress.mainAddress == 1" />
                                                            </label>
                                                        </td> -->
                                                        <td width="90%">
                                                            <div class="address-name overflow-hidden">
                                                                <h4 class="pull-left">{{billAddress.contactName}}
                                                                    <span *ngIf="billAddress.mainAddress == 1"
                                                                        class="recommended_txt">&nbsp;&nbsp;(Default
                                                                        Address)</span> </h4>
                                                            </div>

                                                            <div class="addres-content">
                                                                <div class="add-icon">
                                                                    <i class="fa fa-map-marker"></i>
                                                                </div>
                                                                <div class="add-text">
                                                                    <p>{{billAddress.addressNumber}}
                                                                        {{billAddress.address1}}, {{billAddress.state}}
                                                                        {{billAddress.zipCode}}</p>
                                                                </div>
                                                            </div>
                                                            <div class="addres-content pull-left m-rgt-20">
                                                                <div class="add-icon">
                                                                    <i class="fa fa-phone"></i>
                                                                </div>
                                                                <div class="add-text mar-right">
                                                                    <p>{{billAddress.phoneNumber}} </p>
                                                                </div>
                                                            </div>
                                                            <div class="addres-content pull-left">
                                                                <div class="add-icon">
                                                                    <i class="fa fa-envelope"></i>
                                                                </div>
                                                                <div class="add-text">
                                                                    <p>{{(billAddress?.emailAddress == null) || (billAddress?.emailAddress == '') ? 'No Email Provided' : billAddress?.emailAddress}}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    }</tbody>
                                            </table>
                                        </form>
                                    </div>
                                  
                                    <div class="cards-list" *ngIf="isEditCC">
                                        <h4>Edit Card Details</h4>

                                        <div class="col-lg-6 pull-left">
                                            <div class="form-group">
                                                <label>Card Number<sup>*</sup></label>
                                                <input type="text" class="form-control" id="cardnumber"
                                                    [(ngModel)]="createCC.cardnumber" placeholder="Card Number"
                                                    name="cardnumber" max="11" disabled>
                                                <show-errors [control]="paymentForm.controls.cardnumber">
                                                </show-errors>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 pull-left">
                                            <div class="form-group">
                                                <label>Full Name<sup>*</sup></label>
                                                <input type="text" class="form-control" id="fullname"
                                                    [ngModel]="createCC.fullname | titlecase"
                                                    (ngModelChange)="createCC.fullname=$event" placeholder="Full Name"
                                                    name="fullname" maxlength="30">
                                                <show-errors [control]="paymentForm.controls.fullname">
                                                </show-errors>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 pull-left">
                                            <div class="form-group">
                                                <label>Expiry Date<sup>*</sup></label>
                                                <input type="text" class="form-control" id="expirydate"
                                                    placeholder="MM/YY" [(ngModel)]="createCC.expirydate"
                                                    name="expirydate" maxlength="5" (keypress)="numberOnly($event)"
                                                    (ngModelChange)="expiryDateInputEdit(createCC.expirydate?.length)">
                                                <show-errors [control]="paymentForm.controls.expirydate"> </show-errors>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 pull-left">
                                            <div class="form-group">
                                                <label>CVV<sup>*</sup></label>
                                                <input type="text" class="form-control" id="cvv"
                                                    [(ngModel)]="createCC.cvv" placeholder="CVV" name="cvv"
                                                    maxlength="4" (keypress)="numberOnly($event)">
                                                <show-errors [control]="paymentForm.controls.cvv">
                                                </show-errors>
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                        <div class="checkout-account-toggle address-add-btn-2 mb-30">
                                            <div class="cust-btn col-lg-12">
                                                <button class="btn-hover checkout-btn" type="submit"
                                                    (click)="updateCreditCard()"
                                                    [disabled]="createCC.fullname?.length<3||createCC.expirydate?.length<5||createCC.cvv?.length<3">Update
                                                    Card</button>&nbsp;
                                                <!-- <a class="btn-hover" href="javascript:void(0);" id="CardAdded" [disabled]="!paymentForm.valid"
                                                        (click)="isEditCC =!isEditCC;updateCreditCard()">Edit
                                                        Card</a>&nbsp; -->
                                                <button class="btn-hover checkout-btn cancel-btn" type="submit"
                                                    (click)="isEditCC =!isEditCC">Cancel</button>
                                            </div>
                                        </div>
                                        <table class="table table-responsive">
                                            <tbody>
                                                @for (billAddress of bilAddressInfolist| slice:0:15; track billAddress; let index = $index) {
                                                <tr>
                                                    <td width="10%">
                                                        <label class="container">
                                                            <input style="width: 20px" type="checkbox"
                                                                name="check{{index}}"
                                                                [(ngModel)]="billAddress.isSelected"
                                                                (change)="deactivate(index, billAddress.isSelected)"
                                                                [ngModelOptions]="{standalone: true}" />
                                                            <span class="checkmark"></span>
                                                        </label>
                                                        <!-- <label>
                                                                <input type="radio" class="option-input radio" name="example" id="{{billAddress.hgCustomerAddressID}}" name="radio{{index}}"
                                                                    [(ngModel)]="selectedBillAddress?.hgCustomerAddressID" [value]="billAddress?.hgCustomerAddressID" />
                                                            </label> -->
                                                    </td>
                                                    <!-- <td width="10%">
                                                            <label>
                                                                <input type="radio" class="option-input radio"
                                                                    name="example"
                                                                    [checked]="billAddress.mainAddress == 1" />
                                                            </label>
                                                        </td> -->
                                                    <td width="90%">
                                                        <div class="address-name overflow-hidden">
                                                            <h4 class="pull-left">{{billAddress.contactName}}
                                                                <span *ngIf="billAddress.mainAddress == 1"
                                                                    class="recommended_txt">&nbsp;&nbsp;(Default
                                                                    Address)</span> </h4>
                                                        </div>

                                                        <div class="addres-content">
                                                            <div class="add-icon">
                                                                <i class="fa fa-map-marker"></i>
                                                            </div>
                                                            <div class="add-text">
                                                                <p>{{billAddress.addressNumber}}
                                                                    {{billAddress.address1}}, {{billAddress.state}}
                                                                    {{billAddress.zipCode}}</p>
                                                            </div>
                                                        </div>
                                                        <div class="addres-content pull-left">
                                                            <div class="add-icon">
                                                                <i class="fa fa-phone"></i>
                                                            </div>
                                                            <div class="add-text mar-right">
                                                                <p>{{billAddress.phoneNumber}} </p>
                                                            </div>
                                                        </div>
                                                        <div class="addres-content pull-left">
                                                            <div class="add-icon">
                                                                <i class="fa fa-envelope"></i>
                                                            </div>
                                                            <div class="add-text">
                                                                <p>{{(billAddress?.emailAddress == null) || (billAddress?.emailAddress == '') ? 'No Email Provided' : billAddress?.emailAddress}}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                }</tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<!-- Modal -->