import { Component, OnInit, AfterViewInit, ViewChild, TemplateRef } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DataService } from 'src/app/shared/services/data.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfigService } from 'src/app/shared/services/config.service';
import { CountService } from 'src/app/shared/services/count.service';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as $ from 'jquery';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, AfterViewInit {
  return: any;
  redirectMessage: any;
  header: any;
  loginForm: FormGroup;
  public loading = false;
  public errorMessage = '';
  islogin = false;
  customerInfo: any;
  currentTempUser: any;
  websiteTestURL: string;
  websiteLiveURL: string;
  systemDetails: string ="";
  shoppingCartList: any = [];
  hostEnvironment: any;
  previousPageURL: any;
  @ViewChild('orderDetailGatherPopup') orderDetailGatherPopup: TemplateRef<any>;
  dialogRef: any;
  customerEmail: any;
  orderNumber: any;
  constructor(private fb: FormBuilder, private configService: ConfigService, private router: Router, public dialog: MatDialog, private dataService: DataService, private spinner: NgxSpinnerService, private countService: CountService, public titleService : Title) {
    this.titleService.setTitle('Forgot Password | Homeful Gifts');
    this.loginForm = this.fb.group({
      userName: [null, [Validators.required, Validators.pattern(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/)]],
      password: [null, Validators.required]
    });
    this.websiteTestURL = this.configService.getWebTestUrl();
    this.websiteLiveURL = this.configService.getWebLiveUrl();
    this.hostEnvironment = JSON.parse(localStorage.getItem('hostEnvironment'));
    this.redirectMessage = JSON.parse(localStorage.getItem('navMessage'));
    this.return = JSON.parse(localStorage.getItem('return'));
  }

  ngOnInit() {
    this.currentTempUser = JSON.parse(localStorage.getItem('currentHFGUser'));
    this.previousPageURL = localStorage.getItem('previousPageURL');
    if (this.currentTempUser.guestCustomer !== 1) {
      this.router.navigate(['/']);
    }
    this.getShoppingCartItems();
    this.systemDetails = localStorage.getItem('systemUniqueIP');
  }

  ngAfterViewInit() {
    $(document).ready(() => {
      $(".preloader").fadeOut();
      $(".toggle-password").click(function () {
        $(this).toggleClass("fa-eye fa-eye-slash");
        const input = $($(this).attr("toggle"));
        input.attr("type", input.attr("type") === "password" ? "text" : "password");
      });
    });
  }

  // Method to handle user login
  async logIn() {
    if (this.loginForm.invalid) {
      this.errorMessage = "Form is invalid. Please check the inputs.";
      return;
    }
    this.spinner.show();
    const req = {
      login: this.loginForm.value.userName,
      pwd: this.loginForm.value.password,
      deviceID: null,
      deviceName: null,
      deviceOSVersion: null
    };

    try {
      const response = await this.dataService.post('app/ValidateLogin', JSON.stringify(req)).toPromise();
      if (response[3] === "False") {
        this.spinner.hide();
        this.dataService.alert('Invalid EmailID & Password', '', 'info');
      } else {
        this.getUserInfo(response[0]);
      }
    } catch (error) {
      this.spinner.hide();
      this.dataService.alert('Invalid EmailID & Password','','error');
    }
  }

  // Method to handle forgot password click
  forgotPasswordClick() {
    if (!this.loginForm.value.userName) {
      this.errorMessage = "Please Enter Customer Number/Email";
    } else {
      this.router.navigate(['/login/forgot-password']);
    }
  }

  // Method to get user information after login
  async getUserInfo(customerNumber: string) {
    const req = { customerNumber };

    try {
      const response = await this.dataService.post('app/GetHFCustomerGeneralInfo', req).toPromise();
      this.customerInfo = response.data[0];
      localStorage.setItem('currentHFGUser', JSON.stringify(this.customerInfo));
      // this.countService.setCartItems();
      this.countService.setCounts();
      this.countService.setTotals();

      if (!this.currentTempUser) {
        await this.router.navigate(['/']);
        window.location.reload();
      } else if (this.currentTempUser.guestCustomer !== undefined) {
        if (this.shoppingCartList.length > 0 && this.customerInfo.cartCount > 0) {
          this.spinner.hide();
          const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: { title: "Merge Products to Cart", msg: "Merge Products to the Existing Cart?", ok: 'Merge', cancel: 'Discard Login-Account Cart' },
            width: 'auto',
            height: 'auto',
            disableClose: true,
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result !== undefined) {
              this.transferDataFromGuestToCustomer(1);
            } else {
              this.transferDataFromGuestToCustomer(0);
            }
          });
        } else {
          this.transferDataFromGuestToCustomer(1);
        }
      } else {
        await this.router.navigate(['/']);
        window.location.reload();
      }
      this.loginForm.reset();
    } catch (error) {
      this.spinner.hide();
      this.dataService.alert(error.error.message,'responce','error');
    }
  }

  // Method to transfer data from guest to customer
  transferDataFromGuestToCustomer(type: number): void {
    const transferDetails = {
      deviceID: this.systemDetails,
      guestCustomerNumber: this.currentTempUser.customerNumber,
      customerNumber: this.customerInfo.customerNumber,
      insertPreviousCartInfo: type
    };

    this.dataService.post('app/TransferGuestDataToCustome', JSON.stringify(transferDetails))
      .subscribe(
        response => {
          if (this.shoppingCartList.length > 0) {
            this.InsertUpdateCompleteOrderDetails();
          } else {
            // if (this.previousPageURL) {
            //   this.router.navigate([this.previousPageURL]).then(() => {
            //     window.location.reload();
            //   });
            // } else {
            //   this.router.navigate(['/']).then(() => {
            //     window.location.reload();
            //   });
            // }
            this.router.navigate(['/']).then(() => {
              window.location.reload();
            });
          }
        },
        error => {
          this.spinner.hide();
        }
      );
  }


  // Method to get shopping cart items
  async getShoppingCartItems() {
    const cartItemsReq = { customerNumber: this.currentTempUser.customerNumber };
    try {
      const response = await this.dataService.post('Cart/GetShoppingCart', JSON.stringify(cartItemsReq)).toPromise();
      this.shoppingCartList = response.data;
    } catch (error) {
      if (error.status === 300) {
        this.shoppingCartList = [];
      }
      this.spinner.hide();
    }
  }

  // Method to insert/update complete order details
  InsertUpdateCompleteOrderDetails() {
    const req = {
      customerNumber: this.customerInfo.customerNumber,
      billAddressNumber: 0,
      shipAddressNumber: 0,
      creditCardID: 0,
      freightTerms: null,
      freightAmount: 0,
      paymentTerms: "CC",
      ischeckOut: 1,
      shippingNote: null
    };

    this.dataService.post('Order/InsertUpdateCompleteOrderDetails', JSON.stringify(req)).subscribe((response) => {
        // Successful POST request
        // const targetUrl = this.previousPageURL ? this.previousPageURL : '/';

        debugger;

        if (response && response.couponRemovedMessage && response.couponRemovedMessage.length > 0) {
          this.dataService.alert(response.couponRemovedMessage, '', 'info', 2000);

          setTimeout(() => {
            this.router.navigate(['/']).then(() => {
              window.location.reload();
            });
          }, 2000);
        } else {
          this.router.navigate(['/']).then(() => {
            window.location.reload();
          });
        }
      },
      (error) => {
        // Error during POST request
        this.spinner.hide();
      }
    );
  }

  onContinueGuestClick(){
    this.dialogRef = this.dialog.open(this.orderDetailGatherPopup);
  }
  getOrderDetails(){
    var req = {
      "customerEmail": this.customerEmail,
      "orderNumber": this.orderNumber
    }
    localStorage.setItem('orderTrackingDetails', JSON.stringify(req));
    var value = window.btoa(this.customerEmail + "/" + this.orderNumber);
    this.router.navigate(['/account/my-orders', value]);
  }
}
