import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MyAccountComponent } from './my-account/my-account.component';
import { MyOrdersComponent } from './my-orders/my-orders.component';
import { MyAddressComponent } from './my-address/my-address.component';
import { ManagePaymentComponent } from './manage-payment/manage-payment.component';
import { MyReturnsComponent } from './my-returns/my-returns.component';
import { ReturnsListComponent } from './returns-list/returns-list.component';
import { TrackOrderComponent } from './track-order/track-order.component';
import { TrackOrderByNumberComponent } from './track-order-by-number/track-order-by-number.component';


const routes: Routes = [
  {
    path: '', children: [
      {
        path: 'my-account', component: MyAccountComponent
      },
      {
        path: 'my-orders', component: MyOrdersComponent
      },
      {
        path: 'my-orders/:orderDetail', component: MyOrdersComponent
      },
      {
        path: 'my-address', component: MyAddressComponent
      },
      {
        path: 'manage-payment', component: ManagePaymentComponent
      },
      {
        path: 'my-returns', component: MyReturnsComponent
      },
      {
        path: 'returns-list', component: ReturnsListComponent
      },
      {
        // path: 'track-order', component: TrackOrderComponent
        path: 'track-orders/:orderNumber', component: TrackOrderComponent

      },
      {
        path: 'track-by-number', component: TrackOrderByNumberComponent
      }
    ]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountRoutingModule { }
