<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>

<div [ngClass]="[showMainDiv ? 'tab-menu-block mb-20':'tab-menu-block mb-40 disable-area']" style="padding-top: 10px;" #subItems>
    <div class="tab-menu-heading"
        *ngIf="selectedMenuDetails.childValue!=undefined && selectedMenuDetails.childValue!='Home-Screen Category'">

        <h2
            *ngIf="selectedMenuDetails.childValue != 'TOP' && selectedMenuDetails.childValue != 'BEST' && selectedMenuDetails.childValue != 'NEW' && selectedMenuDetails.childValue != 'recommendedItems'">
            {{selectedMenuDetails.childValue}}</h2>
        <h2 *ngIf="selectedMenuDetails.childValue == 'NEW'">{{selectedMenuDetails.childValue}} ARRIVALS</h2>
        <h2 *ngIf="selectedMenuDetails.childValue == 'BEST'">{{selectedMenuDetails.childValue}} SELLER</h2>
        <h2 *ngIf="selectedMenuDetails.childValue == 'TOP'">{{selectedMenuDetails.childValue}} RECOMMENDED</h2>
        <h2 *ngIf="selectedMenuDetails.childValue == 'recommendedItems'">Recommended Items</h2>

    </div>
    <div class="tab-menu-heading"
        *ngIf="selectedMenuDetails.childValue!=undefined && selectedMenuDetails.childValue=='Home-Screen Category'">
        <h2>Category</h2>
    </div>
    <div class="tab-menu-cat">
        <div class="tab-menu-img" *ngFor="let subCategory of selectedSubCategoryList">
            <a (click)="reAssignSelectedSubMenu(subCategory, i)">
                <img [src]="subCategory.imageUrl" class="img-responsive">
                <span class="mt-10">{{subCategory.subCategoryName}}</span>
            </a>
        </div>
    </div>
    <div class="tab-menu-cat"
        *ngIf="selectedMenuDetails.childValue != 'ALL' && selectedMenuDetails.childValue != 'BEST' && selectedMenuDetails.childValue != 'NEW'">
        @for(subMenu of subMenuList; track subMenu;let i = $index) {
        <div class="tab-menu-img">
            <a (click)="reAssignSelectedSubMenu(subMenu,i);">
                <img [src]="categoryImgPath.url+subMenu?.childValue+categoryImgPath.extension" class="img-responsive"
                    (error)="errorHandlerForItemImages($event)" *ngIf="selectedMenuDetails.childValue != 'Made in America'">
                <img [src]="categoryImgPath.url+subMenu?.childValue+categoryImgPath.extension" class="img-responsive"
                    (error)="errorHandlerForItemImages($event)" *ngIf="selectedMenuDetails.childValue == 'Made in America'">
                <span class="mt-10">{{subMenu.childValue}}</span>
            </a>
        </div>
        }
    </div>
</div>

<div class="breadcrumb-area pt-10 pb-10 bg-gray-3" #productSection>
    <div class="container-fluid">
        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-7 nopadding">
                <div class="breadcrumb-content text-left mrg-top-7">
                    <ul
                        *ngIf="selectedMenuDetails!='search-bar' && selectedMenuDetails!='best-seller' && !otherCategoriesBreadcrumb && !isFullWebsiteForBreadCrumb">
                        <li>
                            <a routerLink="/">Home</a>
                        </li>
                        <li>
                            <a href="javascript:void(0)"
                                *ngIf="selectedMenuDetails.childValue!='Home-Screen Category'">{{selectedMenuDetails.childValue}}</a>
                            <a *ngIf="selectedMenuDetails.childValue=='Home-Screen Category'">Category</a>
                        </li>
                        <li class="active" *ngIf="selectedSubCategoryName != null && selectedSubCategoryName != ''">{{selectedSubCategoryName}} </li>
                    </ul>
                    <ul *ngIf="selectedMenuDetails=='search-bar' && !isFullWebsiteForBreadCrumb">
                        <li>
                            <a routerLink="/">Home</a>
                        </li>
                        <li class="active">{{descriptionLabel}} </li>
                    </ul>
                    <ul *ngIf="selectedMenuDetails=='best-seller' && !isFullWebsiteForBreadCrumb">
                        <li>
                            <a routerLink="/">Home</a>
                        </li>
                        <li class="active">Most Popular</li>
                    </ul>
                    <ul *ngIf="otherCategoriesBreadcrumb && !isFullWebsiteForBreadCrumb">
                        <li>
                            <a routerLink="/">Home</a>
                        </li>
                        <li class="active">{{selectedMenuDetails.childValue}} </li>
                    </ul>
                    <ul *ngIf="isFullWebsiteForBreadCrumb">
                        <li>
                            <a routerLink="/">Home</a>
                        </li>
                        <li class="active">All Categories </li>
                    </ul>
                </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 nopadding">
                <div class="row">
                    <div class="col-xl-8 col-lg-8 col-md-6 col-sm-12 col-xs-12">
                        <div class="pull-right">
                            <div class="form-group mb-0 filter-grp">
                                <div class="d-flex flex-row">
                                    <div class="d-flex">
                                        <label for="exampleFormControlSelect1"
                                    class="col-form-label nopadright text-right">Sort&nbsp;&nbsp;</label>
                                    </div>
                                    <div class="d-flex">
                                        <select class="form-control" id="exampleFormControlSelect1" name="defaultSort"
                                        [(ngModel)]="defaultSort"
                                        (change)="onFilterSelect(selectedSubCategoryID,selectedSubCategoryIndex,defaultSort)">
                                        <option *ngFor="let sortOption of sort" [ngValue]="sortOption.sortValue">
                                            {{sortOption.sortDesc}}</option>
                                    </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                        <div class="pull-right">
                            <div class="item-count">
                                <p>Item Count: <span>{{subMenuItemsList?.length}}</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div
    [ngClass]="[showMainDiv ? 'shop-area pt-15 pb-100 section-padding-1 bg-white':'shop-area pt-15 pb-100 section-padding-1 bg-white disable-area']">
    <div class="container-fluid">
        <div class="row" style="margin-left:0px;margin-right:0px;">
            <div class="col-lg-12 col-xl-3 col-md-12">
                <div class="sidebar-style clearfix">
                    <div class="col-md-6 col-xl-12 pull-left">
                        <div class="sidebar-widget">
                            <h4 class="pro-sidebar-title title-1">Filter By Item</h4>
                            <div class="sidebar-widget-list mt-10">
                                <ul>
                                    <li>
                                        <div class="">
                                            <label class="control-label">Description</label>
                                            <input type="text" [(ngModel)]="filterDescription"
                                                (keydown.enter)="onFilterSelect(selectedSubCategoryID,selectedSubCategoryIndex,defaultSort)"
                                                class="form-control" placeholder="Enter Description" name="description">
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="sidebar-widget mt-30">
                            <h4 class="pro-sidebar-title title-1">Filter By Price</h4>
                            <div class="price-filter mt-20 row">
                                <div class="col-md-12 col-xl-12 nopadding">
                                    <div class="range-slider">
                                        <div class="slider-value" [style.left]="getSliderValuePosition()">{{ maxPrice | currency }}</div>
                                        <input type="range" min="0" [max]="maxSliderPrice" step="1" [(ngModel)]="maxPrice" class="custom-slider">
                                        <div class="slider-labels">
                                            <span>{{minPrice | currency}}</span>
                                            <span>{{maxSliderPrice | currency}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="sidebar-widget mt-30">
                            <figcaption>
                                <a class="filter-button"
                                    (click)="onFilterSelect(selectedSubCategoryID,selectedSubCategoryIndex,defaultSort)">Filter</a>
                                <a class="filter-button" (click)="clearFilter()">Clear Filter</a>
                            </figcaption>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-12 pull-left">
                        <div class="new-product-display" *ngIf="currentProduct">
                            <h2>RECOMMENDED</h2>
                            <div class="container">
                                <owl-carousel-o [options]="customOptionsRecentlyViewed">
                                    <ng-template carouselSlide *ngFor="let item of products; let index = index">
                                        <div class="product-wrap" style="padding-right: 3px; max-width: 300px !important; width: auto !important" [title]="item.description">
                                            <div class="product-img product-img-res-car">
                                                <div class="stock-tag" *ngIf="item.currentAvailableQty < 6 && item.currentAvailableQty > 0">
                                                    <img src="assets/img/piece-tag.png">
                                                    <span>Only {{item.currentAvailableQty}} left</span>
                                                </div>
                                                <div class="stock-tag" *ngIf="item.countryOfOrigin == 'USA'" style="left: 4px!important;right: auto">
                                                    <img class="made-usa" src="assets/img/USA.png" title="Made in USA">
                                                </div>
                                                <!-- <div class="stock-tag" *ngIf="item.currentAvailableQty == 0">
                                                    <img src="assets/img/piece-tag.png">
                                                    <span>Notify me</span>
                                                </div> -->
                                                <a (click)="productDetailsNav(item, $event)">
                                                    <img class="default-img" [src]="item.primaryImageURL" alt=""
                                                        (error)="errorHandlerForItemImages($event)"
                                                        style="background-color: var(--main-color); display: unset">
                                                </a>
                                                <a class="product-quicview" (click)="productDetailsNav(item, $event)"></a>
                                                <div class="product-action">
                                                    <div class="pro-same-action pro-wishlist">
                                                        <a title="Wishlist" (click)="addUpdateWishList(item,0,null)"
                                                            *ngIf="item.isInWishList == 0">
                                                            <i class="fa fa-heart-o"></i>
                                                        </a>
                                                        <a title="Wishlist" (click)="addUpdateWishList(item,2,null)"
                                                            *ngIf="item.isInWishList == 1">
                                                            <i class="fa fa-heart" *ngIf="item.isInWishList == 1"></i>
                                                        </a>
                                                    </div>
                                                    <div class="pro-same-action pro-cart">
                                                        <a title="Add To Cart"
                                                            (click)="addItemToCart(item,null)"
                                                            *ngIf="item.shoppingCartQty == 0 && item.currentAvailableQty>0">
                                                            <i class="pe-7s-cart"></i>
                                                        </a>
                                                        <a title="View Cart" *ngIf="item.shoppingCartQty>0 && item.currentAvailableQty>0" routerLink="/cart">
                                                            <i class="fa fa-shopping-cart"></i>
                                                        </a>
                                                        <a title="Notify Me" (click)="notifyCustomer(item)" *ngIf="item.currentAvailableQty==0">
                                                            <i class="fa fa-bell"></i>
                                                        </a>
                                                    </div>
                                                    <div class="pro-same-action pro-quickview">
                                                        <a title="Details" data-toggle="modal" (click)="onShareClick(item)"><i
                                                                class="fa fa-share"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="clearfix"></div>
                                            <div class="product-content text-center">
                                                <h3><a [title]="item.description">{{item.description | slice:0:18}}</a></h3>
                                                <div *ngIf="item?.listPrice != item?.dealPrice">
                                                    <div style="margin-right: 10px;">
                                                        <span style="text-decoration: line-through">{{item?.listPrice | currency}}</span>
                                                        <span style="color: #fe5252"> {{item?.discountPercent}}% off</span>&nbsp;
                                                        <span class="product-price"
                                                            style="color: rgb(11, 128, 104);font-size: 18px;">&nbsp;<b>{{item?.dealPrice |
                                                                currency}}</b></span>
                                                    </div>
                                                </div>
                                                <div class="product-price" *ngIf="item?.listPrice == item?.dealPrice">
                                                    <span>{{item?.listPrice | currency}}</span>
                                                </div>
                                                <div class="pro-details-rating-wrap" *ngIf="item?.itemRating != 0">
                                                    <div class="review-stars" style="display: flex; justify-content: center;">
                                                        <div class="rate text-center">
                                                            <input type="radio" id="star11" name="rate" [value]="5" disabled>
                                                            <label for="star11" [title]="item?.itemRating + ' rated'" [ngClass]="[item?.itemRating == 5?'selected-star':'']">5 stars</label>
                                                            <input type="radio" id="star21" name="rate" [value]="4" disabled>
                                                            <label for="star21" [title]="item?.itemRating + ' rated'" [ngClass]="[item?.itemRating > 3?'selected-star':'']">4 stars</label>
                                                            <input type="radio" id="star31" name="rate" [value]="3" disabled>
                                                            <label for="star31" [title]="item?.itemRating + ' rated'" [ngClass]="[item?.itemRating > 2?'selected-star':'']">3 stars</label>
                                                            <input type="radio" id="star41" name="rate" [value]="2" disabled>
                                                            <label for="star41" [title]="item?.itemRating + ' rated'" [ngClass]="[item?.itemRating > 1?'selected-star':'']">2 stars</label>
                                                            <input type="radio" id="star51" name="rate" [value]="1" disabled>
                                                            <label for="star51" [title]="item?.itemRating + ' rated'" [ngClass]="[item?.itemRating >0?'selected-star':'']">1 star</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </owl-carousel-o>
                                <div>
                                    <button class="view-more-button" (click)="loadMoreRecommendedItems()" style="padding: 3px 5px;font-size: 12px;">View More</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-xl-9 col-md-12">
                <div class="shop-bottom-area">
                    <div class="tab-content jump">
                        <div id="shop-11" class="tab-pane active">
                            <div class="row cust-row">
                                <div *ngFor="let item of subMenuItemsList; let index = index"
                                    class="col-xl-3 col-md-4 col-lg-4 col-sm-4 item-col-xl col-mb-6 nopadding-3 col-lg-cust-4">
                                    <div class="product-wrap mb-25 scroll-zoom" *ngIf="subMenuItemsList.length>0">
                                        <div class="product-img product-img-items bg-white" [title]="item.description">
                                            <div class="stock-tag" *ngIf="item.currentAvailableQty<6 && item.currentAvailableQty > 0">
                                                <img src="assets/img/piece-tag.png">
                                                <span>Only {{item.currentAvailableQty}} left</span>
                                            </div>
                                            <div *ngIf="item?.currentAvailableQty == 0" class="out-of-stock-tag">Out of
                                                Stock</div>
                                            <div class="stock-tag"
                                                *ngIf="item.countryOfOrigin=='USA' && selectedMenuDetails.childValue != 'Made in America'" style="left: 4px!important;right: auto">
                                                <img class="made-usa" src="assets/img/USA.png" title="Made in USA">
                                            </div>
                                            <!-- <div class="stock-tag" *ngIf="item.currentAvailableQty==0">
                                                <img src="assets/img/piece-tag.png">
                                                <span>Notify Me</span>
                                            </div> -->
                                            <a (click)="productDetailsNav(item, $event)">
                                                <img [src]="item.primaryImageURL"
                                                    (error)="errorHandlerForItemImages($event)"
                                                    style="background-color: var(--main-color);">
                                            </a>
                                            <a class="product-quicview" (click)="productDetailsNav(item, $event)"
                                                (contextmenu)="onRightClick(item, $event)"
                                                [routerLink]="item.groupKey != undefined ? ['/product-details',item.itemNumber] : (selectedSubCategoryID == undefined ? ['/product-details',item.itemNumber] : ['/product-details',item.itemNumber])"></a>
                                            <span class="purple" *ngIf="item.itemTag == 'New'">New</span>
                                            <div class="product-action">
                                                <div class="pro-same-action pro-wishlist">
                                                    <a title="Wishlist" (click)="addUpdateWishList(item,0,index)"
                                                        *ngIf="item.isInWishList == 0">
                                                        <i class="fa fa-heart-o"></i>
                                                    </a>
                                                    <a title="Wishlist" (click)="addUpdateWishList(item,2,index)"
                                                        *ngIf="item.isInWishList == 1">
                                                        <i class="fa fa-heart" *ngIf="item.isInWishList == 1"></i>
                                                    </a>
                                                </div>
                                                <div class="pro-same-action pro-cart">
                                                    <a [ngClass]="[item.currentAvailableQty==0?'disable-area':'']"
                                                        title="Add To Cart" (click)="addItemToCart(item,index)"
                                                        *ngIf="item.shoppingCartQty==0 && item.currentAvailableQty>0">
                                                        <i class="pe-7s-cart"></i>
                                                    </a>
                                                    <a title="View Cart"
                                                        *ngIf="item.shoppingCartQty>0 && item.currentAvailableQty>0"
                                                        routerLink="/cart">
                                                        <i class="fa fa-shopping-cart"></i>
                                                        <!-- &nbsp;View cart -->
                                                    </a>
                                                    <a title="Notify Me" *ngIf="item.shoppingCartQty==0 && item.currentAvailableQty==0" (click)="notifyCustomer(item)">
                                                        <i class="fa fa-bell"></i>
                                                        <!-- &nbsp;Notify Me -->
                                                    </a>
                                                </div>
                                                <div class="pro-same-action pro-quickview">
                                                    <a title="Share Link" data-toggle="modal"
                                                        (click)="onShareClick(item)"><i
                                                            class="fa fa-share"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="product-content text-center">
                                            <h3><a (click)="productDetailsNav(item, $event)">{{item.description |
                                                    slice: 0:25}}<span *ngIf="item.assortments>1"> -
                                                        {{item.assortments}} Piece Set</span></a></h3>
                                            <div *ngIf="item?.listPrice != item?.dealPrice">
                                                <div style="margin-right: 10px;">
                                                    <span style="text-decoration: line-through">{{item?.listPrice | currency}}</span>
                                                    <span style="color: #fe5252"> {{item?.discountPercent}}% off</span>&nbsp;
                                                    <span class="product-price" style="color: rgb(11, 128, 104);font-size: 18px;">&nbsp;{{item?.dealPrice |
                                                        currency}}</span>
                                                </div>
                                            </div>
                                            <div class="product-price" *ngIf="item?.listPrice == item?.dealPrice">
                                                <span>{{item?.listPrice | currency}}</span>
                                            </div>
                                            <div class="pro-details-rating-wrap" *ngIf="item?.itemRating != 0">
                                                <div class="review-stars" style="display: flex; justify-content: center;">
                                                    <div class="rate text-center">
                                                        <input type="radio" id="star11" name="rate" [value]="5" disabled>
                                                        <label for="star11" [title]="item?.itemRating + ' rated'" [ngClass]="[item?.itemRating == 5?'selected-star':'']">5 stars</label>
                                                        <input type="radio" id="star21" name="rate" [value]="4" disabled>
                                                        <label for="star21" [title]="item?.itemRating + ' rated'" [ngClass]="[item?.itemRating > 3?'selected-star':'']">4 stars</label>
                                                        <input type="radio" id="star31" name="rate" [value]="3" disabled>
                                                        <label for="star31" [title]="item?.itemRating + ' rated'" [ngClass]="[item?.itemRating > 2?'selected-star':'']">3 stars</label>
                                                        <input type="radio" id="star41" name="rate" [value]="2" disabled>
                                                        <label for="star41" [title]="item?.itemRating + ' rated'" [ngClass]="[item?.itemRating > 1?'selected-star':'']">2 stars</label>
                                                        <input type="radio" id="star51" name="rate" [value]="1" disabled>
                                                        <label for="star51" [title]="item?.itemRating + ' rated'" [ngClass]="[item?.itemRating >0?'selected-star':'']">1 star</label>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="subMenuItemsList?.length==0" style="padding-left: 50%">No Items Found</div>
                            </div>
                        </div>
                        <div id="shop-21">
                            <div *ngFor="let item of subMenuItemsList; let index = index" class="shop-list-wrap mb-30">
                                <div class="row">
                                    <div class="col-xl-4 col-lg-5 col-md-5 col-sm-6">
                                        <div class="product-wrap">
                                            <div class="product-img product-img-list bg-white">
                                                <div class="stock-tag" *ngIf="item.currentAvailableQty<6 && item.currentAvailableQty>0">
                                                    <img src="assets/img/piece-tag.png">
                                                    <span>Only {{item.currentAvailableQty}} left</span>
                                                </div>
                                                <div class="stock-tag"
                                                    *ngIf="item.countryOfOrigin=='USA' && selectedMenuDetails.childValue != 'Made in America'" style="left: 4px!important;right: auto">
                                                    <img class="made-usa" src="assets/img/USA.png" title="Made in USA">
                                                </div>
                                                <div class="stock-tag" *ngIf="item.currentAvailableQty==0">
                                                    <img src="assets/img/piece-tag.png">
                                                    <span>Notify Me</span>
                                                </div>
                                                <a (click)="productDetailsNav(item, $event)">
                                                    <img class="default-img"
                                                        style="background-color: var(--main-color);"
                                                        [src]="item.primaryImageURL"
                                                        (error)="errorHandlerForItemImages($event)" alt="">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-8 col-lg-7 col-md-7 col-sm-6">
                                        <div class="shop-list-content">
                                            <h3><a (click)="productDetailsNav(item, $event)" [title]="item.description">{{item.description |
                                                    slice: 0:25}}<span *ngIf="item.assortments>1"> -
                                                        {{item.assortments}} Piece Set</span></a></h3>
                                            <div class="product-list-price">
                                                <span style="color: black">{{item.listPrice | currency}}</span>
                                            </div>
                                            <div class="item-desc-list">
                                                <ul style="margin: 15px 0 40px;line-height: 28px;font-size: 15px;">
                                                    <li *ngFor="let itemSpec of item.itemSpecificationDetails">
                                                        {{itemSpec}}</li>
                                                </ul>
                                            </div>
                                            <div class="shop-list-btn btn-hover row">
                                                <div class="pro-same-action pro-wishlist">
                                                    <a title="Wishlist" (click)="addUpdateWishList(item,0,index)"
                                                        *ngIf="item.isInWishList == 0">
                                                        <i class="fa fa-heart-o"></i>
                                                    </a>
                                                    <a title="Wishlist" (click)="addUpdateWishList(item,2,index)"
                                                        *ngIf="item.isInWishList == 1" style="padding: 14px 20px 15px">
                                                        <i class="fa fa-heart" *ngIf="item.isInWishList == 1"></i>
                                                    </a>
                                                </div>
                                                <div class="pro-same-action pro-cart">
                                                    <a title="Add To Cart" (click)="addItemToCart(item,index)"
                                                        *ngIf="item.shoppingCartQty==0">
                                                        <i class="pe-7s-cart"></i>
                                                    </a>
                                                    <a title="View Cart" *ngIf="item.shoppingCartQty>0"
                                                        routerLink="/cart">
                                                        <i class="pe-7s-cart"></i>
                                                    </a>
                                                </div>
                                                <div class="pro-same-action pro-quickview">
                                                    <a title="Details" (click)="onShareClick(item)"><i
                                                            class="fa fa-share"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="subMenuItemsList?.length==0" style="padding-left: 50%">No Items Found</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #shareProductPopup let-modal>
    <div class="custom-modal">
        <div class="modal-header">
            <h4 class="page-title mb-0">Share Product Detail</h4>
            <a href="javascript:void(0)" class="close" (click)="dialogRef.close()" style="color: #fff !important;">
                <i class="fa fa-times" aria-hidden="true"></i>
            </a>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-md-12 form-group">
                    <label for="emailAddress">Email Address:</label>
                    <input type="email" class="form-control" id="emailAddress" [(ngModel)]="customerEmail" placeholder="Enter your email">
                </div>
            </div>
            <div class="col-md-12 text-center center-block cust-modal-footer mt-20">
                <button type="button" class="btn bg-theme" [disabled]="customerEmail == '' || customerEmail == null"
                    (click)="shareProductLink()">Send Email</button>
                <button type="button" class="btn bg-theme  ml-10" (click)="onCopyLinkClick()">Copy Link</button>
                <!-- <button type="submit" class="btn bg-theme ml-5" id="Cancel" (click)="dialogRef.close()">Cancel</button> -->
            </div>
        </div>
    </div>
</ng-template>
