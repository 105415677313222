import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class CountService {

  private wishListCount = new BehaviorSubject<number>(null);
  private cartCount = new BehaviorSubject<number>(null);
  private cartTotal = new BehaviorSubject<any>(null);
  private cartItems = new BehaviorSubject<any>(null);

  currentCartItems = this.cartItems.asObservable();
  currentWishListCount = this.wishListCount.asObservable();
  currentCartCount = this.cartCount.asObservable();
  currentCartTotal = this.cartTotal.asObservable();
  currentUser: any;

  constructor(private _dataService: DataService) {
    this.currentUser = JSON.parse(localStorage.getItem('currentHFGUser'));
  }

  setCounts(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentHFGUser'));
    var req = {
      "customerNumber": this.currentUser.customerNumber,
    }
    this._dataService.post('Order/GetCartCountToDisplay', req).subscribe((response) => {      
      this.cartCount.next(response[0].itemsCount);
    });
  }
  setWishlistCounts(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentHFGUser'));
    var req = {
      "customerNumber": this.currentUser.customerNumber,
    }
    this._dataService.post('Items/GetWishList', req).subscribe((response) => {      
      this.wishListCount.next(response.data.length);
    });
  }

  setTotals(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentHFGUser'));
    var req = {
      "customerNumber": this.currentUser.customerNumber,
    }
    var cartTotal = 0;
    var cartItems = [];
    this._dataService.post('Cart/GetShoppingCart', req).subscribe((response) => {
      cartItems = response.data
      cartItems.forEach(element => {
        cartTotal = cartTotal + element.listPrice
      });
      this.cartTotal.next(cartTotal);
      this.cartItems.next(response.data);
    }, (error) => {
    });
  }
}
