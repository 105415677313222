import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/shared/services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService } from 'src/app/shared/services/config.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-track-order',
  templateUrl: './track-order.component.html',
  styleUrls: ['./track-order.component.css']
})
export class TrackOrderComponent implements OnInit {
  currentUser: any;
  orderTrackingReq: { "customerNumber": string; "orderNumber": number; "emailAddress": string; };
  orderNumber: string;
  orderDetails: any;
  imgPath: any;
  orderBasicDetails: any;
  emailAddress: string;
  previousPageURL: any;

  constructor(private _dataService: DataService, private activatedRoute: ActivatedRoute, private config_service: ConfigService, private spinner: NgxSpinnerService, private router: Router, public titleService : Title) {
    this.spinner.show();
    this.currentUser = JSON.parse(localStorage.getItem('currentHFGUser'));
    this.titleService.setTitle('Track Order | Homeful Gifts');
    
    this.activatedRoute.paramMap.subscribe(params => {
      var linkDetails= window.atob(params.get("orderNumber"));
      this.emailAddress = linkDetails.split("/")[1];
      this.orderNumber = linkDetails.split("/")[0];
      
      // this.imgPath = this.config_service.getImageUrl();
      // this.router.navigateByUrl(this.router.url.replace(this.emailAddress, ""));
    })
    this.getOrderTrackingDetails();
  }

  ngOnInit(): void {
  }

  getOrderTrackingDetails() {

    this.orderTrackingReq = {
      "customerNumber": this.currentUser == undefined ? null : this.currentUser.customerNumber,
      "orderNumber": +this.orderNumber,
      "emailAddress": this.emailAddress == "null" ? null : this.emailAddress
    }
    this.previousPageURL = localStorage.getItem('guestTrackOrderDecision');
    localStorage.removeItem('guestTrackOrderDecision');
    
    this._dataService.post('Order/GetOrderTrackingDetails', JSON.stringify(this.orderTrackingReq))
      .subscribe((response) => {
        
        this.orderDetails = response;
        this.orderBasicDetails = this.orderDetails[0];
        this.spinner.hide();
        
      }, (error) => {
        if (error.status == 300) {
          this.orderDetails = [];
        }
        this.spinner.hide();
      });
  }
}