import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  emailAddress: any;
  personalDataForm: FormGroup;
  
  constructor(private router: Router,private _dataService: DataService, private spinner: NgxSpinnerService, public titleService : Title, private fb: FormBuilder) {
    this.spinner.show();
    this.titleService.setTitle('Forgot Password | Homeful Gifts');
    this.personalDataForm = fb.group({
      'email': [null, [Validators.required, Validators.pattern(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/)]]
    });
   }

  ngOnInit(): void {
    this.spinner.hide();

  }
  submitEmail() {
    
    this.spinner.show();
    var req = {
      "emailID": this.emailAddress
    }
    this._dataService.post('Templates/ForgetPassword', JSON.stringify(req)).subscribe((response) => {
      this._dataService.alert("An Email has been sent to your registered email address", "",'success');
      this.spinner.hide();
      setTimeout(() => {
        this.router.navigate(['/login'])
      }, 5000);
      this.spinner.hide();
    }, (error) => {
      this.spinner.hide();
      this._dataService.alert("EMail Address not found. Please provide a valid email address", "",'info');
        
    });
  }
}
