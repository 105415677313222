import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'HomeFulGifts';
  routesToShowHeader: any = ['/admin/admin-page', '/admin/item-detail/:id/:customerNum', '/admin/item-detail'];
  showHeaderNavigation: boolean = false;

  constructor(private router: Router) { }

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.showHeaderNavigation = this.shouldShowHeader(event.urlAfterRedirects);
    });
    console.log('AppComponent initialized');
  }

  private shouldShowHeader(url: string): boolean {
    return this.routesToShowHeader.some(route => this.matchRoute(url, route));
  }

  private matchRoute(url: string, route: string): boolean {
    const urlSegments = url.split('/');
    const routeSegments = route.split('/');

    if (urlSegments.length !== routeSegments.length) {
      return false;
    }

    return routeSegments.every((segment, index) => {
      if (segment.startsWith(':')) {
        // This is a dynamic segment, accept any value
        return true;
      }
      return segment === urlSegments[index];
    });
  }
}
