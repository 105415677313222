import { Component, OnInit } from '@angular/core';
import { MustMatch } from 'src/app/shared/validation-utilities/must-match';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataService } from 'src/app/shared/services/data.service';
import { ConfigService } from 'src/app/shared/services/config.service';
import { UtilityService } from 'src/app/shared/services/utilityService';
import * as $ from 'jquery'; // Avoid using jQuery if possible
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  // isChecked: boolean = true;
  phoneNumber: number;
  customerInfo: any;
  guestUserDetails: any;
  currentUser: any;
  tempGuestNumber: any = null;
  billAddressInfolist: any[];
  systemDetails: any;
  websiteTestURL: any;
  shoppingCartList: any;
  websiteLiveURL: string;
  previousPageURL: any;
  firstName: any = "";
  lastName:any = "";
  isDataLoading:boolean = false;
  isFromOffer: string;
  passwordStrength: string;
  passwordStrengthMessage: string;
  passwordStrengthClass: string;
  passwordHasMinLength: boolean = false;
  passwordHasLowercase: boolean = false;
  passwordHasUppercase: boolean = false;
  passwordHasNumber: boolean = false;
  passwordHasSpecialChar: boolean = false;

  constructor(private fb: FormBuilder, private config_service: ConfigService, private _dataService: DataService, private _snackBar: MatSnackBar,
    public router: Router, public utilservice: UtilityService, public titleService : Title) {
    this.isFromOffer = localStorage.getItem('welcomeEmail');
    this.titleService.setTitle('Registration | Homeful Gifts');
    this.registerForm = fb.group({
      'first_name': [null, Validators.compose([Validators.required])],
      'last_name': [null],
      'email': [(this.isFromOffer ? this.isFromOffer : null), [Validators.required, Validators.pattern(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/)]],
      'phone_number': [null, [Validators.required, Validators.minLength(14)]],
      'password': [null, Validators.compose([Validators.required, Validators.minLength(6)])],
      'confirm_password': [null, Validators.compose([Validators.required])]

    },
      {
        validator: MustMatch('password', 'confirm_password')
      });
    this.websiteTestURL = this.config_service.getWebTestUrl();
    this.websiteLiveURL = this.config_service.getWebLiveUrl();
    this.systemDetails = localStorage.getItem('systemUniqueIP');
  }

  ngOnInit(): void {
    this.isDataLoading = false;
    this.guestUserDetails = JSON.parse(localStorage.getItem('guestUserAddress'));
    this.currentUser = JSON.parse(localStorage.getItem('currentHFGUser'));
    this.previousPageURL = JSON.parse(localStorage.getItem('previousPageURL'));
    this.systemDetails = localStorage.getItem('systemUniqueIP');
    this.getShoppingCartItems();
    if (this.currentUser.guestCustomer == 0) {
      this.router.navigate(['/']);
    }
    if (this.currentUser.guestCustomer == 1) {
      this.tempGuestNumber = this.currentUser.customerNumber;
    }
  }

  ngAfterViewInit() {
    $(".toggle-password").click(function () {
      $(this).toggleClass("fa-eye fa-eye-slash");
      var input = $($(this).attr("toggle"));
      if (input.attr("type") == "password") {
        input.attr("type", "text");
      } else {
        input.attr("type", "password");
      }
    });
  }
  // Method for Registering a Customer
  onRegisterCustomer() {
    this.isDataLoading = true;
    var req = {
      "indicator": 0,
      "customerNumber": 0,
      "firstName": this.registerForm.value.first_name,
      "lastName": this.registerForm.value.last_name,
      "email": this.registerForm.value.email,
      "phoneNumber": this.registerForm.value.phone_number,
      "password": this.registerForm.value.password,
      "salesDevice": "WEB",
      "guestCustomer": 0,
      "includeWelcomeCoupon": this.isFromOffer ? 1 : 0
    }
    
    this._dataService.post('app/InsertUpdateDeleteCustomerInformation', JSON.stringify(req)).subscribe((response) => {
      if (response.statusMessage[0].statusCode == -1) {
        this._dataService.alert(response.statusMessage[0].statusMessage,'','info');
        this.isDataLoading = false;
      }
      else {
        this.getUserInfo(response.statusMessage[0].customerNumber);
        this.emailRegisteredCustomer(response.statusMessage[0].customerNumber);
        this._dataService.alert('Thank you for registering.','','success');
        this.isDataLoading = false;
      }
    }, (error) => {
      this.isDataLoading = false;
      this._dataService.alert(error["error"].message,'','error');
    });
  }
  assignValue(value) {
    this.registerForm.value.phone_number = value;
  }

  continueAsGuest() {
    if (this.shoppingCartList.length > 0) {
      this.router.navigate(['/cart/checkout']);
    }
    else {
      this.router.navigate(['/']);
    }
  }

  //To Get Shopping Cart Items
  getShoppingCartItems() {
    var cartItemsReq = {
      "customerNumber": this.currentUser.customerNumber,
    }
    this._dataService.post('Cart/GetShoppingCart', JSON.stringify(cartItemsReq))
      .subscribe((response) => {
        this.shoppingCartList = response.data;
        this.isDataLoading = false;
      }, (error) => {
        if (error.status == 300) {
          this.shoppingCartList = [];
        }
        this.isDataLoading = false;
      });
  }

  logIn() {
    this.isDataLoading = true;
    var req = {
      "login": this.registerForm.value.email,
      "pwd": this.registerForm.value.password,
      "deviceID": this.systemDetails,
      "deviceName": null,
      "deviceOSVersion": null
    }

    this._dataService.post('app/ValidateLogin', JSON.stringify(req)).subscribe((response) => {
        if (this.tempGuestNumber != null && this.shoppingCartList.length > 0) {
        this.transferDataFromGuestToCustomer();
      }
    }, (error) => {
    });

  }
  // Emailing a registered customer
  emailRegisteredCustomer(customerNumber) {
    var req = {
      "userId": customerNumber,
      "customerNumber": customerNumber,
      "emailID": this.registerForm.value.email,
      "orderNumber": null
    }

    this._dataService.post('Templates/OnRegistration', req)
      .subscribe((response) => {
        this.customerInfo = response.data;
        localStorage.setItem('currentHFGUser', JSON.stringify(this.customerInfo));
        this.isDataLoading = false;
        this.router.navigate(['/']);
        window.location.reload();
      }, (error) => {
        this.isDataLoading = false;
      this._dataService.alert(error["error"].message,'','error');
      })
  }

  // Getting Registered User Information for Storing in the Local Storage
  getUserInfo(customerNumber) {
    var req = {
      customerNumber: customerNumber
    }
    this._dataService.post('app/GetHFCustomerGeneralInfo', req)
      .subscribe((response) => {
        this.customerInfo = response.data[0];
        localStorage.setItem('currentHFGUser', JSON.stringify(this.customerInfo));
        this.currentUser = JSON.parse(localStorage.getItem('currentHFGUser'));

        this.isDataLoading = false;
        if (this.currentUser.guestCustomer == 1) {
          this.transferDataFromGuestToCustomer();
        }
        else {
          this.logIn();
        }
      }, (error) => {
        
        this.isDataLoading = false;
       this._dataService.alert(error["error"].message,'','error');
      })
  }

  // To add  Cutomer Shiping Addresss
  addUpdateShpCustomerAddress() {
    var addressDetails = {
      "customerNumber": this.currentUser.customerNumber,
      
      "addressNumber": 0,
      "addressType": "SHP",
      "contactName": this.guestUserDetails.contact_Name,
      "mainAddress": 1,
      "address1": this.guestUserDetails.address1,
      "address2": this.guestUserDetails.address2,
      "city": this.guestUserDetails.city,
      "state": this.guestUserDetails.state,
      "zipCode": this.guestUserDetails.zipcode,
      "country": this.guestUserDetails.country,
      "phoneNumber": this.utilservice.removeSpecials(this.guestUserDetails.phone_Number),
      "phoneNumberExt": "",
      "eMail": this.guestUserDetails.email,
      "residentialAdd": 0,
      "longitude": 0.0,
      "latitude": 0.0,
      "addToBillingAndShipping": 0,
      "registerGuestCustomer": 0,
      "deviceID": this.systemDetails
    };
    this.isDataLoading = true;

    this._dataService.post('app/InsertUpdateCustomerAddress', JSON.stringify(addressDetails)).subscribe((response) => {
      this.transferDataFromGuestToCustomer();
      this.isDataLoading = false;
    }, (error) => {
      this.isDataLoading = false;
    });
  }

  transferDataFromGuestToCustomer() {

    var transferDetails = {
      "deviceID": this.systemDetails,
      "guestCustomerNumber": this.tempGuestNumber,
      "customerNumber": this.currentUser.customerNumber,
      "insertPreviousCartInfo": 1
    };
    this.isDataLoading = true;
    this._dataService.post('app/TransferGuestDataToCustome', JSON.stringify(transferDetails)).subscribe((response) => {
      this.router.navigate([(this.previousPageURL == "/cart" ? "/cart/checkout" : this.previousPageURL)]).then(() => {
        window.location.reload();
      });
    }, (error) => {
      this.isDataLoading = false;
    });
  }
  checkPasswordStrength() {
    const password = this.registerForm.get('password').value;

    if (!password) {
      this.passwordStrength = null;
      return;
    }

    this.passwordHasMinLength = password.length >= 8;
    this.passwordHasLowercase = /[a-z]/.test(password);
    this.passwordHasUppercase = /[A-Z]/.test(password);
    this.passwordHasNumber = /[0-9]/.test(password);
    this.passwordHasSpecialChar = /[$@#&!]/.test(password);

    let strength = 0;
    if (this.passwordHasMinLength) strength += 1;
    if (this.passwordHasLowercase) strength += 1;
    if (this.passwordHasUppercase) strength += 1;
    if (this.passwordHasNumber) strength += 1;
    if (this.passwordHasSpecialChar) strength += 1;

    if (strength <= 2) {
      this.passwordStrength = 'weak';
      this.passwordStrengthMessage = 'Weak Password';
      this.passwordStrengthClass = 'weak';
    } else if (strength === 3 || strength === 4) {
      this.passwordStrength = 'medium';
      this.passwordStrengthMessage = 'Medium Strength Password';
      this.passwordStrengthClass = 'medium';
    } else {
      this.passwordStrength = 'strong';
      this.passwordStrengthMessage = 'Strong Password';
      this.passwordStrengthClass = 'strong';
    }
  }

}