import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ConfigService {

    _YIS_base_Url = '';
    _base_Url = '';
    _login_Url = '';
    _forgot_url = '';
    _show_Url = '';
    _image: { url: string, extension: string, defaultImageURL: string };
    _categoryImage: { url: string; extension: string; defaultImageURL: string; };
    _bannerImage: { url: string; extension: string; defaultImageURL: string; };

    _webLiveUrl = '';
    _webTestUrl = '';

    constructor() {

        this._webTestUrl = 'https://yisbeta.youngsinc.com/homefulgifts';
        this._webLiveUrl = 'https://www.homefulgifts.com';

        this._image = { url: 'https://www.youngsinc.com/images/Images600/', extension: '.jpg', defaultImageURL: 'https://www.youngsinc.com/images/YoungsThumb.jpg' }
        // this._image = { url: 'https://www.youngsinc.com/images/', extension: '.jpg', defaultImageURL: 'https://www.youngsinc.com/images/YoungsThumb.jpg' }

        this._categoryImage = { url: 'https://www.youngsinc.com/images/HomefulGifts/CategoryImages/', extension: '.jpg', defaultImageURL: 'https://www.youngsinc.com/images/HomefulGifts/CategoryImages/Default%20Image.jpg' }
        this._bannerImage = { url: 'https://www.youngsinc.com/images/HomefulGifts/Banners/', extension: '.jpg', defaultImageURL: 'https://www.youngsinc.com/images/HomefulGifts/CategoryImages/Default%20Image.jpg' }

        /* Test API URL */

        // this._base_Url = 'https://www.youngsinc.com/HomefulGifts_SERVICES_TEST/';
        this._base_Url = 'https://www.homefulgifts.com/api/';
        this._YIS_base_Url = 'https://www.youngsinc.com/YIS5Test_Service/api/';
        this._login_Url = 'https://www.youngsinc.com/YISCUSTAPPTEST_SERVICE/applogin';

        /* Beta API URL */

        // this._base_Url = 'https://www.youngsinc.com/HomefulGifts_SERVICES_Beta/';
        // this._YIS_base_Url = 'https://www.youngsinc.com/yis5_service/api/';
        // this._login_Url = 'https://www.youngsinc.com/YISCUSTAPPTEST_SERVICE/applogin';

        // Local URL

        // this._base_Url = 'http://localhost:58815/';
        // this._YIS_base_Url = 'http://localhost:26264/api/';
        // this._login_Url = 'http://localhost:58815/applogin';

    }

    checkLive() {
        return this._base_Url.includes('test') || this._base_Url.includes('localhost') ? false : true
    }

    getLoginUrl() {
        return this._login_Url;
    }

    getApiUrl() {
        return this._base_Url;
    }

    getYISApiUrl() {
        return this._YIS_base_Url;
    }

    getShowUrl() {
        return this._show_Url;
    }

    getImageUrl() {
        return this._image;
    }
    getCategoryImageUrl() {
        return this._categoryImage;
    }

    getWebLiveUrl() {
        return this._webLiveUrl;
    }

    getWebTestUrl() {
        return this._webTestUrl;
    }

    getBannerImageUrl() {
        return this._bannerImage;
    }
}
