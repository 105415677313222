import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard  {

    constructor(private router: Router,private _authService:AuthService) { }
    canActivate() {
        if (localStorage.getItem('currentHFGUser')) {
            return true;
        }else{
         // localStorage.removeItem('currentUser');          
          this.router.navigateByUrl('');
          return false;
        }
      
    }
}
