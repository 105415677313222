import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/shared/services/data.service';
import { ConfigService } from 'src/app/shared/services/config.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-returns-list',
  templateUrl: './returns-list.component.html',
  styleUrls: ['./returns-list.component.css']
})
export class ReturnsListComponent implements OnInit {
  claimList: any;
  currentUser: any;

  constructor(private _dataService: DataService, private config_service: ConfigService, private spinner: NgxSpinnerService) { 
    this.currentUser = JSON.parse(localStorage.getItem('currentHFGUser'));
  }

  ngOnInit(): void {
    this.getPaymentCardDetails();
  }

  getPaymentCardDetails() {
    this.spinner.show();
    var req = {
      "customerNumber": this.currentUser.customerNumber,
      
      "orderNumber": 0,
      "itemNumber": null
    }
    this._dataService.post('Claims/GetClaimItemDetails', JSON.stringify(req))
      .subscribe((response) => {
        
        this.claimList = response.data;
        
        this.spinner.hide();
      }, (error) => {
        this.spinner.hide();

      });
  }
}
