<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
<div class="breadcrumb-area pt-10 pb-10 bg-gray-3">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12 nopadding">
                <div class="breadcrumb-content text-left">
                    <ul>
                        <li>
                            <a routerLink="/">Home</a>
                        </li>
                        <li>
                            <a href="javascript:void(0)">My Account</a>
                        </li>
                        <li class="active">Account Info </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="shop-area pt-15 pb-100 section-padding-1 section-product">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-3 col-plgc-3 nopadding">
                <div class="sidebar-style mr-30">

                    <div class="sidebar-widget">
                        <h4 class="title-1">My Account </h4>
                        <div class="sidebar-widget-list sidebar-category mt-20">
                            <ul>
                                <li class="active">
                                    <a routerLink="/account/my-account">Account Info</a>
                                </li>
                                <li>
                                    <a routerLink="/account/my-orders">Orders</a>
                                </li>
                                <li>
                                    <a routerLink="/account/my-address">Manage Addresses</a>
                                </li>
                                <li>
                                    <a routerLink="/account/manage-payment">Manage Payments</a>
                                </li>
                                <!-- <li>
                                    <a routerLink="/account/my-returns">My Returns</a>
                                </li> -->
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-lg-9 col-plgc-9 nopadding">
                <div class="billing-info-wrap mt-15" style="display: block;">
                    <!-- <h3>Billing Details <span class="pull-right bill-close  cust-icon"><i class="fa fa-close"></i></span></h3> -->
                    <div *ngIf="!isChangePassword">
                        <h3>Account Information </h3>
                        <form class="mt-10" [formGroup]="personalDataForm">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="billing-info mb-20">
                                        <label>First Name<sup>*</sup></label>
                                        <input type="text" [ngModel]="currentUser.firstName | titlecase"
                                            (ngModelChange)="currentUser.firstName=$event" formControlName="first_name">
                                        <show-errors [control]="personalDataForm.controls.first_name"></show-errors>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="billing-info mb-20">
                                        <label>Last Name</label>
                                        <input type="text" [ngModel]="currentUser.lastName | titlecase" (ngModelChange)="currentUser.lastName=$event"
                                            formControlName="last_name">
                                        <!-- <show-errors [control]="personalDataForm.controls.last_name"></show-errors> -->
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="billing-info mb-20">
                                        <label>Email Address</label>
                                        <input type="text" [(ngModel)]="currentUser.loginEmailAddress"
                                            formControlName="email" style="opacity: 0.7" disabled>
                                        <show-errors [control]="personalDataForm.controls.email"></show-errors>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="billing-info mb-20">
                                        <label>Phone Number<sup>*</sup></label>
                                        <input type="text" [(ngModel)]="currentUser.phoneNumber" maxlength="16"
                                            appPhoneMask formControlName="phone_number"
                                            autocomplete="doNotAutoComplete">
                                        <span
                                            *ngIf="!personalDataForm.get('phone_number').valid && personalDataForm.get('phone_number').touched">
                                            <span class="required--ndication" style="color: red;">
                                                Phone Number is required
                                            </span>
                                            <!-- <span class="required--ndication" *ngIf="personalDataForm.controls['phone_number'].hasError('pattern') ">
                                                        Please enter valid phone number
                                                    </span> -->
                                        </span>
                                    </div>
                                </div>
                                <!-- <div class="col-lg-6 col-md-6">
                            <div class="billing-info mb-20">
                                <label>Current Password</label>
                                <input type="text">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="billing-info mb-20">
                                <label>New Password</label>
                                <input type="text">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="billing-info mb-20">
                                <label>Confirm Password</label>
                                <input type="text">
                            </div>
                        </div> -->
                            </div>
                        </form>
                        <div class="checkout-account-toggle address-add-btn-2 mb-30">
                            <button class="btn-hover checkout-btn" type="submit" [disabled]="!personalDataForm.valid"
                                (click)="onEditCustomerDetails()">Save</button>&nbsp;
                            <!-- <button class="btn-hover checkout-btn cancel-btn" type="submit">Cancel</button> -->
                        </div>
                        <div class="col-lg-4 order-b4">
                            <!-- <label>
                                    <input style="width: 20px;height: 18px;" type="checkbox" name="" [(ngModel)]="isChangePassword" [ngModelOptions]="{standalone: true}" />
                                    <span class="checkmark">&nbsp;Is Change Password</span>
                                  </label> -->
                            <a class="text-underline" href="javascript:void(0);"
                                (click)="isChangePassword=!isChangePassword">Change Password</a>
                        </div>
                    </div>
                    <div *ngIf="isChangePassword">
                        <h3>Change Password </h3>

                        <form action="#" method="post" [formGroup]="changePasswordForm">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="billing-info mb-20">
                                        <label>Old Password<sup>*</sup></label>
                                        <input class="custom-form" type="password" placeholder="Old Password"
                                            maxlength="30" formControlName="oldPassword">
                                        <show-errors [control]="changePasswordForm.controls.oldPassword"></show-errors>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="billing-info mb-20">
                                        <label>New Password<sup>*</sup></label>
                                        <input class="custom-form" type="password" placeholder="New Password"
                                            maxlength="30" formControlName="newPassword">
                                        <show-errors [control]="changePasswordForm.controls.newPassword"></show-errors>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="billing-info mb-20">
                                        <label>Confirm New Password<sup>*</sup></label>
                                        <input class="custom-form" type="password" placeholder="Confirm New Password"
                                            maxlength="30" formControlName="confirm_password">
                                        <!-- <show-errors [control]="changePasswordForm.controls.confirm_password"></show-errors> -->
                                        <div class="required--ndication"
                                            *ngIf="changePasswordForm.controls.confirm_password.value?.length==0||changePasswordForm.controls.confirm_password.value?.length==null && changePasswordForm.controls.confirm_password.touched"
                                            style="color: red">
                                            Confirm Password is required
                                        </div>
                                        <div class="required--ndication"
                                            *ngIf="changePasswordForm.controls.confirm_password.errors?.mustMatch"
                                            style="color: red">
                                            Password doesn't match
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="checkout-account-toggle address-add-btn-2 mb-30">
                            <button class="btn-hover checkout-btn" type="submit" (click)="onChangePassword()"
                                [disabled]="!changePasswordForm.valid">Update</button>&nbsp;
                            <button class="btn-hover checkout-btn cancel-btn"
                                (click)="isChangePassword=!isChangePassword" type="submit">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<!-- <footer class="footer-area bg-gray pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-2 col-md-4 col-sm-4">
                <div class="copyright mb-30">
                    <div class="footer-logo">
                        <a href="index.html">
                            <img alt="" src="assets/img/logo/hg-logo.png">
                        </a>
                    </div>
                    <p>© 2019 <a href="#">Flone</a>.<br> All Rights Reserved</p>
                </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-4">
                <div class="footer-widget mb-30 ml-30">
                    <div class="footer-title">
                        <h3>ABOUT US</h3>
                    </div>
                    <div class="footer-list">
                        <ul>
                            <li><a href="about.html">About us</a></li>
                            <li><a href="#">Store location</a></li>
                            <li><a href="contact.html">Contact</a></li>
                            <li><a href="#">Orders tracking</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-4">
                <div class="footer-widget mb-30 ml-50">
                    <div class="footer-title">
                        <h3>USEFUL LINKS</h3>
                    </div>
                    <div class="footer-list">
                        <ul>
                            <li><a href="#">Returns</a></li>
                            <li><a href="#">Support Policy</a></li>
                            <li><a href="#">Size guide</a></li>
                            <li><a href="#">FAQs</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="footer-widget mb-30 ml-75">
                    <div class="footer-title">
                        <h3>FOLLOW US</h3>
                    </div>
                    <div class="footer-list">
                        <ul>
                            <li><a href="#">Facebook</a></li>
                            <li><a href="#">Twitter</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="footer-widget mb-30 ml-70">
                    <div class="footer-title">
                        <h3>SUBSCRIBE</h3>
                    </div>
                    <div class="subscribe-style">
                        <p>Get E-mail updates about our latest shop and special offers.</p>
                        <div id="mc_embed_signup" class="subscribe-form">
                            <form id="mc-embedded-subscribe-form" class="validate" novalidate="" target="_blank"
                                name="mc-embedded-subscribe-form" method="post"
                                action="http://devitems.us11.list-manage.com/subscribe/post?u=6bbb9b6f5827bd842d9640c82&amp;id=05d85f18ef">
                                <div id="mc_embed_signup_scroll" class="mc-form">
                                    <input class="email" type="email" required="" placeholder="Enter your email here.."
                                        name="EMAIL" value="">
                                    <div class="mc-news" aria-hidden="true">
                                        <input type="text" value="" tabindex="-1"
                                            name="b_6bbb9b6f5827bd842d9640c82_05d85f18ef">
                                    </div>
                                    <div class="clear">
                                        <input id="mc-embedded-subscribe" class="button" type="submit" name="subscribe"
                                            value="Subscribe">
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer> -->


<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">x</span></button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-5 col-sm-12 col-xs-12">
                        <div class="tab-content quickview-big-img">
                            <div id="pro-1" class="tab-pane fade show active">
                                <img src="assets/images/17091.png" alt="">
                            </div>
                            <div id="pro-2" class="tab-pane fade">
                                <img src="assets/images/17091.png" alt="">
                            </div>
                            <div id="pro-3" class="tab-pane fade">
                                <img src="assets/images/17091.png" alt="">
                            </div>
                            <div id="pro-4" class="tab-pane fade">
                                <img src="assets/images/17091.png" alt="">
                            </div>
                        </div>
                        <!-- Thumbnail Large Image End -->
                        <!-- Thumbnail Image End -->
                        <div class="quickview-wrap mt-15">
                            <div class="quickview-slide-active owl-carousel nav nav-style-1" role="tablist">
                                <a class="active" data-toggle="tab" href="#pro-1"><img src="assets/images/17091.png"
                                        alt=""></a>
                                <a data-toggle="tab" href="#pro-2"><img src="assets/images/17091.png" alt=""></a>
                                <a data-toggle="tab" href="#pro-3"><img src="assets/images/17091.png" alt=""></a>
                                <a data-toggle="tab" href="#pro-4"><img src="assets/images/17091.png" alt=""></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-7 col-sm-12 col-xs-12">
                        <div class="product-details-content quickview-content">
                            <h2><strong>17091</strong> - Tin Mason Jar Wall, 3 Assorted </h2>
                            <div class="product-details-price">
                                <span>$3.25 </span>
                                <span class="old">$6.50 </span>
                            </div>
                            <div class="pro-details-rating-wrap">
                                <div class="pro-details-rating">
                                    <i class="fa fa-star-o yellow"></i>
                                    <i class="fa fa-star-o yellow"></i>
                                    <i class="fa fa-star-o yellow"></i>
                                    <i class="fa fa-star-o"></i>
                                    <i class="fa fa-star-o"></i>
                                </div>
                                <span>3 Reviews</span>
                            </div>
                            <div class="pro-details-quality">
                                <div class="cart-plus-minus">
                                    <input class="cart-plus-minus-box" type="text" name="qtybutton" value="2">
                                </div>
                                <div class="pro-details-cart btn-hover">
                                    <a href="#">Add To Cart</a>
                                </div>
                                <div class="pro-details-wishlist">
                                    <a href="#"><i class="fa fa-heart-o"></i></a>
                                </div>

                            </div>
                            <div class="pro-details-list">
                                <P>Perfect red, white and blue American flag decor for your Memorial Day or 4th of July.
                                    The little jute rope with a bow and star motif adds grace to the decor.</P>
                                <ul>
                                    <li>- Includes a set of three tin mason jar wall plaques</li>
                                    <li>- Ful graphic text ( jar 1): My worries are few beacuse my blessings are many
                                    </li>
                                    <li>- Full graphic text ( jar 2): Home a place your feet may leave but your heart
                                        will always be</li>
                                    <li>- Full graphic text ( jar 3): Being a family means you will love and be loved
                                        The rest of your life</li>
                                    <li>- Item Measures: 9" x 0.25" x 14.5"</li>
                                    <li>- Case Measures: 16.54" x 8.27" x 10.43"</li>
                                </ul>
                            </div>


                            <!-- <div class="pro-details-meta">
                                <span>Categories :</span>
                                <ul>
                                    <li><a href="#">Minimal,</a></li>
                                    <li><a href="#">Furniture,</a></li>
                                    <li><a href="#">Electronic</a></li>
                                </ul>
                            </div>
                            <div class="pro-details-meta">
                                <span>Tag :</span>
                                <ul>
                                    <li><a href="#">Fashion, </a></li>
                                    <li><a href="#">Furniture,</a></li>
                                    <li><a href="#">Electronic</a></li>
                                </ul>
                            </div> -->

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Modal end -->
