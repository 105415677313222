import { Component, OnInit, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/shared/services/data.service';
import { ConfigService } from 'src/app/shared/services/config.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UtilityService } from 'src/app/shared/services/utilityService';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CountService } from 'src/app/shared/services/count.service';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-my-orders',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.css']
})
export class MyOrdersComponent implements OnInit {
  currentUser: any;
  allPageIndex: number = 0;
  allItemsList: any[];
  subPageIndex: number;
  submittedList: any[];
  shePageIndex: number;
  scheduledList: any[];
  imgPath: { url: string; extension: string; defaultImageURL: string; };
  sort: { sortDesc: string, sortValue: string, sortOrder: string }[] = [
    { sortDesc: "Item# Ascending", sortValue: "ITEMNO", sortOrder: "ASC" },
    { sortDesc: "Item# Descending", sortValue: "ITEMNO", sortOrder: "DESC" },
    { sortDesc: "Description Ascending", sortValue: "DES", sortOrder: "ASC" },
    { sortDesc: "Description Descending", sortValue: "DES", sortOrder: "DESC" },
    { sortDesc: "Price (Low to High)", sortValue: "SPRICE", sortOrder: "ASC" },
    { sortDesc: "Price (High to Low)", sortValue: "SPRICE", sortOrder: "DESC" },
    { sortDesc: "Available Date Ascending", sortValue: "AVAIL", sortOrder: "ASC" },
    { sortDesc: "Available Date Descending", sortValue: "AVAIL", sortOrder: "DESC" }
  ];
  selectedSort: { sortDesc: string; sortValue: string; sortOrder: string; };
  getOrderReq: any;
  sortFilter: { orderNumber: string; poNumber: string; shipZip: string; SortBy: string; SortOrder: string; dateDuration: string; };
  ordListReq: { "minPrice": number; "above100Dollers": boolean; "UserId": string; "ItemSizeTo": string; "Type": string; "sortOrder": string; "PageIndex": number; "Sale": boolean; "Description": string; "sortBy": string; "Status": string; "Item": string; "ItemSizeFrom": string; "InstockBy": string; "customerNumber": string; "maxPrice": number; "New": boolean; "UpTo5Dollers": boolean; };
  sortItemFilter: { MinPrice: any; MaxPrice: any; Above100Dollers: boolean; InStockByData: any; ItemSizeTo: any; SortOrder: string; SortBy: string; Sale: boolean; Description: any; Item: any; New: boolean; ItemSizeFrom: any; InstockBy: string; UpTo5Dollers: boolean; Price: any; Size: any; SizeRange: string; };
  allOrderList: any[];
  selectedOrder: any;
  reviewDetails: { "title": any; "description": any; "rating": any };
  @ViewChild('itemReviewPopUp') itemReviewPopUp: TemplateRef<any>;
  reasonValue: any = "";
  @ViewChild('CancelReasonPopup') CancelReasonPopup: TemplateRef<any>;
  itemForCancellation: any;
  dialogRef: any;
  orderTrackingDetails: any;
  systemDetails: { ip: any; };
  constructor(private router: Router, private _dataService: DataService, private spinner: NgxSpinnerService, public utilservice: UtilityService,
    private countService: CountService, public dialog: MatDialog, private activatedRoute: ActivatedRoute, public titleService: Title, private authService: AuthService) {
    this.titleService.setTitle('My Orders | Homeful Gifts');
    this.reviewDetails = { "title": "", "description": "", "rating": 0 };
    this.currentUser = JSON.parse(localStorage.getItem('currentHFGUser'));
  }

  ngOnInit(): void {
    this.spinner.show();
    let currentUser = JSON.parse(localStorage.getItem('currentHFGUser'));
    this.activatedRoute.paramMap.subscribe(params => {
      if (params.has("orderDetail")) {
        var linkDetails = window.atob(params.get("orderDetail"));
        var orderDetails = {
          emailAddress: linkDetails.split("/")[0],
          orderNumber: linkDetails.split("/")[1]
        };
        this.orderTrackingDetails = orderDetails;
      } else {
        console.log("orderDetail parameter is missing in the route");
        this.orderTrackingDetails = null;
      }
    });
    // if (currentUser == null || currentUser == undefined) {
    //   this.router.navigate(['/']);
    // }
    if (currentUser == null || currentUser == undefined) {
      const guid = this.generateGuid();
      this.authService.getSystemDetails().subscribe((response) => {
        localStorage.setItem('systemDetails', JSON.stringify(response));
        localStorage.setItem('systemUniqueIP', (response.ip + '-' + guid).toString());
        this.systemDetails = { ip: response ? response.ip : null };
        const req = { "deviceID": this.systemDetails.ip + '-' + guid };
        this._dataService.post('app/GenerateGuestLogin', JSON.stringify(req)).subscribe((response) => {
          const req = { "customerNumber": response.statusMessage };
          this._dataService.post('app/GetHFCustomerGeneralInfo', req).toPromise()
            .then(response => {
              this.currentUser = response.data[0];
              localStorage.setItem('currentHFGUser', JSON.stringify(this.currentUser));
              window.location.reload()
            })
            .catch(error => {
              this.spinner.hide();
            });
        }, (error) => {
          this.spinner.hide();
        });
      }, (error) => {
        this.systemDetails = { ip: null };
      });
    }
    this.selectedSort = this.sort[1];
    this.sortFilter = { orderNumber: "", poNumber: "", shipZip: "", SortBy: "ORDDT", SortOrder: "DESC", dateDuration: "LST12M", };
    this.sortItemFilter = { MinPrice: null, MaxPrice: null, Above100Dollers: false, InStockByData: null, ItemSizeTo: null, SortOrder: this.selectedSort.sortOrder, SortBy: this.selectedSort.sortValue, Sale: false, Description: null, Item: null, New: false, ItemSizeFrom: null, InstockBy: "", UpTo5Dollers: false, Price: null, Size: null, SizeRange: '0.5' }
    this.getOrderDetails();
  }

  // To get order details
  getOrderDetails() {
    this.allOrderList = [];
    this.allItemsList = [];
    if (this.orderTrackingDetails != null) {
      this.getOrderReq = {
        "customerNumber": null,
        "orderNumber": this.orderTrackingDetails.orderNumber,
        "emailAddress": this.orderTrackingDetails.emailAddress
      }
    }
    else {
      this.getOrderReq = {
        "customerNumber": this.currentUser.customerNumber,
        "orderNumber": null,
        "emailAddress": this.currentUser.loginEmailAddress
      }
    }

    this._dataService.post('Order/GetOrderDetails', this.getOrderReq)
      .subscribe((response) => {
        const groupedOrders = response.reduce((acc, item) => {
          let existingOrder = acc.find(order => order.orderNumber === item.orderNumber);
          if (existingOrder) {
            existingOrder.items.push(item);
          } else {
            acc.push({
              orderNumber: item.orderNumber,
              dateSubmitted: item.dateSubmitted,
              salesTaxAmount: item.salesTaxAmount,
              deliveryInstructions: item.deliveryInstructions,
              discountAmount: item.discountAmount,
              freightAmount: item.freightAmount,
              couponDiscount: item.couponDiscount,
              orderTotalAmount: item.orderTotalAmount,
              totalOrderAmount: item.totalOrderAmount,
              items: [item]
            });
          }

          return acc;
        }, []);
        this.allOrderList = groupedOrders;
        this.allOrderList.forEach(order => {
          order.items.forEach(item => {
            item.deliveryDate = new Date(new Date().getTime() + (11 * 24 * 60 * 60 * 1000));
          });
        });
        debugger
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
      });

  }

  addItemToCart(item) {
    this.spinner.show();
    item.Qty = +item.Qty;
    var finalReq = [];
    var req = {
      "customerNumber": this.currentUser.customerNumber,
      "itemNumber": item.itemNumber,
      "marketPlaceSupplierID": item.marketPlaceSupplierID,
      "quantity": 1,
      "couponCode": null,
      "itemNotes": null,
      "clearWishList": 0,
      "inputType": 0
    }

    finalReq.push(req);
    this._dataService.post('Cart/InsertUpdateShoppingCart', finalReq)
      .subscribe((response) => {
        localStorage.setItem('isFromCart', null);
        // this._dataService.alert('Added to your Cart', '', 'success');
        this._dataService.confirm('Added to your Cart', "");
        var countReq = { "customerNumber": this.currentUser.customerNumber };
        // this.countService.setCartItems();
        this.countService.setCounts();
        this.spinner.hide();
      }, error => {
        this._dataService.alert("Item Couldn't be Added to Cart", '', 'error');
        this.spinner.hide();
      });
  }
  onReturnClick(orderDetails) {

    localStorage.setItem('selectedReturnOrder', JSON.stringify(orderDetails));
    this.router.navigate(['/account/my-returns']);
  }
  onClicktoReview(orderDetails) {

    this.ValidatingUserReviewItem(orderDetails);
    this.selectedOrder = orderDetails;
  }

  onItemReviewSubmit() {
    this.spinner.show();
    var req = {
      "customerNumber": this.currentUser.customerNumber,
      "itemNumber": this.selectedOrder.itemNumber,
      "marketPlaceSupplierID": this.selectedOrder.marketPlaceSupplierID,
      "rating": this.reviewDetails.rating,
      "reviewTitle": this.reviewDetails.title,
      "reviewDescription": this.reviewDetails.description,
      "inputType": 0
    }
    this._dataService.post('Items/InsertUpdateDeleteItemReview', req)
      .subscribe((response) => {
        this.selectedOrder = null;
        this._dataService.alert('Review Submitted Successfully', '', 'success');
        this.spinner.hide();
      }, error => {
        this._dataService.alert("Item Couldn't be Added to Cart", '', 'error');
        this.spinner.hide();
      });
  }
  onClickStar(value) {
    this.reviewDetails.rating = value;
  }

  ValidatingUserReviewItem(orderValue) {
    this.spinner.show();
    var req = {
      "customerNumber": this.currentUser.customerNumber,
      "itemNumber": orderValue.itemNumber,
      "marketPlaceSupplierID": orderValue.marketPlaceSupplierID,
    }

    this._dataService.post('Items/ValidatingUserReviewItem', req)
      .subscribe((response) => {
        if (response.data.value == 3) {
          this._dataService.alert("You've already submitted the ratings for this order", '', 'info');
          this.spinner.hide();
        }
        else {
          this.dialogRef = this.dialog.open(this.itemReviewPopUp);
          this.spinner.hide();
        }
      }, error => {
        this._dataService.alert("Item Couldn't be Added to Cart", '', 'error');
        this.spinner.hide();
      });
  }
  onItemClick(itemDetails) {

    localStorage.setItem('itemNumber', itemDetails.itemNumber);
    localStorage.setItem('upcNumber', itemDetails.upcNumber);
    sessionStorage.setItem('itemNumber', itemDetails.itemNumber);
    sessionStorage.setItem('upcNumber', itemDetails.upcNumber);
    var req = {
      "customerNumber": this.currentUser.customerNumber,

      "clickedMenuOption": itemDetails.category == "" ? "Wishlist" : itemDetails.category,
      "itemNumber": itemDetails.itemNumber,
      "marketPlaceSupplierID": itemDetails.marketPlaceSupplierID == 0 ? 1 : itemDetails.marketPlaceSupplierID
    }

    this._dataService.post('Items/InsertCustomerTrackDetails', req).subscribe((response) => {
      this.spinner.hide();
      this.router.navigate(['/product-details/', itemDetails.itemNumber, itemDetails.groupKey == undefined ? 0 : itemDetails.groupKey]);
    }, (error) => {
      this.spinner.hide();
    });
  }

  onTrackOrderClick(trackingLink) {
    window.open(trackingLink, '_blank');
  }
  errorHandlerForItemImages(event) {
    // Error handler for item images, setting a default image if an error occurs
    event.target.src = event.target.src.replace("hfg_images/ConsolidatedImages", "images");
  }

  cancelItemConfirmation(itemDetails) {
    this.itemForCancellation = itemDetails;
    this.dialogRef = this.dialog.open(this.CancelReasonPopup);

  }
  cancelItemFromOrder() {
    this.spinner.show();
    var req = {
      "customerNumber": this.currentUser.customerNumber,
      "orderNumber": this.itemForCancellation.orderNumber,
      "itemNumber": this.itemForCancellation.itemNumber,
      "reason": this.reasonValue
    }
    this._dataService.post('order/CancelItemFromOrder', JSON.stringify(req))
      .subscribe((response) => {
        this.reasonValue = "";
        this._dataService.alert("Item Cancelled Successfully", '', 'success');
        this.onClaimSubmitted();
        this.closeModal();
        this.getOrderDetails();
      }, (error) => {
        this.spinner.hide();
        this._dataService.alert(error.error.message, '', 'error');
      });
  }
  closeModal() {
    if (this.dialogRef)
      this.dialogRef.close();
  }
  generateOrderDetailReport(orderDetails) {
    var req = {
      "emailAddress": this.currentUser.loginEmailAddress,
      "customerNumber": this.currentUser.customerNumber,
      "customerName": this.currentUser.firstName,
      "orderNumber": orderDetails.orderNumber
    }
    this.spinner.show();
    this._dataService.PostDownloadFile("Report/GenerateOrderDetailReport", req).subscribe((response) => {
      var contentType = response.headers.get("content-type");
      const contentDisposition = response.headers.get('content-disposition');
      const fileNameReq = contentDisposition ? this.getFileNameFromContentDisposition(contentDisposition) : 'Order_Detail_Report.pdf';
      var headers = response.headers;
      var contentType = headers.get("content-type");
      var newBlob = new Blob([response.body], { type: contentType });
      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement("a");
      link.href = data;
      link.download = fileNameReq;
      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(this.blobdata);
        link.remove();
        this.spinner.hide();
      }, 100);
      this.spinner.hide();
    }, (error) => {
      this.spinner.hide();
      this._dataService.alert('Unable to download the report', '', 'error')
    });
  }

  getFileNameFromContentDisposition(contentDisposition: string): string {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(contentDisposition);
    return (matches != null && matches[1]) ? matches[1].replace(/['"]/g, '') : 'downloadedFile.pdf';
  }

  onClaimSubmitted() {
    var req = {
      "emailAddress": this.currentUser.loginEmailAddress,
      "customerNumber": this.currentUser.customerNumber,
      "customerName": this.currentUser.firstName,
      "orderNumber": this.itemForCancellation.orderNumber,
      "itemNumber": this.itemForCancellation.itemNumber,
      "reportType": "cancellation"
    }
    this._dataService.post('Report/SendClaimEmail', req)
      .subscribe((response) => {
        this.spinner.hide();
      }, (error) => {
        this.spinner.hide();
      })
  }
  generateGuid(): string {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }
}
