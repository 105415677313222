import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {

  constructor(private updates: SwUpdate, private router: Router) {
    if (updates.isEnabled) {
      console.log('Service Worker is enabled');
      // Check for updates every 1 minute (60000 milliseconds)
      interval(60000).subscribe(() => {
        console.log('Checking for updates...');
        updates.checkForUpdate().then(() => console.log('Update check completed'));
      });

      updates.versionUpdates.subscribe(event => {
        switch (event.type) {
          case 'VERSION_DETECTED':
            console.log(`Downloading new app version: ${event.version.hash}`);
            break;
          case 'VERSION_READY':
            console.log(`Current app version: ${event.currentVersion.hash}`);
            console.log(`New app version ready for use: ${event.latestVersion.hash}`);
            this.promptUser();
            break;
          case 'VERSION_INSTALLATION_FAILED':
            console.log(`Failed to install app version '${event.version.hash}': ${event.error}`);
            break;
        }
      });
    } else {
      console.log('Service Worker is not enabled');
    }
  }

  promptUser() {
    if (confirm('A new version of the app is available. Load the new version!!?')) {
      this.router.navigate(['/']).then(() => {
        window.location.reload();
      });
    }
  }
}
