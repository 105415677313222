import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';
import $ from 'jquery';
import { Router } from '@angular/router';
import { ConfigService } from '../services/config.service';
import { AuthService } from '../services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { CountService } from '../services/count.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UtilityService } from '../services/utilityService';

@Component({
  selector: 'app-header-navigation',
  templateUrl: './header-navigation.component.html',
  styleUrls: ['./header-navigation.component.css']
})
export class HeaderNavigationComponent implements OnInit {
  // Component properties for storing user and UI state
  isShowAccount = false;
  isShowCart = false;
  cssStyle = 'shopping-cart-content';
  headerMenuList: any;
  menuList: any;
  currentUser: any;
  sortFilter: any = {
    LifeStyle: null, MinPrice: null, MaxPrice: null, Above100Dollers: false,
    InStockByData: null, QtyType: '', ItemSizeTo: null, Sale: false, Description: null,
    ItemNumber: null, New: false, ItemSizeFrom: null, InstockBy: '', UpTo5Dollers: false,
    Price: null, Size: null, SizeRange: '0.5'
  };
  cartItemsReq: any;
  shoppingCartList: any[] = [];
  total = 0;
  LifestyleList: any[];
  imgPath: any;
  shoppingCartListForCount: number;
  searchValue = '';
  cartSubscription: Subscription;
  wishlistSubscription: Subscription;
  cartItemSubscription: Subscription;
  cartItemTotalSubscription: Subscription;
  cartCount: number;
  cartItems: any[];
  wishListCount: number;
  mainMenuList: any;
  subMenuList: any;
  cartInformation: any;

  constructor(
    private dataService: DataService,
    private utilityService: UtilityService,
    private router: Router,
    private configService: ConfigService,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private countService: CountService,
    private spinner: NgxSpinnerService
  ) {
    this.imgPath = configService.getImageUrl();
  }

  // Initializes component by retrieving user data and setting up subscriptions
  ngOnInit() {
    const currentUser = localStorage.getItem('currentHFGUser');
    if (!currentUser) {
      setTimeout(() => {
        this.currentUser = JSON.parse(localStorage.getItem('currentHFGUser'));
        this.initialize();
      }, 4500);
    } else {
      this.currentUser = JSON.parse(currentUser);
      this.initialize();
    }
  }

  private initialize() {
    // Helper method to initialize counts, cart items, totals and fetch initial data
    if (this.currentUser) {
      this.countService.setCounts();
      this.countService.setWishlistCounts();
      // this.countService.setCartItems();
      this.countService.setTotals();
    }

    this.getShoppingCartItems();
    this.getMainMenu();
    setTimeout(() => {
      this.wishlistSubscription = this.countService.currentWishListCount.subscribe(count => this.wishListCount = count);
    }, 500);
    this.cartSubscription = this.countService.currentCartCount.subscribe(count => this.cartCount = count);
    this.cartItemSubscription = this.countService.currentCartItems.subscribe(itemsList => {
      this.cartItems = itemsList;
      if (this.cartItems && this.cartItems.length) {
        this.cartInformation = this.cartItems[0];
        this.cartInformation.freightAmount = this.cartInformation.freightAmount || 0;
      }
    });
    this.cartItemTotalSubscription = this.countService.currentCartTotal.subscribe(total => this.total = total);
  }

  ngAfterViewInit() {
    // Setup UI interactions after the view initializes
    this.initializeStickyHeaders();
    setTimeout(() => {
      this.initializeClickHandlers();
    }, 2500);
  }

  private initializeStickyHeaders() {
    // Setup sticky headers for the navigation bar
    const header = $('.sticky-bar');
    const headerMain = $('.sticky-bar-main');
    header.addClass('stick');
    headerMain.addClass('stick-main');

  }

  private initializeClickHandlers() {
    // Setup click handlers for account settings and cart interactions
    $(".account-setting-active, .search-active, .account-dropdown").on("click", function (e) {
      e.preventDefault();
      $(this).parent().find('.account-dropdown, .search-content').slideToggle('medium');
    });

    const iconCart = $('.icon-cart');
    const shoppingCartContent = $('.shopping-cart-content');

    // Show the shopping cart content when the icon is hovered (desktop behavior)
    iconCart.on('mouseenter', () => {
      if (window.innerWidth > 768) { // Ensure this only applies to desktop
        shoppingCartContent.addClass('cart-visible'); // Show the cart
      }
    });

    // Keep the cart visible when hovering over it (desktop behavior)
    shoppingCartContent.on('mouseenter', () => {
      if (window.innerWidth > 768) {
        shoppingCartContent.addClass('cart-visible'); // Ensure it stays visible
      }
    });

    // Hide the cart when the mouse leaves both the icon and the cart content (desktop behavior)
    iconCart.on('mouseleave', () => {
      if (window.innerWidth > 768) {
        setTimeout(() => {
          if (!shoppingCartContent.is(':hover')) {
            shoppingCartContent.removeClass('cart-visible'); // Hide the cart if not hovering
          }
        }, 300); // Add a delay to allow smooth transition
      }
    });

    shoppingCartContent.on('mouseleave', () => {
      if (window.innerWidth > 768) {
        shoppingCartContent.removeClass('cart-visible'); // Hide the cart when not hovering
      }
    });

    // Toggle cart visibility on mobile click (mobile behavior)
    iconCart.on('click', function () {
      if (window.innerWidth <= 768) { // Apply click event only on mobile view
        shoppingCartContent.toggleClass('cart-visible');
      }
    });

    // Hide cart content when clicking outside on mobile
    $(document).on('click', function (e) {
      if (window.innerWidth <= 768) {
        if (!$(e.target).closest('.shopping-cart-content, .icon-cart').length) {
          shoppingCartContent.removeClass('cart-visible'); // Close the cart if clicked outside
        }
      }
    });

    // Close cart pop-up when an item inside the cart is clicked in mobile view
    shoppingCartContent.on('click', '.single-shopping-cart', function () {
      if (window.innerWidth <= 768) {
        shoppingCartContent.removeClass('cart-visible'); // Close the cart after item click
      }
    });
  }

  getCategoryMenu() {
    // Fetch category menu based on the current user
    if (this.currentUser) {
      const userName = this.currentUser.subCustomerNumber;
      this.dataService.get('app/GetCategoriesMenu', userName).subscribe(response => {
        response.data.forEach(obj => {
          if (obj.categoryName === 'Sale') {
            const saleCat = obj.subCategories.find(x => x.subCategoryValue === "WEB");
            saleCat.subCategoryName = "Web Only";
          }
        });
        this.menuList = response.data.map(x => ({ ...x }));
        this.headerMenuList = response.data.map(x => ({ ...x }));
        this.filterCategories();
        localStorage.setItem('menu', JSON.stringify(this.menuList));
        localStorage.setItem('headerMenu', JSON.stringify(this.headerMenuList));
      }, error => {});
    }
  }

  private filterCategories() {
    // Filters categories based on user preferences and settings
    this.headerMenuList.forEach(cat => {
      if (cat.subCategories.length > 0) {
        const filteredSubCategories = cat.subCategories.filter(sub => ['Best Sellers', 'New', 'Sale', 'All Items'].includes(sub.subCategoryName));
        cat.subCategories = [filteredSubCategories];
      }
    });
    if (this.currentUser) {
      this.menuList = this.menuList.filter(item => item.categoryName !== 'Sale');
      this.headerMenuList = this.headerMenuList.filter(item => item.categoryName !== 'Sale');
    }
  }

  onMenuClick(parentCategoryValue) {
    // Handles click events on menu items, navigating to respective category pages
    this.spinner.show();
    if (parentCategoryValue.childValue !== "Best Sellers" && parentCategoryValue.childValue !== "New") {
      this.onCategoryClick(parentCategoryValue);
    } else {
      localStorage.setItem('selectedCategoryValue', JSON.stringify(parentCategoryValue));
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        // this.router.navigate(['/display-items', parentCategoryValue.childValue, parentCategoryValue.hgGenericClassificationID, 1]);
        this.router.navigate(['/product-list']);
      });
    }
  }

  getShoppingCartItems() {
    // Retrieves shopping cart items for the current user
    if (this.currentUser) {
      const cartItemsReq = { "customerNumber": this.currentUser.customerNumber };
      this.dataService.post('Cart/GetShoppingCart', JSON.stringify(cartItemsReq)).subscribe(response => {
        this.shoppingCartList = response.data;
        if (this.shoppingCartList.length) {
          this.cartInformation = this.shoppingCartList[0];
          this.cartInformation.freightAmount = this.cartInformation.freightAmount || 0;
        }
      }, error => {
        if (error.status === 300) {
          this.shoppingCartList = [];
          this.LifestyleList = [];
        }
      });
    }
  }

  searchBarNav(value) {
    // Navigation logic for search bar interactions
    if (value.length > 2) {
      localStorage.setItem('searchValue', value);
      localStorage.setItem('selectedCategoryValue', JSON.stringify('search-bar'));
      this.searchValue = '';
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/display-items', 'search-bar', value]);
      });
    } else {
      this.dataService.alert('Enter at least 3 characters','','warning')
    }
  }

  logOut() {
    // Logs out the current user
    this.authService.logout();
  }

  // Navigation logic for different user interface interactions
  navigate(searchValue) {
    if(searchValue == "fashion"){
      localStorage.setItem('selectedCategoryValue', JSON.stringify({
        "childValue": "Fashion",
        "childrenExist": 0,
        "classificationType": "MAIN",
        "displaySequence": 8,
        "hgGenericClassificationID": 11,
        "parentKey": 0,
        "parentValue": ""
      }));
      localStorage.setItem('firstSubCategoryValue', JSON.stringify({
        "childValue": "Dresses",
        "childrenExist": 1,
        "classificationType": "MAIN",
        "displaySequence": 1,
        "hgGenericClassificationID": 90,
        "parentKey": 11,
        "parentValue": "Fashion"
      }));
      this.router.navigate(['/display-items/fashion']);
      return;
    }
    this.isShowAccount = !this.isShowAccount;
    localStorage.setItem('previousPageURL', JSON.stringify(this.router.url));
    this.router.navigate([searchValue]);
  }

  getMainMenu() {
    // Fetches the main menu items for the header navigation
    const req = { "indicator": 0, "parentKey": 0 };
    this.dataService.post('Items/GetMenuForHFG', req).subscribe(response => {
      if (response.status) {
        this.mainMenuList = response.data;
      }
    });
  }

  onCategoryClick(value) {
    // Handles navigation to subcategories within the menu
    const req = { "indicator": 1, "parentKey": value.hgGenericClassificationID };
    this.dataService.post('Items/GetMenuForHFG', req).subscribe(response => {
      if (response.status) {
        this.subMenuList = response.data;
        var URL = window.btoa(JSON.stringify(value) + "/" + JSON.stringify(this.subMenuList[0]));

        localStorage.setItem('selectedCategoryValue', JSON.stringify(value));
        localStorage.setItem('firstSubCategoryValue', JSON.stringify(this.subMenuList[0]));
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/display-items', value.childValue, value.hgGenericClassificationID, this.subMenuList[0].hgGenericClassificationID]);
        });
        // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        //   this.router.navigate(['/product-list', URL]);
        // });
      }
    });
  }

  productDetailsNav(item) {
    // Navigation to product details based on selected item
    const route = window.location.pathname;
    const req = {
      "customerNumber": this.currentUser.customerNumber,
      "clickedMenuOption": item.category,
      "itemNumber": item.itemNumber,
      "marketPlaceSupplierID": item.marketPlaceSupplierID || 1
    };
    localStorage.setItem('navigatedURL', JSON.stringify(route));
    this.dataService.post('Items/InsertCustomerTrackDetails', req).subscribe(() => {
      localStorage.setItem('itemNumber', item.itemNumber);
      localStorage.setItem('upcNumber', item.upcNumber);
      sessionStorage.setItem('itemNumber', item.itemNumber);
      sessionStorage.setItem('upcNumber', item.upcNumber);
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/product-details/', item.itemNumber, item.groupKey == undefined ? 0 : item.groupKey]);
      });
    }, error => {});
  }

  errorHandlerForItemImages(event) {
    // Error handler for item images, setting a default image if an error occurs
    event.target.src = event.target.src.replace("hfg_images/ConsolidatedImages", "images");
  }
}
