import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgxSpinnerService } from 'ngx-spinner';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HeaderNavigationComponent } from 'src/app/shared/header-navigation/header-navigation.component';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ConfigService } from 'src/app/shared/services/config.service';
import { CountService } from 'src/app/shared/services/count.service';
import { DataService } from 'src/app/shared/services/data.service';
import { UtilityService } from 'src/app/shared/services/utilityService';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrl: './product-list.component.css'
})
export class ProductListComponent {
  itemsList: any;
  imgPath: { url: string; extension: string; defaultImageURL: string; };
  itemDisplaySort: {
    "SortBy": string; "customerNumber": string; "qty": number; "pageIndex": number; "searchBy": string; "fall": boolean; "SortOrder": string;
    "upTo5Dollers": boolean; "category": string; "maxPrice": number; "instockBy": string; "new": boolean; "sale": boolean; "spring": boolean;
    "subCategory": string; "qtyType": string; "subCatType": string; "minPrice": number; "above100Dollers": boolean; "itemNumber": string,
    "description": string; "itemSizeFrom": number; "itemSizeTo": number; "group": string; "everyDay": boolean; "SalesDevice": string;
    "IsFromCheckOutScreen": boolean; "referenceID": string
  };

  filters: {
    ItemNo: string;
    Description: string;
    MinPrice: any;
    MaxPrice: any;
    UpTo5Dollers: boolean;
    Above100Dollers: boolean;
    InstockBy: string;
    InStockByData: any;
    QtyType: string;
    Qty: any;
    New: boolean;
    Sale: boolean;
    Price: any;
    ItemSizeTo: any;
    ItemSizeFrom: any;
    Size: any;
    SizeRange: string;
    SortBy: string;
    SortOrder: string;
    IsFiltersApplied: boolean;
    IsSortingApplied: boolean;
  };
  // subCategory: { catValue: string, name: string; value: string; subCatURL: string, groupValue: string, groupName: string, grpURL: string };

  pageIndex: number = 0;
  selectedSort: { sortDesc: string, sortValue: string, sortOrder: string };
  sort: { sortDesc: string, sortValue: string, sortOrder: string }[] = [
    // { sortDesc: "Default", sortValue: "null", sortOrder: "null" },
    { sortDesc: "Best Seller", sortValue: "BEST", sortOrder: "ASC" },
    { sortDesc: "New Arrivals", sortValue: "NEW", sortOrder: "ASC" },
    { sortDesc: "Price (Low to High)", sortValue: "PRICELOWTOHIGH", sortOrder: "ASC" },
    { sortDesc: "Price (High to Low)", sortValue: "PRICEHIGHTOLOW", sortOrder: "DESC" },
    { sortDesc: "Customer Ratings", sortValue: "RTNG", sortOrder: "DESC" }
  ];
  defaultSort: any;
  isSortingApplied: boolean = false;
  isUrlChange: boolean = false;
  recentListLoading: boolean = false;
  isFiltersApplied: boolean = false;
  bestSellerLoading: boolean = false;
  inStockDate = new Date(Date.now() + 1000 * 60 * 60 * 24 * 14);
  currentUser: any;
  @ViewChild('productSection') productSection: ElementRef;
  latestAriavalLoading: boolean = false;
  isQtyCheck: boolean = false;
  subCategoryData: any;
  menuList: any;
  categoryData: any;
  groupData: any[] = [];
  subCategoryBreadCrumb: any = null;
  limit: number;
  itemDetailForModel: any;
  thumbnailsList: any;
  itemSpecificationDetails: any;
  selectedCategoryList: any[] = [];
  selectedSubCategoryList: any;
  selectedSubCategoryListDetails: any;
  recentlyViewedList: any[];
  recViewedItemsReq: any;
  recommendedList: any;
  bestSellersList: any;
  addItemToCartReq: any[];
  isLoading: boolean = true;
  forBreadCrumb: any;
  showFiller = false;
  wishListReq: { "shipZipcode": string; "shipAddressNumber": number; "itemNumber": any; "customerNumber": any; }[];
  isCheckedLatestinFilter: boolean = false;
  itemNumberFromFilter: any;
  searchBy: string;
  errorOccured: boolean = false;
  pageNumber: number = 1;
  clickedSubCategory: any;
  mainCategoryID: any;
  subMenuList: any;
  selectedSubCategoryName: any;
  selectedMenuDetails: any;
  subMenuItemsList: any;
  name: any;
  categoryImgPath: { url: string; extension: string; defaultImageURL: string; };
  selectedSubCategoryID: any;
  selectedSubCategoryIndex: any = 0;
  minPrice: number = 0;
  maxPrice: number = 500;
  maxSliderPrice: number = 500;
  description: any = null;
  selectedSortValue: any = null;
  otherCategoriesBreadcrumb: boolean = false;
  subCategoryValue: any;
  categoryValue: any;
  firstSubMenuDetails: any;
  newArrivalsList: any;
  systemDetails: { ip: any; };
  isFullWebsite: boolean = false;
  @ViewChild('subItems') subCatItems: any;
  showMainDiv: boolean = true;
  isFullWebsiteForBreadCrumb: boolean = false;
  products = [];
  currentProductIndex = 0;
  currentProduct = this.products[this.currentProductIndex];
  recommendedItemsList: any = [];
  selectedSubMenuIndex: any;
  descriptionLabel: string;
  customOptionsRecentlyViewed: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    autoplay: false,
    autoplayHoverPause: true,
    animateIn: 'fadeIn',
    animateOut: 'fadeOut',
    navText: ["<a class='left carousel-control' role='button' data-slide='prev'><span class='icon-prev' aria-hidden='true'></span></a>", "<a class='right carousel-control' role='button' data-slide='next'><span class='icon-next' aria-hidden='true'></span></a>"],
    responsive: {
      0: {
        items: 1
      },
      300: {
        items: 1
      },
      600: {
        items: 1
      },
      740: {
        items: 1
      },
      1000: {
        items: 1
      }
    },
    nav: true
  };
  selectedItem: any;
  customerEmail: any;
  @ViewChild('shareProductPopup') shareProductPopup: TemplateRef<any>;
  dialogRef: any;
  constructor(private _dataService: DataService, private config_service: ConfigService, private activatedRoute: ActivatedRoute, public utilservice: UtilityService,
    private router: Router, private _snackBar: MatSnackBar, private _headerService: HeaderNavigationComponent, private spinner: NgxSpinnerService,
    public dialog: MatDialog, private countService: CountService, private authService: AuthService) {
    this.spinner.show();
    this.currentUser = JSON.parse(localStorage.getItem('currentHFGUser'));

    this.firstSubMenuDetails = JSON.parse(localStorage.getItem('firstSubCategoryValue'));
    this.selectedMenuDetails = JSON.parse(localStorage.getItem('selectedCategoryValue'));
    // this.menuList = JSON.parse(localStorage.getItem('menu'));

    if (this.selectedMenuDetails.childValue == "BEST" || this.selectedMenuDetails.childValue == "NEW" || this.selectedMenuDetails.childValue == "ALL" || this.selectedMenuDetails.childValue == "TOP") {
      this.defaultSort = this.sort[0].sortValue;
      this.getBestSellerAndNew(this.selectedMenuDetails.childValue, null);
    }

    else if (this.selectedMenuDetails == "search-bar") {
      this.defaultSort = this.sort[0].sortValue;
      this.description = localStorage.getItem('searchValue');
      this.descriptionLabel = localStorage.getItem('searchValue');
      this.customSearchAI();
    }
    else if (this.selectedMenuDetails.childValue == "Watermelon" || this.selectedMenuDetails.childValue == "Mugs" || this.selectedMenuDetails.childValue == "Baskets" ||
      this.selectedMenuDetails.childValue == "Stoneware" || this.selectedMenuDetails.childValue == "Pots & Planters" || this.selectedMenuDetails.childValue == "Botanicals" ||
      this.selectedMenuDetails.childValue == "Rugs" || this.selectedMenuDetails.childValue == "Notebook & Journals" || this.selectedMenuDetails.childValue == "Purses & Wallets" ||
      this.selectedMenuDetails.childValue == "Photo Frames" || this.selectedMenuDetails.childValue == "Personalization") {
      this.defaultSort = this.sort[0].sortValue;
      this.otherCategoriesBreadcrumb = true;
      this.getItemOnSubCategorySelect(this.selectedMenuDetails, 0, "Best Seller", this.minPrice, this.maxPrice, [null]);
    }
    else if (this.selectedMenuDetails.childValue == "recommendedItems") {
      this.defaultSort = this.sort[0].sortValue;
      this.getRecommendedItems();
    }
    else {
      this.defaultSort = this.sort[0].sortValue;
      this.getHFGSubMenu(this.selectedMenuDetails.hgGenericClassificationID);
    }
    this.categoryImgPath = this.config_service.getCategoryImageUrl();
    this.mainCategoryID = JSON.parse(localStorage.getItem('selectedMainCategory'));
    this.recommendedItems();
  }

  ngOnInit(): void {

    let currentUser = localStorage.getItem('currentHFGUser');
    var guid = this.generateGuid();
    if (currentUser == null || currentUser == undefined) {
      // this.router.navigate(['/login']);
      this.authService.getSystemDetails().subscribe((response) => {
        localStorage.setItem('systemDetails', JSON.stringify(response));
        localStorage.setItem('systemUniqueIP', (response.ip + '-' + guid).toString());
        this.systemDetails = {
          ip: response != null ? response.ip : null,
          // os: response != null ? response.os : null,
          // browser: response != null ? response.browser : null
        }
        var req = {
          "deviceID": this.systemDetails.ip + '-' + guid
        }
        this._dataService.post('app/GenerateGuestLogin', JSON.stringify(req)).subscribe((response) => {
          this.getUserInfo(response.statusMessage);
        }, (error) => {
          this.spinner.hide();
        });
      }, (error) => {
        this.systemDetails = {
          ip: null,
          // os: null,
          // browser: null
        }
      });
    }
    else {
      // this.activatedRoute.queryParams.subscribe(params => {
      //   this.name = params['catName'];
      //   this.categoryValue = params['catValue'];
      //   this.subCategoryValue = params['subCatValue'];
      // });

      const urlParams: Observable<any> = combineLatest(this.activatedRoute.params, this.activatedRoute.queryParams)
        .pipe(map(([params, queryParams]) => ({ ...params, ...queryParams })));

      urlParams.subscribe(params => {
        this.selectedCategoryList = JSON.parse(localStorage.getItem('selectedCategoryList'));
        this.selectedSubCategoryListDetails = JSON.parse(localStorage.getItem('selectedCategoryDetails'));

        this.pageIndex = parseInt(params.pageIndex);

        this.isUrlChange = true;

        if (this.isUrlChange) {
          this.recentListLoading = true;
          this.bestSellerLoading = true;
          this.latestAriavalLoading = true;
        }

      });
      this.spinner.show();
      // this.imgPath = this.config_service.getImageUrl();
      this.selectedCategoryList = [];
      this.selectedSubCategoryList = [];
      this.itemsList = [];
      this.getRecentlyViewedItems();
    }
    setInterval(() => {
      this.currentProductIndex = (this.currentProductIndex + 1) % this.products.length;
      this.currentProduct = this.products[this.currentProductIndex];
    }, 3000);
  }
  onRightClick(item) {


    var route = window.location.pathname;
    localStorage.setItem('itemNumber', item.itemNumber);
    localStorage.setItem('upcNumber', item.upcNumber);

    sessionStorage.setItem('itemNumber', item.itemNumber);
    sessionStorage.setItem('upcNumber', item.upcNumber);
    var req = {
      "customerNumber": this.currentUser.customerNumber,
      "clickedMenuOption": item.category == undefined ? this.selectedSubCategoryName : item.category,
      "itemNumber": item.itemNumber,
      "marketPlaceSupplierID": item.marketPlaceSupplierID == 0 ? 1 : item.marketPlaceSupplierID
    }
    if (this.selectedMenuDetails == "search-bar") {
      localStorage.setItem('navigatedURL', JSON.stringify(route));
    }
    else {
      localStorage.setItem('navigatedURL', JSON.stringify(route.replace('homefulgifts', '')));
    }
    this._dataService.post('Items/InsertCustomerTrackDetails', req).subscribe((response) => {
    }, (error) => {
    });
  }
  generateGuid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
      s4() + '-' + s4() + s4() + s4();
  }

  getUserInfo(customerNumber) {
    var req = {
      "customerNumber": customerNumber,
      // "subCustomerNumber": customerNumber
    }

    this._dataService.post('app/GetHFCustomerGeneralInfo', req)
      .subscribe((response) => {
        localStorage.setItem('currentHFGUser', JSON.stringify(response.data[0]));
        window.location.reload();
        // this.router.navigate(['/']);
        // window.location.reload();
      }, (error) => {
        // this.spinner.hide();
      })
  }
  customSearchAI() {
    var req = {
      "customerNumber": this.currentUser.customerNumber,
      "query": this.description
    }
    this._dataService.post("Items/GetSimilarItems", req)
      .subscribe((response) => {
        var itemList = [];
        response.data.forEach(element => {
          itemList.push(element.itemNumber);
        });
        this.getItemOnSubCategorySelect(0, 0, null, this.minPrice, this.maxPrice, itemList);
      }, (error) => {
        this.getItemOnSubCategorySelect(0, 0, null, this.minPrice, this.maxPrice, [null]);
      });
  }
  ngAfterViewInit() {
    $(document).ready(function () {
      $("#shop-21").hide();
      $("#shop-11").show();
      $("#shop-21-btn").show();
      $("#shop-11-btn").hide();
      $("#shop-11-btn").click(function () {
        $("#shop-21").hide('slow');
        $("#shop-11").show('slow');
        $("#shop-11-btn").hide();
        $("#shop-21-btn").show();
      });
      $("#shop-21-btn").click(function () {
        $("#shop-21").show('slow');
        $("#shop-11").hide('slow');
        $("#shop-11-btn").show();
        $("#shop-21-btn").hide();
      });
    });
    $(document).ready(function () {
      $("#SideFilters").hide();
      $('#SideFilterHideShow').on('click', function (event) {
        $('#SideFilters').toggle('show');
      });
    });

    var menuTrigger = $('.clickable-mainmenu-active'),
      endTrigger = $('button.clickable-mainmenu-close'),
      container = $('.clickable-mainmenu');

    menuTrigger.on('click', function (e) {
      e.preventDefault();
      container.addClass('inside');
    });
    endTrigger.on('click', function () {
      container.removeClass('inside');
    });

    $('<div class="quantity-nav"><div class="quantity-button quantity-up">+</div><div class="quantity-button quantity-down">-</div></div>').insertAfter('.quantity input');
    $('.quantity').each(function () {
      var spinner = $(this),
        input = spinner.find('input[type="number"]'),
        btnUp = spinner.find('.quantity-up'),
        btnDown = spinner.find('.quantity-down'),
        min = input.attr('min'),
        max = input.attr('max');

      btnUp.click(function () {
        var oldValue = parseFloat(input.val());
        if (oldValue >= max) {
          var newVal = oldValue;
        } else {
          var newVal = oldValue + 1;
        }
        spinner.find("input").val(newVal);
        spinner.find("input").trigger("change");
      });

      btnDown.click(function () {
        var oldValue = parseFloat(input.val());
        if (oldValue <= min) {
          var newVal = oldValue;
        } else {
          var newVal = oldValue - 1;
        }
        spinner.find("input").val(newVal);
        spinner.find("input").trigger("change");
      });

    });
  }

  //To Get Recently Viewed Items
  getRecentlyViewedItems() {
    // this.spinner.show();
    this.recentlyViewedList = [];


    // this.recViewedItemsReq = { customerNumber: this.currentUser.CustomerNumber, PageIndex: 0, referenceID: null }
    this.recViewedItemsReq = { customerNumber: this.currentUser.customerNumber }
    this._dataService.post("Items/RecentlyViewedProducts", JSON.stringify(this.recViewedItemsReq))
      .subscribe((response) => {
        var imgPath = this.imgPath;
        this.recentlyViewedList = response.data.slice(0, 5);
      }, (error) => {
        this.recentlyViewedList = [];
      });
  }

  addItemToCart(item, index) {
    this.spinner.show();
    item.Qty = +item.Qty;
    var finalReq = [];
    var req = {
      "customerNumber": this.currentUser.customerNumber,
      "marketPlaceSupplierID": item.marketPlaceSupplierID,
      "itemNumber": item.itemNumber,
      "itemParentID": item.groupKey,
      "quantity": item.qty,
      "couponCode": null,
      "itemNotes": null,
      "clearWishList": 0,
      "inputType": 0
    }
    finalReq.push(req);
    var items = this.sort.filter(item => item.sortValue.indexOf(this.defaultSort) !== -1);
    this._dataService.post('Cart/InsertUpdateShoppingCart', finalReq)
      .subscribe((response) => {

        var itemAddedMsg = item.shoppingCartQty == 0 ? "Added to your Cart!" : "Quantity updated to your Cart!"
        localStorage.setItem('subcat', JSON.stringify("subCategory"));
        item.shoppingCartQty = item.Qty;
        localStorage.setItem('isFromCart', null);
        this._dataService.confirm(itemAddedMsg, "");
        this._headerService.getShoppingCartItems();
        this.recommendedItems();
        this.countService.setCounts();
        this.countService.setTotals();
        if (index != null) {
          this.subMenuItemsList[index].shoppingCartQty = 1;
        }
        this.spinner.hide();
      }, error => {
        item.Qty = item.shoppingCartQty;
        this._dataService.alert("Item Couldn't be Added to Cart", "", 'error');
      });
  }

  addUpdateWishList(item, indicator, index) {

    this.spinner.show();
    var req = {
      "customerNumber": this.currentUser.customerNumber,
      "marketPlaceSupplierID": item.marketPlaceSupplierID,
      "itemNumber": item.itemNumber,
      "itemParentID": item.groupKey,
      "type": indicator,
      "quantity": 1,
      "clearAll": 0
    }
    var finalReq = [];
    finalReq.push(req);

    this._dataService.post('Items/InsertUpdateWishList', finalReq)
      .subscribe((response) => {
        if (item.isInWishList == 1) {
          this.countService.setWishlistCounts();
          this.spinner.hide();
          this._dataService.alert("Item removed from wishlist", '', 'success');
          this.recommendedItems();
          if (index != null) {
            this.subMenuItemsList[index].isInWishList = 0;
          }
        } else {
          this.countService.setWishlistCounts();
          this.spinner.hide();
          this._dataService.alert("Item added to wishlist", '', 'success');
          if (index != null) {
            this.subMenuItemsList[index].isInWishList = 1;
          }
        }
      }, (error) => {
        item.isInWishList == !item.isInWishList;
        this._dataService.alert(error.error.message, '', 'error');
        this.spinner.hide();
      });
  }

  onFilterClicked() {
    $(function sidebarMainmenu() {
      var menuTrigger = $('.clickable-mainmenu-active'),
        endTrigger = $('button.clickable-mainmenu-close'),
        container = $('.clickable-mainmenu');
      menuTrigger.on('click', function (e) {
        e.preventDefault();
        container.addClass('inside');
      });
      endTrigger.on('click', function () {
        container.removeClass('inside');
      });
    });
  }

  //HFG New Functionality
  getHFGSubMenu(parentID) {

    var req = {
      "indicator": 1,
      "parentKey": parentID
    }
    this._dataService.post('Items/GetMenuForHFG', req).subscribe((response) => {

      if (response.status == true) {
        this.subMenuList = response.data;

        if (this.subMenuList.length > 0) {
          this.getItemOnSubCategorySelect(this.subCategoryValue != 1 ? this.firstSubMenuDetails : this.subMenuList[0], 0, null, this.minPrice, this.maxPrice, [null]);
        }
        else {
          // this.spinner.hide();
        }

      }
      else {
      }
    })
  }

  //Method to get Best Seller Items from Home Screen
  getBestSellerAndNew(typeValue, sortValue) {
    // this.spinner.show();
    this.defaultSort = "BEST";
    var req = {
      "customerNumber": this.currentUser.customerNumber,
      "type": typeValue,
      "minPrice": this.minPrice,
      "maxPrice": this.maxPrice,
      "searchDesc": this.description,
      "sortingParameter": sortValue,
      "topCount": 100
    }

    this._dataService.post('Items/BestSellersAndNewItems', req).subscribe((response) => {
      if (response.status == true) {

        var maxPrice = 0;
        this.subMenuItemsList = response.data;
        this.subMenuItemsList.forEach((element, index) => {

          if (maxPrice < element.listPrice) {
            maxPrice = element.listPrice;
          }
          if (element.additionalDescription != null || element.additionalDescription != undefined) {
            element.itemSpecificationDetails = element.additionalDescription.split('$$');
            element.itemSpecificationDetails[0] = element.itemSpecificationDetails[0].substring(1);
          }
        });
        this.maxPrice = +maxPrice.toFixed(0);
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    }, (error) => {
      this.spinner.hide();
    })
  }
  getRecommendedItems() {
    var req = {
      "num_recommendations": 100,
      "recent": 1,
      "customer_number": this.currentUser.customerNumber
    }
    this._dataService.post("Items/GetRecommendedItems", req)
      .subscribe((response) => {
        var itemList = [];
        response.data.forEach(element => {
          itemList.push(element);
        });
        var req = {
          "parentKey": 0,
          "itemNumber": "",
          "searchBy": "recommended",
          "dealCode": null,
          "customerNumber": this.currentUser.customerNumber,
          "sortingParameter": 'ALL',
          "minPrice": 0,
          "maxPrice": 200,
          "itemNumberList": itemList
        }
        this._dataService.post('Items/GetDisplayItems', req).subscribe((response) => {
          if (response.status == true) {
            // var maxPrice = 0;
            this.subMenuItemsList = response.data;
            this.subMenuItemsList.forEach((element, index) => {

              // if (maxPrice < element.listPrice) {
              //   maxPrice = element.listPrice;
              // }
              if (element.additionalDescription != null || element.additionalDescription != undefined) {
                element.itemSpecificationDetails = element.additionalDescription.split('$$');
                element.itemSpecificationDetails[0] = element.itemSpecificationDetails[0].substring(1);
              }
            });
            // this.maxPrice = +maxPrice.toFixed(0);
            this.spinner.hide();
          }
        });
      }, (error) => {
        this.recommendedItemsList = [];
      });
  }

  productDetailsNav(item, decision) {

    var route = window.location.pathname;
    localStorage.setItem('itemNumber', item.itemNumber);
    localStorage.setItem('upcNumber', item.upcNumber);
    localStorage.setItem('groupKey', item.groupKey);

    sessionStorage.setItem('itemNumber', item.itemNumber);
    sessionStorage.setItem('upcNumber', item.upcNumber);
    sessionStorage.setItem('groupKey', item.groupKey);
    var req = {
      "customerNumber": this.currentUser.customerNumber,
      "clickedMenuOption": item.category == undefined ? this.selectedSubCategoryName : item.category,
      "itemNumber": item.itemNumber,
      "marketPlaceSupplierID": item.marketPlaceSupplierID == 0 ? 1 : item.marketPlaceSupplierID
    }
    if (this.selectedMenuDetails == "search-bar") {
      localStorage.setItem('navigatedURL', JSON.stringify(route));
    }
    else {
      localStorage.setItem('navigatedURL', JSON.stringify(route.replace('homefulgifts', '')));
    }
    this._dataService.post('Items/InsertCustomerTrackDetails', req).subscribe((response) => {

      if (!decision.ctrlKey || decision == "fromImage") {

        this.router.navigate(['/product-details/', item.itemNumber, item.groupKey == undefined ? 0 : item.groupKey]);
      }
    }, (error) => {
    });
  }

  getItemOnSubCategorySelect(subCategoryTID, indexValue, sortParameter, minPrice, maxPrice, itemList) {
    this.spinner.show();
    if (this.firstSubMenuDetails != null) {
      this.router.navigateByUrl(this.router.url.replace(this.firstSubMenuDetails.hgGenericClassificationID, subCategoryTID == null ? 1 : (subCategoryTID.hgGenericClassificationID == undefined ? 0 : subCategoryTID.hgGenericClassificationID)));
    }
    if (!this.firstSubMenuDetails) {
      this.firstSubMenuDetails = {};
    }
    this.firstSubMenuDetails.hgGenericClassificationID = subCategoryTID == 0 ? 0 : (subCategoryTID.hgGenericClassificationID == undefined ? 0 : subCategoryTID.hgGenericClassificationID);
    this.isFullWebsiteForBreadCrumb = this.isFullWebsite ? true : false;
    subCategoryTID = this.isFullWebsite ? 0 : subCategoryTID;
    var req = {
      "parentKey": subCategoryTID == 0 ? 0 : (subCategoryTID.hgGenericClassificationID == undefined ? 0 : subCategoryTID.hgGenericClassificationID),
      "itemNumber": "",
      "searchBy": this.description,
      "dealCode": null,
      "customerNumber": this.currentUser.customerNumber,
      "sortingParameter": sortParameter,
      "description": null,
      "minPrice": +minPrice,
      "maxPrice": +maxPrice,
      "itemNumberList": itemList
    }
    this.description = null;
    this._dataService.post('Items/GetDisplayItems', req).subscribe((response) => {

      if (response.status == true) {
        this.subMenuItemsList = response.data;
        // var maxPrice = 0;
        this.subMenuItemsList.forEach((element, index) => {

          // if (maxPrice < element.listPrice) {
          //   maxPrice = element.listPrice;
          // }
          if (element.additionalDescription != null || element.additionalDescription != undefined) {
            element.itemSpecificationDetails = element.additionalDescription.split('$$');
            element.itemSpecificationDetails[0] = element.itemSpecificationDetails[0].substring(1);
            element.itemSpecificationDetails.forEach((elementInner, indexInner) => {
              var updatedDesc = '';
              if (elementInner.indexOf('assortments') != -1 && elementInner.indexOf('set') != -1) {
                updatedDesc = elementInner.replace("assortments", "");
                if (index != null) {
                  this.subMenuItemsList[index].itemSpecificationDetails[indexInner] = updatedDesc;
                }
              }
              else if (elementInner.indexOf('assortments') != -1) {
                updatedDesc = elementInner.replace("assortments", "set of");
                elementInner = updatedDesc;
              }
            });
          }
        });

        for (let line = 0; line < 20; line++) {
          for (let i = 0; i < 20; i++) {
            const value = this.subMenuItemsList[i + line * 20]
            if (!value) continue
          }
        }

        this.selectedSubCategoryName = subCategoryTID.childValue == undefined ? this.selectedSubCategoryName : subCategoryTID.childValue;
        this.selectedSubCategoryID = subCategoryTID.hgGenericClassificationID == undefined ? this.selectedSubCategoryID : subCategoryTID.hgGenericClassificationID;
        this.selectedSubCategoryIndex = indexValue;
        this.maxPrice = +maxPrice.toFixed(0) == 0 ? 500 : +maxPrice.toFixed(0);
        // this.maxSliderPrice = this.maxPrice;
        this.spinner.hide();
      }
      else {
        this.spinner.show();
      }
    }, (error) => {
      this.spinner.hide();
    })
  }

  reAssignSelectedSubMenu(subMenuValue, index) {
    this.isFullWebsite = false;
    this.isFullWebsiteForBreadCrumb = false;
    this.selectedSubMenuIndex = index;
    localStorage.setItem('firstSubCategoryValue', JSON.stringify(subMenuValue));
    this.getItemOnSubCategorySelect(subMenuValue, index, 'Best', 0, this.maxPrice, [null]);
    // this.minPrice = 0;
    // this.maxPrice = 100;
    this.defaultSort = 'BEST';
    this.description = null;
  }

  onFilterSelect(TID, SelectedCategoryIndex, sortValue) {

    this.selectedSortValue = sortValue;
    this.spinner.show();
    var items = this.sort.filter(item => item.sortValue.indexOf(sortValue) !== -1);
    var tid = {
      "hgGenericClassificationID": TID == undefined ? 0 : TID
    }
    this.maxPrice = this.maxPrice == null ? 150 : this.maxPrice;
    this.minPrice = this.minPrice == null ? 0 : this.minPrice;
    // this.maxSliderPrice = this.maxPrice;
    if (this.selectedMenuDetails.childValue == "BEST" || this.selectedMenuDetails.childValue == "NEW" || this.selectedMenuDetails.childValue == "ALL" || this.selectedMenuDetails.childValue == "TOP") {
      this.defaultSort = this.sort[0].sortValue;
      this.getBestSellerAndNew(this.selectedMenuDetails.childValue, null);
    }
    else if (this.selectedMenuDetails == "search-bar" || this.selectedMenuDetails.childValue == "recommendedItems") {
      this.spinner.show();
      var filteredItems = (this.description != null && this.description != "") ? this.subMenuItemsList.filter(item =>
        item.description.toLowerCase().includes(this.description.toLowerCase())
      ) : this.subMenuItemsList;
      this.subMenuItemsList = filteredItems.filter(item => item.dealPrice >= this.minPrice && item.dealPrice <= this.maxPrice);
      this.spinner.hide();
    }
    else if (this.selectedMenuDetails != "best-seller" && this.selectedMenuDetails != "search-bar") {
      // this.getItemOnSubCategorySelect(tid, SelectedCategoryIndex, items[0].sortDesc, this.minPrice, this.maxPrice, [null]);
      this.getItemOnSubCategorySelect(tid, SelectedCategoryIndex, items[0].sortDesc, this.minPrice, this.maxPrice, []);
    }
    else {
      this.getBestSellerAndNew("BEST", sortValue);
    }
    // }
  }
  errorHandler(event) {

    event.target.src = this.categoryImgPath.defaultImageURL;
  }

  errorHandlerForItemImages(event) {

    event.target.src = event.target.src.replace("hfg_images/ConsolidatedImages", "images");
  }

  disableEnableMainDiv() {
    this.showMainDiv = !this.showMainDiv;
  }

  clearFilter() {
    this.isFullWebsite = false;
    this.isFullWebsiteForBreadCrumb = false;
    this.description = null;
    var defaultSort = this.sort.filter(item => item.sortValue.indexOf(this.defaultSort) !== -1)[0].sortDesc ?? this.defaultSort;

    if (this.selectedMenuDetails.childValue == "BEST" || this.selectedMenuDetails.childValue == "NEW" || this.selectedMenuDetails.childValue == "ALL" || this.selectedMenuDetails.childValue == "TOP") {
      this.maxPrice = 500;
      this.spinner.show();
      this.getBestSellerAndNew(this.selectedMenuDetails.childValue, null);
    }
    else if (this.selectedMenuDetails == "search-bar" || this.selectedMenuDetails.childValue == "recommendedItems") {
      this.spinner.show();
      if (this.selectedMenuDetails == "search-bar") {
        this.description = this.descriptionLabel;
        this.customSearchAI();
      }
      else {
        this.getRecommendedItems();
      }
      this.spinner.hide();
    }
    else {
      this.getItemOnSubCategorySelect(this.subCategoryValue != 1 ? this.firstSubMenuDetails : this.subMenuList[this.selectedSubMenuIndex ?? 0], 0, defaultSort, 0, 500, [null]);
    }
  }
  recommendedItems() {
    var req = {
      "num_recommendations": 20,
      "recent": 1,
      "customer_number": this.currentUser.customerNumber
    }
    this._dataService.post("Items/GetRecommendedItems", req)
      .subscribe((response) => {
        var itemList = [];
        response.data.forEach(element => {
          itemList.push(element);
        });
        var req = {
          "parentKey": 0,
          "itemNumber": "",
          "searchBy": "recommended",
          "dealCode": null,
          "customerNumber": this.currentUser.customerNumber,
          "sortingParameter": 'ALL',
          "description": null,
          "minPrice": 0,
          "maxPrice": 200,
          "itemNumberList": itemList
        }
        this._dataService.post('Items/GetDisplayItems', req).subscribe((response) => {
          if (response.status == true) {
            this.products = response.data.slice(0, 10);
          }
        });
      }, (error) => {
        this.recommendedItemsList = [];
      });
  }
  scrollToSection(element: HTMLElement, offset: number) {
    const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
    const offsetPosition = elementPosition + offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  }

  notifyCustomer(item) {
    if (!this.currentUser.loginEmailAddress) {
      this._dataService.alert('Please Login/Register to get notifications.', "", 'info');
      return;
    }
    this.spinner.show();
    this._dataService.notifyCustomer(this.currentUser.customerNumber, item.itemNumber, this.currentUser.loginEmailAddress, this.currentUser.phoneNumber).subscribe(response => {
      this.spinner.hide();
      this._dataService.alert('We will notify you once the item is back in stock.', "", 'success');
    }, error => {
      this.spinner.hide();
      this._dataService.alert('Error creating notification', "", 'error');
    });

  }
  getSliderValuePosition(): string {
    const slider: HTMLElement | null = document.querySelector('.custom-slider');
    if (slider) {
      const sliderWidth = slider.clientWidth;
      const valuePercentage = (this.maxPrice - this.minPrice) / (this.maxSliderPrice - this.minPrice);
      const pixelOffset = valuePercentage * sliderWidth;
      return `${pixelOffset}px`;
    }
    return '0px';
  }

  loadMoreRecommendedItems() {
    var req = {
      childValue: "recommendedItems",
      childrenExist: 0,
      displaySequence: 5,
      groupType: "MN",
      parentKey: 0,
      parentValue: "",
      hgGenericClassificationID: 5
    };
    localStorage.setItem('selectedCategoryValue', JSON.stringify(req));
    this.router.navigateByUrl('/display-items', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/display-items', 'Recommended Items', 0, 0]);
    });
  }
  onShareClick(item) {
    this.selectedItem = item;
    this.customerEmail = this.currentUser.loginEmailAddress ?? "";
    this.dialogRef = this.dialog.open(this.shareProductPopup);
  }

  shareProductLink() {
    this.spinner.show();
    var req = {
      "customerNumber": this.currentUser.customerNumber,
      "emailID": this.customerEmail,
      "itemNumber": this.selectedItem.itemNumber,
      "itemDescription": this.selectedItem.description,
      "productURL": document.location.origin + "/homefulgiftstest/product-details/" + (this.selectedItem.groupKey == undefined ? 0 : this.selectedItem.groupKey)
    };
    this._dataService.post('Templates/OnShareItem', JSON.stringify(req)).subscribe((response) => {
      this.spinner.hide();
      this.dialogRef.close();
      this._dataService.alert("Thank you for showing interest. The email is sent", "", "success");
    }, (error) => {
      this._dataService.alert("Email sending failed", "", "error");
      this.spinner.hide();
    });
  }

  onCopyLinkClick() {
    var productLink = document.location.origin + "/homefulgiftstest/product-details/" + (this.selectedItem.groupKey == undefined ? 0 : this.selectedItem.groupKey);
    const tempInput = document.createElement('input');
    tempInput.style.position = 'absolute';
    tempInput.style.left = '-9999px';
    tempInput.value = productLink;
    document.body.appendChild(tempInput);
    tempInput.select();
    tempInput.setSelectionRange(0, 99999);
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    this.dialogRef.close();
    this._dataService.alert('Product link copied to clipboard!', "", "success");
  }
}
