<!-- Main header area, visible when a user is authenticated -->
<div class="header-area clearfix">
  <!-- Top header bar showing promotional message -->
  <header class="header-area">
    <div class="top-header" style="text-align: center">
      <p style="color: white; background-color: #0E1111; font-size: large; padding: 10px 5px 0px 5px">
        <marquee><i class="fa fa-truck fa-lg" aria-hidden="true"></i> Free Shipping on Orders above $30</marquee>
      </p>
    </div>
  </header>

  <!-- Main navigation area -->
  <div class="container-fluid" style="padding-left: 0px; padding-right: 0px;">
    <div class="header-top-area sticky-bar-main">
      <div class="row">
        <!-- Logo section -->
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12 d-lg-block">
          <div class="logo-hm9 text-center">
            <a routerLink="/">
              <img alt="" src="assets/img/logo/hg-logo.png">
            </a>
          </div>
        </div>

        <!-- Search bar -->
        <div class="col-xl-4 col-lg-4 col-md-3 col-sm-8 col-s-12">
          <div class="cart-search search-style">
            <form action="#">
              <input type="text" name="find" placeholder="Search..." [(ngModel)]="searchValue" (keydown.enter)="searchBarNav(searchValue)" style="color: white;">
              <a (click)="searchBarNav(searchValue)">
                <img src="assets/images/searchicon.png">
              </a>
            </form>
          </div>
        </div>

        <!-- User account and cart -->
        <div class="col-xl-4 col-lg-4 col-md-5 col-sm-12 col-xs-12">
          <div class="mobile-menu-area pull-left">
            <div class="mobile-menu">
              <nav class="navbar navbar-toggleable-md navbar-light bg-faded">
                <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                  <i class="fa fa-bars"></i>
                </button>
                <div class="collapse navbar-collapse" id="navbarNav">
                  <ul class="navbar-nav">
                    @for(category of mainMenuList|slice:0:9; track category ){
                    <li class="nav-item active" >
                      <a class="nav-link" (click)="onMenuClick(category)" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav">{{category.childValue}}<span class="sr-only">(current)</span></a>
                    </li>
                    }</ul>
                </div>
              </nav>
            </div>
          </div>
          <div class="header-right-wrap float-right" *ngIf="currentUser">
            <!-- <div class="mb-10">
              <button class="button-33" role="button" (click)="navigate('fashion')">Fashion</button>
            </div> -->
            <div class="mb-10 mr-10">
              <h3 style="color: white; margin-bottom:0px;" *ngIf="currentUser.firstName">Hello, {{currentUser.firstName}}</h3>
              <h3 style="color: white; margin-bottom:0px;" *ngIf="!currentUser.firstName">Hello, Guest</h3>
            </div>
            <div class="same-style account-setting">
              <button mat-button [matMenuTriggerFor]="menu" mat-menu-trigger="hover"
                style="background-color: #232B2B; border: 0;">
                <img class="myaccount" src="assets/images/account.png" />
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item class="multi-act" (click)="navigate('/login')"
                  *ngIf="currentUser?.guestCustomer==1">Login</button>
                <button mat-menu-item class="multi-act" (click)="navigate('/login/register')"
                  *ngIf="currentUser?.guestCustomer==1">Register</button>
                <button mat-menu-item class="multi-act" (click)="navigate('/account/my-account')"
                  *ngIf="currentUser?.guestCustomer==0">My account</button>
                <button mat-menu-item class="multi-act" (click)="logOut()" *ngIf="currentUser?.guestCustomer==0">Log-Out</button>
              </mat-menu>
            </div>
            <div class="same-style header-wishlist">
              <a routerLink="/wishlist" title="Wishlist">
                <img class="wishlist-icon" src="assets/images/wishlist.png" />
              </a>
              <span class="count-style" style="padding: 0px 7px!important;" *ngIf="wishListCount > 0">{{wishListCount}}</span>
            </div>
            <div class="same-style cart-wrap">
              <button class="icon-cart">
                <img class="cart-icon" src="assets/images/cart.png" routerLink="/cart" />
                <span class="count-style" *ngIf="cartCount > 0">{{cartCount}}</span>
              </button>
              <div class="shopping-cart-content">
                <ul>
                  @for (item of cartItems| slice:0:3;track item; let index = $index){
                  <li class="single-shopping-cart">
                    <div class="shopping-cart-img">
                      <div class="cart-thum2">
                        <div class="cart-thum-body2">
                          <a href="javascript:void(0);" (click)="productDetailsNav(item)">
                            <img [src]="item.primaryImageURL" style="display: unset; background-color: var(--main-color)"
                              (error)="errorHandlerForItemImages($event)">
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="shopping-cart-title">
                      <br>
                      <h6>{{item.description | slice:0:25}}</h6>
                      <span>{{item.extPrice | currency}}</span><br />
                      <span class="pull-right" *ngIf="i == 1"></span>
                    </div>
                  </li>
                  }
                  <li *ngIf="cartCount == 0" class="single-shopping-cart">
                    <a>Your Cart is Empty</a>
                  </li>
                </ul>
                <div class="shopping-cart-total" *ngIf="cartCount > 0">
                  <span *ngIf="cartCount > 3" class="pull-right">
                    <h4>
                      <span>
                        <a>[+{{cartCount - 3}} items]</a>
                      </span>
                      <br>
                    </h4>
                  </span><br />
                  <h4>Shipping : <span>{{cartInformation?.freightAmount | currency}}</span></h4>
                  <h4>Total : <span class="shop-total">{{cartInformation?.totalAmount | currency}}</span></h4>
                </div>
                <div class="shopping-cart-btn btn-hover text-center" *ngIf="cartCount > 0">
                  <a class="default-btn" routerLink="/cart">view cart</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Main menu navigation -->
    <div class="header-bottom sticky-bar header-res-padding header-padding-2 theme-bg-1">
      <div class="container-fluid header-padding">
        <div class="d-none d-lg-block">
          <div class="main-menu">
            <nav>
              <ul class="margin-bottom:0px!important;">
                @for(category of mainMenuList|slice:0:9; track category ){
                <li >
                  <a (click)="onMenuClick(category)">{{category.childValue}}</a>
                </li>
                }</ul>
            </nav>
          </div>
        </div>

        <!-- Mobile menu navigation -->

      </div>
    </div>
  </div>
</div>
