<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
<div class="login-register-area pt-20 pb-30">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12 ml-auto mr-auto">
                <div class="login-logo text-center mb-30">
                    <img src="assets/img/logo/hg-logo.png" class="img-responsive">
                </div>
                <div class="login-register-wrapper reg-block">
                    <div class="login-form-container">
                        <div class="login-register-form">

                            <form action="#" method="post" [formGroup]="registerForm">
                                <div class="billing-info-wrap" style="display: block;">
                                    <h3 class="title-1">Register</h3>
                                    <div class="row">

                                        <div class="clearfix"></div>
                                        <div class="col-lg-6 col-md-6">
                                            <div class="billing-info mb-20">
                                                <label>First Name<sup>*</sup></label>
                                                <!-- <input type="text"> -->
                                                <input class="custom-form" type="text" placeholder="First Name" [ngModel]="firstName | titlecase"
                                                (ngModelChange)="firstName=$event"
                                                    maxlength="30" formControlName="first_name">
                                                <show-errors [control]="registerForm.controls.first_name"></show-errors>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                            <div class="billing-info mb-20">
                                                <label>Last Name</label>
                                                <input class="custom-form" type="text" placeholder="Last Name" [ngModel]="lastName | titlecase"
                                                (ngModelChange)="lastName=$event"
                                                    maxlength="30" formControlName="last_name">
                                                <show-errors [control]="registerForm.controls.last_name"></show-errors>
                                            </div>
                                        </div>


                                        <div class="col-lg-6">
                                            <div class="billing-info mb-20">
                                                <label>Email<sup>*</sup></label>
                                                <input class="custom-form" type="email" placeholder="Email"
                                                    maxlength="100" formControlName="email">
                                                <!-- <show-errors [control]="registerForm.controls.email"></show-errors> -->
                                                <span
                                                    *ngIf="!registerForm.get('email').valid && registerForm.get('email').touched">
                                                    <span class="required--ndication"
                                                        *ngIf="registerForm.controls['email'].hasError('required') ">
                                                        Email is required
                                                    </span>
                                                    <span class="required--ndication"
                                                        *ngIf="registerForm.controls['email'].hasError('pattern') ">
                                                        Please enter valid email
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="billing-info mb-20">
                                                <label>Phone Number<sup>*</sup></label>
                                                <input class="custom-form" type="text" placeholder="Telephone"
                                                    formControlName="phone_number" appPhoneMask maxlength="14" minlength="14"
                                                    (onchange)="assignValue($event)" autocomplete="doNotAutoComplete">
                                                <!-- <span
                                                    *ngIf="!registerForm.get('phone_number').valid && registerForm.get('phone_number').touched">
                                                    <span class="required--ndication"
                                                        *ngIf="registerForm.controls['phone_number'].hasError('required')">
                                                        Phone Number is required
                                                    </span>
                                                    <span class="required--ndication"
                                                        *ngIf="registerForm.controls['phone_number'].hasError('minLength')">
                                                        Please enter valid phone number
                                                    </span>
                                                </span> -->
                                                <span *ngIf="!registerForm.get('phone_number').valid && registerForm.get('phone_number').touched">
                                                    <span class="required--ndication" style="color: red;">
                                                        Phone Number is required
                                                    </span>
                                                </span>
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-6">
                                            <div class="billing-info mb-20">
                                                <label>Password<sup>*</sup></label>
                                                <input class="custom-form" type="password" placeholder="Password"
                                                    maxlength="30" formControlName="password" (input)="checkPasswordStrength()">
                                                <show-errors [control]="registerForm.controls.password"></show-errors>
                                                <div *ngIf="passwordStrength" [ngClass]="passwordStrengthClass" class="password-strength">
                                                    {{ passwordStrengthMessage }}
                                                </div>
                                                <ul class="password-conditions" *ngIf="registerForm.get('password').touched">
                                                    <li *ngIf="!passwordHasMinLength">At least 8 characters long</li>
                                                    <li *ngIf="!passwordHasLowercase">Contains lowercase letters</li>
                                                    <li *ngIf="!passwordHasUppercase">Contains uppercase letters</li>
                                                    <li *ngIf="!passwordHasNumber">Contains numbers</li>
                                                    <li *ngIf="!passwordHasSpecialChar">Contains special characters (e.g., $#&!)</li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-6">
                                            <div class="billing-info mb-20">
                                                <label>Confirm Password<sup>*</sup></label>
                                                <input class="custom-form" type="password"
                                                    placeholder="Confirm Password" maxlength="30"
                                                    formControlName="confirm_password">
                                                <div *ngIf="registerForm.controls.password.value?.length>0">
                                                    <div *ngIf="!registerForm.controls.confirm_password.valid && registerForm.controls.confirm_password.touched"
                                                        class="required--ndication">
                                                        <div
                                                            *ngIf="registerForm.get('confirm_password').errors.required">
                                                            Confirm password is required
                                                        </div>

                                                    </div>
                                                    <div class="required--ndication"
                                                        *ngIf="registerForm.controls.confirm_password.errors?.mustMatch">
                                                        Password not match
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <!-- <div class=" make-default">
                                            <label class="active">
                                                <input type="checkbox" name="email1" [checked]="isChecked"
                                                    [(ngModel)]="isChecked" [ngModelOptions]="{standalone: true}"><i
                                                    class="fa fa-square-o"></i><i class="fa fa-check-square-o"></i>
                                                <span>&nbsp;I have read and agree to the <a href="javascript:void(0);"
                                                        (click)="openSnackBar('Module under development','Ok',3000)"
                                                        class="cust-link"><strong>Privacy Policy</strong></a>
                                                </span>
                                            </label>
                                        </div> -->
                                        <a routerLink="/login" class="pull-right guest-link guest-btn"
                                            style="margin-right: 15px;">Existing User?
                                            Login</a>
                                    </div>
                                    <!-- </div> -->
                                </div>
                                <div class="button-box">
                                    <!-- <button type="submit" class="login-btn"
                                        (click)="emailRegisteredCustomer()"><span>Register</span></button> -->
                                    <button type="submit" [disabled]="!registerForm.valid" class="login-btn mrg-rgt-15"
                                        (click)="onRegisterCustomer()"><span>Register</span></button>
                                    <button type="submit" class="login-btn" (click)="continueAsGuest()">
                                        <span>Continue as a Guest?</span>
                                    </button>
                                    <!-- <a class="pull-right guest-link guest-btn" (click)="continueAsGuest()"
                                        style="text-decoration: underline;color: var(--main-color);">Continue as a Guest?</a> -->
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
