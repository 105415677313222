import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShowErrorsComponent } from './validation-utilities/show-errors.component';
import { MatSliderModule } from '@angular/material/slider';
import { MatDialogModule } from '@angular/material/dialog';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { ConfirmCartNavComponent } from './confirm-cart-nav/confirm-cart-nav.component';


@NgModule({
  declarations: [ShowErrorsComponent, AlertDialogComponent, ConfirmCartNavComponent],
  imports: [
    CommonModule,
    MatSliderModule,
    MatDialogModule
  ],
  exports:[ShowErrorsComponent]
})
export class SharedModule { }