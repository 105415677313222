import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../services/data.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  currentUser: any;
  emailAddress: string;
  constructor(public router: Router, private _dataService: DataService, private spinner: NgxSpinnerService) {
    this.currentUser = JSON.parse(localStorage.getItem('currentHFGUser'));

  }

  ngOnInit() {
  }

  onOrderTrackingClick() {
    if (this.currentUser == null) {
      localStorage.removeItem('previousPageURL');
      localStorage.setItem('previousPageURL', '/order-tracking');
      this.router.navigate(['/login']).then(() => {
        window.location.reload();
      });
    }
    else {
      if (this.currentUser.guestCustomer == 0) {
        this.router.navigate(['/account/my-orders']);
      }
      else {
        localStorage.removeItem('previousPageURL');
        localStorage.setItem('previousPageURL', '/order-tracking');
        this.router.navigate(['/login']).then(() => {
          window.location.reload();
        });
      }

    }
  }
  notifyCustomer() {
    // Email validation function
    const isValidEmail = (email: string): boolean => {
      // Regular expression for basic email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };

    if (!isValidEmail(this.emailAddress)) {
      this.emailAddress = "";
      this._dataService.alert('Invalid email address format', '', 'error');
      return; // Exit the function if the email is not valid
    }

    this.spinner.show();
    const req = {
      "customerNumber": this.emailAddress
    };

    this._dataService.post('Items/InsertSubscriberEmail', req)
      .subscribe((response) => {
        this.spinner.hide();
        this._dataService.alert(response.data, '', 'success');
      }, (error) => {
        this.spinner.hide();
        this._dataService.alert('Error creating notification', '', 'error');
      });
  }

}
