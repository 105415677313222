import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataService } from 'src/app/shared/services/data.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-item-details',
  templateUrl: './item-details.component.html',
  styleUrl: './item-details.component.css'
})
export class ItemDetailsComponent {
  TableData: any[] = [];
  isActive = false;
    data:any[]=[];
  @ViewChild('dt2') dt2!: any;
  selectedGroupBy = 'Order#';

  constructor(private router: Router, private _dataService: DataService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.spinner.show();
    this.updateData();
  }
  updateData() {
    this._dataService.getProductData().subscribe((data: any) => {
      this.TableData = data
      this.spinner.hide();
    })
  }

  toggleTable1() {
    this.spinner.show();
    this.router.navigate(['/admin/admin-page'])
    this.spinner.hide();
  }


  toggleTable2() {
    this.spinner.show();
    this.router.navigate(['/admin/item-detail'])
    this.spinner.hide();
  }

  ActiveButton() {
    this.isActive = !this.isActive;
  }

  searchInput(event: any) {
    const search = event.target.value;
    if (search) {
      this.dt2.filterGlobal(search, 'contains');
    }
  }
  uploadFile(data: any) {
    const file = data.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheet = workbook.Sheets[workbook.SheetNames[0]];
                XLSX.utils.sheet_to_json(sheet).map((x: any) => {
          this.data.push({
            VendorSKU: x.ItemNumber,
            Quantity: x.Quantity,
            UserID: 'HGAPI',
            MarketPlaceSupplierID: '1',
            NextAvailableDate: x.NextAvailableDate,
          });
        });
        this._dataService
          .updateExcel(this.data)
          .subscribe((res: any) => console.log(res));
      };
      reader.readAsArrayBuffer(file)
    }
  }
}
