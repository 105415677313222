import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';

const routes: Routes = [
  { path: '',  loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: 'dashboard',  loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: 'cart',  loadChildren: () => import('./shopping-cart/shopping-cart.module').then(m => m.ShoppingCartModule) },
  { path: 'wishlist',  loadChildren: () => import('./wishlist/wishlist.module').then(m => m.WishlistModule) },
  { path: 'product-details',  loadChildren: () => import('./product-details/product-details.module').then(m => m.ProductDetailsModule) },
  { path: 'personalization',  loadChildren: () => import('./personalization/personalization.module').then(m => m.PersonalizationModule) },
  { path: 'display-items',  loadChildren: () => import('./display-items/display-items.module').then(m => m.DisplayItemsModule) },
  { path: 'login',  loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  { path: 'account',  loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
  { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
  { path: 'coming-soon', component: ComingSoonComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    // onSameUrlNavigation: 'ignore',
    // scrollPositionRestoration: 'enabled'

    // Tell the router to use the hash instead of HTML5 pushstate.
    // useHash: true,

    // These aren't necessary for this demo - they are just here to provide
    // a more natural experience and test harness.
    scrollPositionRestoration: "enabled",
    anchorScrolling: "enabled",
    enableTracing: false
  }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
