<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
	<p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
<div class="breadcrumb-area pt-15 pb-15 bg-gray-3">
	<div class="container-fluid">
		<div class="breadcrumb-content text-left">
			<ul>
				<li>
					<a routerLink="/" *ngIf="previousPageURL == 'fromTrackByNumber'">Home</a>
					<a routerLink="/login" *ngIf="previousPageURL != 'fromTrackByNumber'">Home</a>
				</li>
				<li *ngIf="currentUser!=undefined && currentUser?.guestCustomer !=1">
					<a routerLink="/account/my-orders">Manage Orders</a>
				</li>
				<li class="active">Track Order </li>
			</ul>
		</div>
	</div>
</div>

<div class="checkout-area pt-10 pb-100">
	<div class="container">
		<div class="row">
			<div class="container">
				<div class="col-lg-8 offset-lg-2 pull-left">
					<div class="your-order-area payment-area address-info">
						<div class="address-title overflow-hidden">
							<h3 class="title-1 pull-left">Track Order</h3>
						</div>
						<div class="row">
							<div class="col-md-12">
								<div class="trackorder-blcok s-brd-top">
									<div class="track-left">
										<p>Order Date:</p>
									</div>
									<div class="track-right">
										<p>{{orderBasicDetails?.dateSubmitted | date}}</p>
									</div>
									<div class="track-left">
										<p>Order Number:</p>
									</div>
									<div class="track-right">
										<p>{{orderBasicDetails?.orderNumber}}</p>
									</div>
									<div class="track-left">
										<p>Order Total:</p>
									</div>
									<div class="track-right" *ngIf="orderDetails?.length==1">
										<p>{{orderBasicDetails?.cartTotal|currency}}({{orderDetails?.length}} item)</p>
									</div>
									<div class="track-right" *ngIf="orderDetails?.length>1">
										<p>{{orderBasicDetails?.cartTotal|currency}}({{orderDetails?.length}} items)</p>
									</div>
								</div>

								<div class="trackorder-blcok">
									<div class="store-status">
										<h2>Status: {{orderBasicDetails?.itemStatus}} <span class="pull-right">Shipment
												1 of 1</span></h2>
										<p>Expected Delivery: <b>{{orderBasicDetails?.expectedDeliveryDate}}</b></p>
									</div>
								</div>
								<div class="table-content table-responsive cart-table-content">
									<table style="width: 100%;">

										<tbody class="ng-star-inserted">
											@for(order of orderDetails;track order ){

											<tr class="ng-star-inserted" >
												<td class="product-thumbnail">
													<div class="cart-thum">
														<div class="cart-thum-body"><a><img
																	style="display: unset; background-color: var(--main-color);"
																	[src]="order?.primaryImageURL"></a>
														</div>
													</div>
												</td>
												<td class="product-name"><a>{{order.description}} </a>
													<p class="sell-info">Seller: Homeful Gifts</p>
													<p class="s-quanty">Qty: {{order.quantity}}</p>
												</td>
												<td class="product-subtotal"> {{order.extendedPrice|currency}}</td>
											</tr>
											<!---->
											}</tbody>
										<!---->
									</table>
									<!---->
								</div>


							</div>
						</div>
						<div class="row">
							<div class="col-lg-6"></div>
							<div class="col-lg-6">
								<!---->
								<div class="cart-total-block ng-star-inserted">
									<div class="s-sub-total">
										<p class="ng-star-inserted cust-subp" style="font-size: 18px;"
											*ngIf="orderBasicDetails?.cartTotal!=orderBasicDetails?.orderTotal">
											Sub-Total
											<span class="pull-right">{{orderBasicDetails?.cartTotal | currency}}</span>
										</p>
										<p class="ng-star-inserted cust-subp" style="font-size: 18px;"
											*ngIf="orderBasicDetails?.freightAmount!=0 && orderBasicDetails?.freightAmount!=null && orderBasicDetails?.cartTotal>0">
											Shipping Charge
											<span
												class="pull-right">{{orderBasicDetails?.freightAmount | currency}}</span>
										</p>
										<p class="ng-star-inserted cust-subp" style="font-size: 18px!important;"
											*ngIf="orderBasicDetails?.discountAmount!=null && orderBasicDetails?.discountAmount!=0 && orderBasicDetails?.cartTotal>0">
											Discount Amount <span class="pull-right">
												-{{orderBasicDetails?.discountAmount | currency}}
											</span>
										</p>
										<p class="ng-star-inserted cust-subp"
											*ngIf="orderBasicDetails?.freightAmount==0 || orderBasicDetails?.freightAmount==null"
											style="font-size: 18px!important;">Free
											Shipping</p>
										<p class="ng-star-inserted cus-totalp"
											*ngIf="orderBasicDetails?.orderTotal!=null && orderBasicDetails?.cartTotal>0">
											Cart Total
											<span class="pull-right">{{orderBasicDetails?.orderTotal | currency}}</span>
										</p>
										<p class="ng-star-inserted cus-totalp"
											*ngIf="orderBasicDetails?.orderTotal==null && orderBasicDetails?.cartTotal>0">
											Cart Total
											<span class="pull-right">{{orderBasicDetails?.orderTotal | currency}}</span>
										</p>
									</div>
								</div>
							</div>
						</div>
						<!-- <div class="your-order-wrap gray-bg-4">
							<div class="your-order-product-info">
								<table>
									<thead>
										<tr>
											<th width="75%">Product</th>
											<th width="5%">Qty</th>
											<th width="20%">Price</th>
										</tr>
									</thead>
									<tbody>
										<tr *ngFor="let cartItem of orderDetails">
											<td><span class="order-middle-left">{{cartItem.description}}</span></td>
											<td><span class="order-middle-left text-center">{{cartItem.quantity}}</span>
											</td>
											<td><span class="order-price">{{cartItem.listPrice | currency}} </span>
											</td>
										</tr>
										<tr
											*ngIf="orderBasicDetails?.orderTotalAmount != orderBasicDetails?.orderNetAmount">
											<td class="order-total"><b>Cart Total</b></td>
											<td width="90"></td>
											<td class="order-total">
												<b>{{orderBasicDetails?.orderTotalAmount | currency}}</b></td>
										</tr>
										<tr>
											<td class="order-total" width="270"><b>Shipping Charges</b></td>
											<td></td>
											<td class="order-total">
												<b>{{orderBasicDetails?.freightAmount | currency}}</b></td>
										</tr>
										<tr *ngIf="orderBasicDetails?.discountAmount>0">
											<td class="order-total"><b>Discount</b></td>
											<td width="90"></td>
											<td class="order-total">
												<b>-{{orderBasicDetails?.discountAmount| currency}}</b>
											</td>
										</tr>
										<tr>
											<td class="order-total"><b>Total</b></td>
											<td width="90"></td>
											<td class="order-total">
												<b>{{orderBasicDetails?.cartTotal | currency}}</b></td>
										</tr>
									</tbody>
								</table>
							</div>

						</div> -->
					</div>
				</div>

			</div>
		</div>
	</div>
</div>