import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfigService } from './config.service';
// import '../../rxjs-operators';
import { DataService } from './data.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
};

@Injectable()
export class AuthService {
  @Output() getLoggedInName: EventEmitter<any> = new EventEmitter();
  userData: any;
  _base_Url: string;
  _login_Url: string;

  // IPAddress: string = null;
  // OSName: string = null;
  browserName: string = null;

  public userDetails = {
    access_token: null,
    userName: null,
    userId: null
  };

  constructor(private http: HttpClient, private activatedRoute: ActivatedRoute, private _dataService: DataService, public router: Router, private config_service: ConfigService) {
    this._base_Url = config_service.getApiUrl();
    this._login_Url = config_service.getLoginUrl();

    this.browserName = this.getBrowserName();
  }

  login(req: any) {
    var os = null;
    if (req.os != null && req.browser != null) {
      os = req.os + "-" + req.browser;
    }
    var data = "grant_type=password&username=" + req.userName + "&password=" + req.password + "&device_id=" + req.ip + "&device_name=" + "WEB" + "&device_os=" + os;
    // var data = "grant_type=password&username=A024875&password=YoungsInc";
    return this.http.post(this._login_Url,
      data, httpOptions).pipe(
        map((response: any) => {
          // login successful if there's a jwt token in the response                
          if (response && response.access_token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes

            this.userDetails.access_token = response.access_token;
            this.userDetails.userName = response.userName;
            // localStorage.setItem('currentHFGUser', JSON.stringify(this.userDetails));
          }
          else {
            this.getLoggedInName.next('');
            return false;
          }
          return response;
        }));
  }

  autoLogin(token, page, offerCode, offerName, catType, value, name, groupValue, groupName, itemNumber, itemName, systemDetails) {
    var os = null;
    if (systemDetails.os != null && systemDetails.browser != null) {
      os = systemDetails.os + "-" + systemDetails.browser;
    }
    this._dataService.getAll('app/AutoLogin/' + token + "/" + systemDetails.ip + "/" + "WEB" + "/" + os).subscribe((response) => {
      if (response != null) {
        this.userData = JSON.parse(response.statusMessage);
        localStorage.setItem('isAutoLogin', JSON.stringify(true));
        localStorage.setItem('reOrder', JSON.stringify({ "reOrderItems": true }));
        this.userData.loginTime = new Date();
        localStorage.setItem('currentHFGUser', JSON.stringify(this.userData));
        localStorage.removeItem('navMessage');

        switch (page) {
          case 'home': { window.location.href = ''; } break;
          case 'category': { window.location.href = 'collection/' + value + '/' + name; } break;
          case 'items-list1': { window.location.href = 'item/' + catType + '/' + value + '/' + name + '/' + groupValue + '/' + groupName + '/1'; } break;
          case 'items-list2': { window.location.href = 'item/' + catType + '/' + value + '/' + name + '/1'; } break;
          case 'offers': { window.location.href = 'offers/' + offerCode + '/' + offerName; } break;
          case 'item-details': { window.location.href = 'item/itemDetails/' + itemNumber + '/' + itemName; } break;
          case 'items-1': { window.location.href = 'item/items/' + groupValue + '/1'; } break;
          case 'items-2': { window.location.href = 'item/items/' + groupValue + '/' + groupName + '/1'; } break;
          case 'cart': { window.location.href = 'cart'; } break;
          default: { window.location.href = ''; }
        }
      } else {
        this.router.navigate(['/login']);
      }
    })
  }

  // retailCustomerAutoLogin(token, systemDetails) {
  //   var os = null;
  //   if(systemDetails.os!=null&&systemDetails.browser!=null) {
  //     os = systemDetails.os + "-" + systemDetails.browser;
  //   }
  //   this._dataService.getAll('app/AutoLogin/'+token+"/"+systemDetails.ip+"/"+ "WEB" + "/" + os).subscribe((response) => {
  //     if (response != null) {
  //       this.userData = JSON.parse(response.statusMessage);
  //       this.userData.isRetailCustomer = true;
  //       localStorage.setItem('currentHFGUser', JSON.stringify(this.userData));
  //       window.location.href='';
  //     } else {
  //       this.router.navigate(['/login']);
  //     }
  //   })
  // }

  async logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentHFGUser');
    localStorage.removeItem('paymentInvoices');
    localStorage.removeItem('submitOrderDetails');
    localStorage.removeItem('types');
    localStorage.removeItem('payinfo');
    localStorage.removeItem('navMessage');
    localStorage.removeItem('returnAdd');
    localStorage.removeItem('claimObj');
    localStorage.removeItem('status');
    localStorage.removeItem("claimsTab");
    localStorage.removeItem('selectAddress');
    localStorage.removeItem("checkout-bill-address");
    localStorage.removeItem("checkout-ship-address");
    localStorage.removeItem("checkout-payment-info");

    localStorage.removeItem('menu');
    localStorage.removeItem('headerMenu');
    localStorage.removeItem('isAutoLogin');

    localStorage.clear();

    // this.router.navigateByUrl('');
    // window.location.href="/";
    // window.location.href = window.location.origin + '/test/';
    if (window.location.origin == "http://localhost:4200") {
      await this.router.navigate(['/']);
      window.location.reload();
    }
    else {
      this.router.navigateByUrl('')
      .then(() => {
        window.location.reload();
      });

      // this.router.navigate([window.location.href + '/'])
      //   .then(() => {
      //     window.location.reload();
      //   });
    }
  }

  getUserDetails(): any {
    return localStorage.getItem('currentHFGUser');
  }

  getUserName(): string {
    if (localStorage.getItem('currentHFGUser')) {
      let data = JSON.parse(localStorage.getItem('currentHFGUser'));
      return data.userName;
    } else {
      return "";
    }
  }

  getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  getSystemDetails() {
    // https://jsonip.com
    // http://ipinfo.io
    return this.http.get<any>('https://jsonip.com').pipe(
      map((response: any) => {
        if (response != null) {
          var OSName = "Unknown OS";
          if (window.navigator.userAgent.indexOf("Win") != -1) OSName = "Windows";
          if (window.navigator.userAgent.indexOf("Mac") != -1) OSName = "Macintosh";
          if (window.navigator.userAgent.indexOf("Linux") != -1) OSName = "Linux";
          if (window.navigator.userAgent.indexOf("Android") != -1) OSName = "Android";
          if (window.navigator.userAgent.indexOf("like Mac") != -1) OSName = "iOS";
          return {
            ip: response.ip != null ? response.ip : null,
            os: OSName,
            browser: this.browserName
          };
        }
        else {
          return null;
        }
      }));
  }

  getLoginTimeDifference(): number {
    var currentUser = JSON.parse(localStorage.getItem('currentHFGUser'));
    var date1 = new Date();
    var date2 = new Date(currentUser.loginTime);
    var diff = Math.abs(date1.getTime() - date2.getTime()) / 36e5;
    return diff;
  }
}
