import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataService } from 'src/app/shared/services/data.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { UtilityService } from 'src/app/shared/services/utilityService';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-manage-payment',
  templateUrl: './manage-payment.component.html',
  styleUrls: ['./manage-payment.component.css']
})
export class ManagePaymentComponent implements OnInit {

  isAddNewCard: boolean = false;
  isEditCC: boolean = false;
  paymentList: any;
  payInfoReq: { "customerNumber": any; };
  createCC: any;
  currentUser: any;
  bilAddressInfolist: any;
  paymentForm: FormGroup;
  selectedBillAddress: any = [];
  expirydate: any;
  cardNumber: string;
  isValidExpiryDate: boolean = true; 
  ccType: any;
  constructor(private router: Router, private spinner: NgxSpinnerService, private _dataService: DataService, private utilservice: UtilityService, private fb: FormBuilder, public titleService: Title, public dialog: MatDialog) {
    this.spinner.show();
    this.titleService.setTitle('My Payment | Homeful Gifts');
    this.currentUser = JSON.parse(localStorage.getItem('currentHFGUser'));
    this.payInfoReq = { "customerNumber": this.currentUser.subCustomerNumber };
    this.createCC = { cardNumber: null, fullname: null, expirydate: null, cvv: null };
    this.paymentForm = fb.group({
      'cardnumber': [null, Validators.compose([Validators.required, Validators.required])],
      'fullname': [null, Validators.compose([Validators.required, Validators.maxLength(30), Validators.pattern('^[a-zA-Z ]*$')])],
      'expirydate': [null, Validators.compose([Validators.required, Validators.maxLength(5)])],
      'cvv': [null, Validators.compose([Validators.required, Validators.maxLength(4)])]
    });
  }
  ngOnInit(): void {
    this.getCustomerSHPAddressesInfo('SHP');
    this.getPaymentCardDetails();

    let currentUser = localStorage.getItem('currentHFGUser');
    if (currentUser == null || currentUser == undefined) {
      this.router.navigate(['/']);
    }
  }

  validateCardNumber(cardNo) {
    
    var req = {
      "cardNo": this.utilservice.removeSpaces(cardNo)
    }
    this._dataService.post('Config/ValidateCreditCard', req)
      .subscribe((response) => {
        
        var cctype = response.data.type;
        this.ccType = cctype;
        if (cctype == "NOCC") {
          // this.validcard = "Please provide a valid Credit Card Number";
        }
      })
  }

  addNewCreditCard() {

    var expMonth = +this.paymentForm.value.expirydate.slice(0, 2);
    var expYear = "20" + this.paymentForm.value.expirydate.slice(-2);

    if ((expMonth <= 12) && (new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getTime() < new Date(+expYear, expMonth, 1).getTime())) {
      this.spinner.show();
      var req = {
        "customerNumber": this.currentUser.customerNumber,
        "creditCardId": 0,
        "billAddressId": this.selectedBillAddress.hgGenericAddressID,
        "ccMasked": null,
        "claimNumber": null,
        "creditCardNumber": this.paymentForm.value.cardnumber,
        "ccType": this.ccType,
        "ccName": this.paymentForm.value.fullname,
        "expMonth": this.paymentForm.value.expirydate.slice(0, 2),
        "expYear": "20" + this.paymentForm.value.expirydate.slice(-2),
        "cvvCode": this.paymentForm.value.cvv,
        "billZip": this.selectedBillAddress.zipCode,
        "ccStreetAddress": this.selectedBillAddress.address1,
        "city": this.selectedBillAddress.city,
        "state": this.selectedBillAddress.state,
        "country": this.selectedBillAddress.country,
        "orderAlias": null,
        "userID": this.currentUser.customerNumber,
        "note": "Added"
      }
      this._dataService.post('app/InsertUpdateCCInfo', JSON.stringify(req))
        .subscribe((response) => {

          this.isAddNewCard = !this.isAddNewCard;
          this.paymentForm.reset();
          this._dataService.alert("Card Details added successfully",'','success');
          this.getPaymentCardDetails();
          this.spinner.hide();
        }, (error) => {

          if (error.error.message == "Please provide a unique customer ID.") {
          this._dataService.alert("Credit Card Already Exists",'','info');
          }
          else {
           this._dataService.alert(error.error.message,'','error');
          }
          this.spinner.hide();
        });
    }
    else {
      this.isValidExpiryDate = false;
      this._dataService.alert("Please enter a valid Expiry Date",'','');
    }
  }

  updateCreditCard() {
    this.spinner.show();
    var expMonth = +this.createCC.expirydate.split('/')[0];
    var expYear = "20" + this.createCC.expirydate.split('/')[1];

    if ((expMonth <= 12) && (new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getTime() < new Date(+expYear, expMonth, 1).getTime())) {
      var req = {
        "customerNumber": this.currentUser.customerNumber,
        "creditCardId": this.createCC.creditCardID,
        "billAddressId": this.selectedBillAddress.hgGenericAddressID,
        "ccMasked": null,
        "claimNumber": null,
        "creditCardNumber": this.createCC.cardnumber,
        "ccType": this.ccType,
        "ccName": this.createCC.fullname,
        "expMonth": this.createCC.expirydate.slice(0, 2),
        "expYear": "20" + this.createCC.expirydate.slice(-2),
        "cvvCode": this.createCC.cvv,
        "billZip": this.selectedBillAddress.zipCode,
        "ccStreetAddress": this.selectedBillAddress.address1,
        "city": this.selectedBillAddress.city,
        "state": this.selectedBillAddress.state,
        "country": this.selectedBillAddress.country,
        "orderAlias": null,
        "userID": this.currentUser.customerNumber,
        "note": "Updated"
      }

      this._dataService.post('app/InsertUpdateCCInfo', JSON.stringify(req))
        .subscribe((response) => {
          this._dataService.alert("Card Details updated successfully",'','success');
          this.getPaymentCardDetails();
          this.isEditCC = !this.isEditCC;
          this.paymentForm.reset();
          this.spinner.hide();
        }, (error) => {
          if (error.error.message == "Please provide a unique customer ID.") {
            this._dataService.alert("Credit Card Already Exists",'','info');
          }
          else {
            this._dataService.alert(error.error.message,'','error');
          }
          this.spinner.hide();
        });
    }
    else {
      this.spinner.hide();
      this._dataService.alert("Please enter a valid Expiry Date",'','info');
    }
  }

  onDeleteClick(cardDetails) {
    let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { title: "Delete Card Details", msg: "Are you sure you want to delete the Card Details?", ok: 'Ok', cancel: 'Cancel' },
      width: 'auto',
      height: 'auto',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.spinner.show();

        var req = {
          "customerNumber": this.currentUser.customerNumber,
          
          "ccMasked": cardDetails.ccMasked,
          "creditCardId": cardDetails.creditCardID
        }
        this._dataService.post('Order/DeleteCreditCardInfo', JSON.stringify(req))
          .subscribe((response) => {

            this.getPaymentCardDetails();
            this._dataService.alert("Credit Card Deleted Successfully",'','success');
            this.createCC = { cardNumber: null, fullname: null, expirydate: null, cvv: null };
            this.spinner.hide();
          }, (error) => {
            this._dataService.alert("Credit Card Deletion Failed",'','error');
            this.spinner.hide();
          });
      }
    });

  }
  getPaymentCardDetails() {
    this.spinner.show();
    var req = {
      "customerNumber": this.currentUser.customerNumber,
      // "subCustomerNumber": this.currentUser.subCustomerNumber
    }
    this._dataService.post('Payments/GetHFGCustomerExistingCCs', JSON.stringify(req))
      .subscribe((response) => {

        this.paymentList = response.data;
        this.paymentList.forEach((item, index) => {
          item.isChecked = index == 0 ? true : false;

          var dates = item.expDt.split('/');
          var month = dates[0].length == 1 ? ("0" + dates[0]).toString() : dates[0];
          var year = dates[1].slice(-2);
          item.expDt = month + "/" + year
        });
        this.spinner.hide();
      }, (error) => {

        this.spinner.hide();
      });
  }

  expiryDateInput(length) {
    this.isValidExpiryDate = true;
    if (length == 2) {
      this.expirydate = this.expirydate + '/'
    }
  }

  expiryDateInputEdit(length) {

    if (length == 2) {
      this.createCC.expirydate = this.createCC.expirydate + '/'
    }
  }

  // To Get Customer Bil Adresses Information  
  getCustomerSHPAddressesInfo(addtype) {
    this.spinner.show();
    var AddressReq = {
      "customerNumber": this.currentUser.customerNumber,
      
      "addressType": addtype,
      "addressNumber": 0
    };

    this._dataService.post('app/GetHFGCustomerAddresses', JSON.stringify(AddressReq))
      .subscribe((response) => {

        this.bilAddressInfolist = response.data;
        this.bilAddressInfolist.forEach(element => {
          if (element.mainAddress != 1) {
            element.isSelected = false;
          }
          else {
            element.isSelected = true;
          }
        });
      }, (error) => {
      });
  }
  deactivate(value, optionValue) {

    this.bilAddressInfolist.forEach((element, index) => {
      if (value != index) {
        element.isSelected = false;
      }
      else {
        element.isSelected = true;
        if (optionValue) {
          this.selectedBillAddress = element;
        }
        else {
          this.selectedBillAddress = [];
        }
      }
    });
  }
  onEditCC(cardDetails) {
    
    this.bilAddressInfolist.forEach(element => {
      if (element.hgGenericAddressID == cardDetails.billAddressID) {
        element.isSelected = true;
      }
      else {
        element.isSelected = false;
      }
    });
    this.isEditCC = true;
    this.createCC.creditCardID = cardDetails.creditCardID;
    this.createCC.cardnumber = cardDetails.creditCard_.length > 18 ? cardDetails.creditCard_.replace('-X', '-') : cardDetails.creditCard_;
    this.createCC.fullname = cardDetails.cardName;
    this.createCC.expirydate = cardDetails.expDt;
    this.createCC.cvv = cardDetails.cvV_;
    this.selectedBillAddress.zipCode = cardDetails.zipCode;
    this.selectedBillAddress.address1 = cardDetails.address;
    this.selectedBillAddress.city = cardDetails.city;
    this.selectedBillAddress.state = cardDetails.state;
    this.selectedBillAddress.country = cardDetails.country;
    this.selectedBillAddress.hgGenericAddressID = cardDetails.billAddressID;
    this.selectedBillAddress.creditCardID = cardDetails.creditCardID;
  }

  onCancelClick() {
    this.paymentForm.reset();
  }
  onCreditCardChange(plainCreditCard: string): string {
    if (plainCreditCard != null) {
      this.validateCardNumber(plainCreditCard);
      this.cardNumber = plainCreditCard.replace(/\s+/g, '').replace(/(\d{4})/g, '$1 ').trim();
      return this.cardNumber;
    }
  }
  // only accept numbers
  numberOnly(event: any) {
    const numberpattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!numberpattern.test(inputChar)) {
      event.preventDefault();
    }
  }
}
