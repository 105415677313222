import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ItemCategoryComponent } from './item-category/item-category.component';
import { DisplayItemsRoutingModule } from './display-items-routing.module';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { ProductListComponent } from './product-list/product-list.component';
import { FashionComponent } from './fashion/fashion.component';

@NgModule({
  declarations: [
    ItemCategoryComponent,
    ProductListComponent,
    FashionComponent
  ],
  imports: [
    CommonModule,
    DisplayItemsRoutingModule,
    CarouselModule,
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule
  ]})
export class DisplayItemsModule { }