<div class="container">
  <h1>Coming Soon!!</h1>
  <p> We are still working on our Socials!!</p>
  <div id="countdown">
    <div><span id="days"></span> Days</div>
    <div><span id="hours"></span> Hours</div>
    <div><span id="minutes"></span> Minutes</div>
    <div><span id="seconds"></span> Seconds</div>
  </div>
  <br>

  <div class="social-icons">
    <!-- Icons for social media -->
  </div>
</div>
