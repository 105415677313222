<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>

<div [ngClass]="[showMainDiv ? 'tab-menu-block mb-40':'tab-menu-block mb-40 disable-area']" style="padding-top: 10px;" #subItems>
    <div class="tab-menu-heading"
        *ngIf="selectedMenuDetails.childValue!=undefined && selectedMenuDetails.childValue!='Home-Screen Category'">
       
        <h2 *ngIf="selectedMenuDetails.childValue != 'TOP' && selectedMenuDetails.childValue != 'BEST' && selectedMenuDetails.childValue != 'NEW'">{{selectedMenuDetails.childValue}}</h2>
        <h2 *ngIf="selectedMenuDetails.childValue == 'NEW'">{{selectedMenuDetails.childValue}} ARRIVALS</h2>
        <h2 *ngIf="selectedMenuDetails.childValue == 'BEST'">{{selectedMenuDetails.childValue}} SELLER</h2>
        <h2 *ngIf="selectedMenuDetails.childValue == 'TOP'">{{selectedMenuDetails.childValue}} RECOMMENDED</h2>

    </div>
    <div class="tab-menu-heading"
        *ngIf="selectedMenuDetails.childValue!=undefined && selectedMenuDetails.childValue=='Home-Screen Category'">
        <h2>Category</h2>
    </div>
    <div class="tab-menu-cat">
        <div class="tab-menu-img" *ngFor="let subCategory of selectedSubCategoryList">
            <a (click)="reAssignSelectedSubMenu(subCategory, i)">
                <img [src]="subCategory.imageUrl" class="img-responsive">
                <span class="mt-10">{{subCategory.subCategoryName}}</span>
            </a>
        </div>
    </div>
    <div class="tab-menu-cat"
        *ngIf="selectedMenuDetails.childValue != 'ALL' && selectedMenuDetails.childValue != 'BEST' && selectedMenuDetails.childValue != 'NEW'">
        @for(subMenu of subMenuList; track subMenu;let i = $index) {
        <div class="tab-menu-img">
            <a (click)="reAssignSelectedSubMenu(subMenu,i);">
                <img [src]="categoryImgPath.url+subMenu?.childValue+categoryImgPath.extension" class="img-responsive"
                    (error)="errorHandler($event)" *ngIf="selectedMenuDetails.childValue != 'Made in America'">
                <img [src]="categoryImgPath.url+subMenu?.childValue+categoryImgPath.extension" class="img-responsive"
                    (error)="errorHandler($event)" *ngIf="selectedMenuDetails.childValue == 'Made in America'">
                <span class="mt-10">{{subMenu.childValue}}</span>
            </a>
        </div>
        }
    </div>
</div>

<div class="breadcrumb-area pt-10 pb-10 bg-gray-3" #productSection>
    <div class="container-fluid">
        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-7 nopadding">
                <div class="breadcrumb-content text-left mrg-top-7">
                    <ul
                        *ngIf="selectedMenuDetails!='search-bar' && selectedMenuDetails!='best-seller' && !otherCategoriesBreadcrumb && !isFullWebsiteForBreadCrumb">
                        <li>
                            <a routerLink="/">Home</a>
                        </li>
                        <li>
                            <a href="javascript:void(0)"
                                *ngIf="selectedMenuDetails.childValue!='Home-Screen Category'">{{selectedMenuDetails.childValue}}</a>
                            <a *ngIf="selectedMenuDetails.childValue=='Home-Screen Category'">Category</a>
                        </li>
                        <li class="active" *ngIf="selectedSubCategoryName != null && selectedSubCategoryName != ''">{{selectedSubCategoryName}} </li>
                    </ul>
                    <ul *ngIf="selectedMenuDetails=='search-bar' && !isFullWebsiteForBreadCrumb">
                        <li>
                            <a routerLink="/">Home</a>
                        </li>
                        <li class="active">{{description}} </li>
                    </ul>
                    <ul *ngIf="selectedMenuDetails=='best-seller' && !isFullWebsiteForBreadCrumb">
                        <li>
                            <a routerLink="/">Home</a>
                        </li>
                        <li class="active">Most Popular</li>
                    </ul>
                    <ul *ngIf="otherCategoriesBreadcrumb && !isFullWebsiteForBreadCrumb">
                        <li>
                            <a routerLink="/">Home</a>
                        </li>
                        <li class="active">{{selectedMenuDetails.childValue}} </li>
                    </ul>
                    <ul *ngIf="isFullWebsiteForBreadCrumb">
                        <li>
                            <a routerLink="/">Home</a>
                        </li>
                        <li class="active">All Categories </li>
                    </ul>
                </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-10 offset-md-2 offset-lg-0 col-lg-6 offset-xl-0 col-xl-5 nopadding">
                <div class="form-group row mb-0 filter-grp">
                    <label for="exampleFormControlSelect1"
                        class="col-sm-3 col-form-label nopadright text-right">Sort&nbsp;&nbsp;</label>
                    <div class="col-sm-9 nopadleft">
                        <select class="form-control" id="exampleFormControlSelect1" name="defaultSort"
                            [(ngModel)]="defaultSort"
                            (change)="onFilterSelect(selectedSubCategoryID,selectedSubCategoryIndex,defaultSort)">
                            <option *ngFor="let sortOption of sort" [ngValue]="sortOption.sortValue">
                                {{sortOption.sortDesc}}</option>
                        </select>
                    </div>
                </div>
                <div class="item-count">
                    <p>Item Count: <span>{{subMenuItemsList?.length}}</span></p>
                </div>
            </div>
        </div>
    </div>
</div>


<div
    [ngClass]="[showMainDiv ? 'shop-area pt-15 pb-100 section-padding-1 bg-white':'shop-area pt-15 pb-100 section-padding-1 bg-white disable-area']">
    <div class="container-fluid">
        <div class="row" style="margin-left:0px;margin-right:0px;">
            <!-- <div class="col-lg-2 col-md-3">
                <div class="sidebar-style">
                    <div class="sidebar-widget">
                        <h4 class="pro-sidebar-title title-1">Filter By Item</h4>
                        <div class="sidebar-widget-list mt-10">
                            <ul>
                                <li>
                                    <div class="">
                                        <label class="control-label">Description</label>
                                        <input type="text" [(ngModel)]="description"
                                            (keydown.enter)="onFilterSelect(selectedSubCategoryID,selectedSubCategoryIndex,defaultSort)"
                                            class="form-control" placeholder="Enter Description" name="description">
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="sidebar-widget mt-30">
                        <h4 class="pro-sidebar-title title-1">Filter By Price</h4>
                        <div class="price-filter mt-20 row">
                            <div class="col-md-12 col-xl-12 nopadding">
                                <div class="range-slider">
                                    <input type="range" min="0" [max]="maxSliderPrice" step="1" [(ngModel)]="maxPrice"
                                        class="custom-slider">
                                    <div class="slider-labels">
                                        <span>{{minPrice | currency}}</span>
                                        <span>{{maxPrice | currency}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="sidebar-widget mt-30">
                        <figcaption>
                            <a class="filter-button"
                                (click)="onFilterSelect(selectedSubCategoryID,selectedSubCategoryIndex,defaultSort)">Filter</a>
                            <a class="filter-button" (click)="clearFilter()">Clear Filter</a>
                        </figcaption>
                    </div>
                </div>
                <div class="new-product-display" *ngIf="currentProduct">
                    <h2>RECOMMENDED</h2>
                    <div class="container">
                        <owl-carousel-o [options]="customOptionsRecentlyViewed">
                            <ng-template carouselSlide *ngFor="let item of products; let index = index">
                                <div class="product-wrap" style="padding-right: 3px; max-width: 250px !important; width: auto !important">
                                    <div class="product-img product-img-res-car">
                                        <div class="stock-tag" *ngIf="item.currentAvailableQty < 6">
                                            <img src="assets/img/piece-tag.png">
                                            <span>Only {{item.currentAvailableQty}} left</span>
                                        </div>
                                        <div class="stock-tag" *ngIf="item.countryOfOrigin == 'USA'">
                                            <img class="made-usa" src="assets/img/USA.png" title="Made in USA">
                                        </div>
                                        <div class="stock-tag" *ngIf="item.currentAvailableQty == 0">
                                            <img src="assets/img/piece-tag.png">
                                            <span>Notify me</span>
                                        </div>
                                        <a (click)="productDetailsNav(item, $event)">
                                            <img class="default-img" [src]="item.primaryImageURL" alt=""
                                                (error)="errorHandlerForItemImages($event)"
                                                style="background-color: var(--main-color); display: unset">
                                        </a>
                                        <a class="product-quicview" (click)="productDetailsNav(item, $event)"></a>
                                        <div class="product-action">
                                            <div class="pro-same-action pro-wishlist">
                                                <a title="Wishlist" (click)="addUpdateWishList(item,0,null)"
                                                    *ngIf="item.isInWishList == 0">
                                                    <i class="fa fa-heart-o"></i>
                                                </a>
                                                <a title="Wishlist" (click)="addUpdateWishList(item,2,null)"
                                                    *ngIf="item.isInWishList == 1">
                                                    <i class="fa fa-heart" *ngIf="item.isInWishList == 1"></i>
                                                </a>
                                            </div>
                                            <div class="pro-same-action pro-cart">
                                                <a title="Add To Cart"
                                                    (click)="addItemToCart(item,null)"
                                                    *ngIf="item.shoppingCartQty == 0">
                                                    <i class="pe-7s-cart"></i>
                                                </a>
                                                <a title="View Cart" *ngIf="item.shoppingCartQty>0 && item.currentAvailableQty>0" routerLink="/cart">
                                                    <i class="fa fa-shopping-cart"></i>
                                                </a>
                                            </div>
                                            <div class="pro-same-action pro-quickview">
                                                <a title="Details" data-toggle="modal" (click)="productDetailsNav(item, $event)"><i
                                                        class="fa fa-eye"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="product-content text-center">
                                        <h3><a>{{item.description | slice:0:18}}</a></h3>
                                        <div class="product-rating" *ngIf="item?.itemRating > 0">
                                            <i [ngClass]="[item?.itemRating > 0 ? 'fa fa-star-o yellow' : 'fa fa-star-o']"></i>
                                            <i [ngClass]="[item?.itemRating > 1 ? 'fa fa-star-o yellow' : 'fa fa-star-o']"></i>
                                            <i [ngClass]="[item?.itemRating > 2 ? 'fa fa-star-o yellow' : 'fa fa-star-o']"></i>
                                            <i [ngClass]="[item?.itemRating > 3 ? 'fa fa-star-o yellow' : 'fa fa-star-o']"></i>
                                            <i [ngClass]="[item?.itemRating == 5 ? 'fa fa-star-o yellow' : 'fa fa-star-o']"></i>
                                        </div>
                                        <div *ngIf="item?.listPrice != item?.dealPrice">
                                            <div style="margin-right: 10px;">
                                                <span style="text-decoration: line-through">{{item?.listPrice | currency}}</span>
                                                <span class="product-price"
                                                    style="color: rgb(11, 128, 104);font-size: 18px;">&nbsp;<b>{{item?.dealPrice |
                                                        currency}}</b></span>
                                                <div style="color: #fe5252">{{item?.discountPercent}}% off</div>
                                            </div>
                                        </div>
                                        <div class="product-price" *ngIf="item?.listPrice == item?.dealPrice">
                                            <span>{{item?.listPrice | currency}}</span>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </owl-carousel-o>
                    </div>
                </div>

            </div> -->
            <div class="col-lg-12 col-md-12">
                <div class="shop-bottom-area">
                    <div class="tab-content jump">
                        <div id="shop-11" class="tab-pane active">
                            <div class="row cust-row">
                                <div *ngFor="let item of subMenuItemsList; let index = index"
                                    class="col-xl-3 col-md-4 col-lg-4 col-sm-4 item-col-xl col-mb-6 nopadding-3 col-lg-cust-4">
                                    <div class="product-wrap mb-25 scroll-zoom" *ngIf="subMenuItemsList.length>0">
                                        <div class="product-img product-img-items bg-white">
                                            <div class="stock-tag" *ngIf="item.currentAvailableQty<6">
                                                <img src="assets/img/piece-tag.png">
                                                <span>Only {{item.currentAvailableQty}} left</span>
                                            </div>
                                            <div class="stock-tag"
                                                *ngIf="item.countryOfOrigin=='USA' && selectedMenuDetails.childValue != 'Made in America'">
                                                <img class="made-usa" src="assets/img/USA.png" title="Made in USA">
                                            </div>
                                            <div class="stock-tag" *ngIf="item.currentAvailableQty==0">
                                                <img src="assets/img/piece-tag.png">
                                                <span>Notify Me</span>
                                            </div>
                                            <a (click)="productDetailsNav(item, $event)">
                                                <img [src]="item.primaryImageURL"
                                                    (error)="errorHandlerForItemImages($event)"
                                                    style="background-color: var(--main-color);">
                                            </a>
                                            <a class="product-quicview" (click)="productDetailsNav(item, $event)"
                                                (contextmenu)="onRightClick(item, $event)"
                                                [routerLink]="item.groupKey != undefined ? ['/product-details',item.groupKey] : (selectedSubCategoryID == undefined ? ['/product-details',0] : ['/product-details',selectedSubCategoryID])"></a>
                                            <span class="purple" *ngIf="item.itemTag == 'New'">New</span>
                                            <div class="product-action">
                                                <div class="pro-same-action pro-wishlist">
                                                    <a title="Wishlist" (click)="addUpdateWishList(item,0,index)"
                                                        *ngIf="item.isInWishList == 0">
                                                        <i class="fa fa-heart-o"></i>
                                                    </a>
                                                    <a title="Wishlist" (click)="addUpdateWishList(item,2,index)"
                                                        *ngIf="item.isInWishList == 1">
                                                        <i class="fa fa-heart" *ngIf="item.isInWishList == 1"></i>
                                                    </a>
                                                </div>
                                                <div class="pro-same-action pro-cart">
                                                    <a [ngClass]="[item.currentAvailableQty==0?'disable-area':'']"
                                                        title="Add To Cart" (click)="addItemToCart(item,index)"
                                                        *ngIf="item.shoppingCartQty==0 && item.currentAvailableQty>0">
                                                        <i class="pe-7s-cart"></i>
                                                    </a>
                                                    <a title="View Cart"
                                                        *ngIf="item.shoppingCartQty>0 && item.currentAvailableQty>0"
                                                        routerLink="/cart">
                                                        <i class="fa fa-shopping-cart"></i>
                                                        <!-- &nbsp;View cart -->
                                                    </a>
                                                    <a title="Notify Me"
                                                        *ngIf="item.shoppingCartQty==0 && item.currentAvailableQty==0"
                                                        routerLink="/cart">
                                                        <i class="fa fa-flag"></i>
                                                        <!-- &nbsp;Notify Me -->
                                                    </a>
                                                </div>
                                                <div class="pro-same-action pro-quickview">
                                                    <a title="Details" data-toggle="modal"
                                                        (click)="productDetailsNav(item, $event)"><i
                                                            class="fa fa-eye"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="product-content text-center">
                                            <!-- <h3><a (click)="productDetailsNav(item, $event)">{{item.itemNumber}}</a></h3> -->
                                            <h3><a (click)="productDetailsNav(item, $event)">{{item.description |
                                                    slice: 0:25}}<span *ngIf="item.assortments>1"> -
                                                        {{item.assortments}} Piece Set</span></a></h3>
                                            <div *ngIf="item?.listPrice != item?.dealPrice">
                                                <div style="margin-right: 10px;">
                                                    <span style="text-decoration: line-through">{{item?.listPrice | currency}}</span>
                                                    <span class="product-price" style="color: rgb(11, 128, 104);font-size: 18px;">&nbsp;{{item?.dealPrice | currency}}</span>
                                                    <div style="color: #fe5252">{{item?.discountPercent}}% off</div>
                                                </div>
                                            </div>
                                            <div class="product-price" *ngIf="item?.listPrice == item?.dealPrice">
                                                <span>{{item?.listPrice | currency}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="subMenuItemsList?.length==0" style="padding-left: 50%">No Items Found</div>
                            </div>
                        </div>
                        <div id="shop-21">
                            <div *ngFor="let item of subMenuItemsList; let index = index" class="shop-list-wrap mb-30">
                                <div class="row">
                                    <div class="col-xl-4 col-lg-5 col-md-5 col-sm-6">
                                        <div class="product-wrap">
                                            <div class="product-img product-img-list bg-white">
                                                <div class="stock-tag" *ngIf="item.currentAvailableQty<6">
                                                    <img src="assets/img/piece-tag.png">
                                                    <span>Only {{item.currentAvailableQty}} left</span>
                                                </div>
                                                <div class="stock-tag"
                                                    *ngIf="item.countryOfOrigin=='USA' && selectedMenuDetails.childValue != 'Made in America'">
                                                    <img class="made-usa" src="assets/img/USA.png" title="Made in USA">
                                                </div>
                                                <div class="stock-tag" *ngIf="item.currentAvailableQty==0">
                                                    <img src="assets/img/piece-tag.png">
                                                    <span>Notify Me</span>
                                                </div>
                                                <a (click)="productDetailsNav(item, $event)">
                                                    <img class="default-img"
                                                        style="background-color: var(--main-color);"
                                                        [src]="item.primaryImageURL"
                                                        (error)="errorHandlerForItemImages($event)" alt="">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-8 col-lg-7 col-md-7 col-sm-6">
                                        <div class="shop-list-content">
                                            <h3><a (click)="productDetailsNav(item, $event)">{{item.description |
                                                    slice: 0:25}}<span *ngIf="item.assortments>1"> -
                                                        {{item.assortments}} Piece Set</span></a></h3>
                                            <div class="product-list-price">
                                                <span style="color: black">{{item.listPrice | currency}}</span>
                                            </div>
                                            <div class="item-desc-list">
                                                <ul style="margin: 15px 0 40px;line-height: 28px;font-size: 15px;">
                                                    <li *ngFor="let itemSpec of item.itemSpecificationDetails">
                                                        {{itemSpec}}</li>
                                                </ul>
                                            </div>
                                            <div class="shop-list-btn btn-hover row">
                                                <div class="pro-same-action pro-wishlist">
                                                    <a title="Wishlist" (click)="addUpdateWishList(item,0,index)"
                                                        *ngIf="item.isInWishList == 0">
                                                        <i class="fa fa-heart-o"></i>
                                                    </a>
                                                    <a title="Wishlist" (click)="addUpdateWishList(item,2,index)"
                                                        *ngIf="item.isInWishList == 1" style="padding: 14px 20px 15px">
                                                        <i class="fa fa-heart" *ngIf="item.isInWishList == 1"></i>
                                                    </a>
                                                </div>
                                                <div class="pro-same-action pro-cart">
                                                    <a title="Add To Cart" (click)="addItemToCart(item,index)"
                                                        *ngIf="item.shoppingCartQty==0">
                                                        <i class="pe-7s-cart"></i>
                                                    </a>
                                                    <a title="View Cart" *ngIf="item.shoppingCartQty>0"
                                                        routerLink="/cart">
                                                        <i class="pe-7s-cart"></i>
                                                    </a>
                                                </div>
                                                <div class="pro-same-action pro-quickview">
                                                    <a title="Details" (click)="productDetailsNav(item, $event)"><i
                                                            class="fa fa-eye"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="subMenuItemsList?.length==0" style="padding-left: 50%">No Items Found</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>