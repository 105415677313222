import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ItemCategoryComponent } from './item-category/item-category.component';
import { FashionComponent } from './fashion/fashion.component';
import { ProductListComponent } from './product-list/product-list.component';

const routes: Routes = [
  {
    path: '', children: [
      {
        path: ':catName/:catValue/:subCatValue', component: ItemCategoryComponent, pathMatch: 'full'
      },
      {
        path: 'fashion', component: FashionComponent, pathMatch: 'full'
      },
      {
        path: 'product-list/:menu', component: ProductListComponent, pathMatch: 'full'
      }
    ]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DisplayItemsRoutingModule { }