<div *ngIf="cancel" class="cancel-container">
    <div class="cancel">
      <div style="display:flex;justify-content:center;font-family:Roboto;">Are you sure that you want to delete?</div>
      <div style="text-align:center;"><textarea rows="3"[(ngModel)]="reason" placeholder="Reason" class="custom-input"></textarea></div>
      <div style="display:flex;justify-content:space-around;">
        <button (click)="confirmCancel()" style="border-radius:70px;width:6.5em;border:none;color:white;background:black;height:2.5em;"><strong>Cancel Item</strong></button>
        <button (click)="notConfirm()" style="border-radius:70px;width:6.5em;border:none;background:lightgrey;height:2.5em;">Go Back</button>
      </div>
    </div>
  </div>
  
  <div class="bg-container" [ngClass]="{'active':cancel}">
    <div class="header-container">
      <h2>Order Details</h2>
      <button (click)="navigateToHome()">Go back</button>
    </div>
    <div class="order-container">
      <div class="order">
        <div>
        <div class="banner">
          <h1>Order#: {{itemId}}</h1>
          <div>{{item[0].dateSubmitted | date:'MMM'}} {{item[0].dateSubmitted | date:'dd'}} at {{item[0].dateSubmitted | date:'HH:mm a'}}</div>
        </div>
        @if(item[0].orderStatus !=="INV" && item[0].orderStatus!=="CAN"){
        <div style="display:flex;justify-content:flex-end;padding:0 1em 0.5em 0 ">
          <div *ngIf="!cancelSelected" style="color:red;margin-left:1em">Please select an item</div>
          <button  class="cancel-button" (click)="cancelButton()">Cancel</button>
        </div>
        }
        <div class="table-container">
          <cdk-virtual-scroll-viewport itemSize="10" class="table-container">
            <table class="responsive-table">
              <thead>
                <tr>
                  <th class="col-5">
                    <input type="checkbox" [checked]="SelectAll()" [disabled]="item[0].can" (change)="toggleAll($event)" style="width: 15px!important;" />
                  </th>
                  <th class="col-5">Image</th>
                  <th class="col-50">Item</th>
                  <th class="col-10">Quantity</th>
                  <th class="col-10">Price</th>
                  <th class="col-10">Discount</th>
                  <th class="col-10">Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr *cdkVirtualFor="let x of item" [style.background]="getBackgroundColor(x.selected)" [style.color]="getColor(x.selected)">
                  <td>
                    <div style="display:flex"><input type="checkbox" [(ngModel)]="x.selected" (change)="checkBox($event)" [disabled]="x.cancelDate" style="width: 15px!important" /></div>
                  </td>
                  <td>
                    <div class="image-container">
                      <img src={{x.primaryImageURL}} class="hover-image" (error)="errorHandlerForItemImages($event)">
                      <div class="hover-block">
                        <img src={{x.primaryImageURL}} (error)="errorHandlerForItemImages($event)">
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>{{x.itemNumber}}
                      <span *ngIf="x.cancelDate" class="cancelled-container">
                        <span class="cancel-status"><strong>CAN</strong></span>
                        <div class="hover-cancel">
                          <div>Cancelled on:{{x.cancelDate}}</div>
                          <div>Reason:{{x.cancelReason}}</div>
                        </div>
                      </span>
                    </div>
                    <p>({{x.description}})</p>
                  </td>
                  <td>{{x.quantity}}</td>
                  <td>{{x.listPrice | number:'1.2-2'}}</td>
                  <td>{{x.discountAmount | number:'1.2-2'}}</td>
                  <td>{{x.extendedPrice | number:'1.2-2'}}</td>
                </tr>
              </tbody>
            </table>
          </cdk-virtual-scroll-viewport>
        </div>
      </div>
        <div style="display:flex;">
          <div  style="text-align:center;">@if(item[0].couponCode){Coupon:{{item[0].couponCode}}}</div>
        </div>
        <div  class="note-container">
          <div class="note">
            @if(item[0].deliveryInstructions){
            <h5>Order Instructions:</h5>
            <div>{{item[0].deliveryInstructions}}</div>
            }
          </div>
          <div class="others">
            <div class="others-subcontainer">
              <div style="display:flex;justify-content:space-between">
                <div>Net Order$:</div>
              <div>{{netOrder() | currency}}</div>
              </div>
              <div style="display:flex;justify-content:space-between">
                <div>Sales Tax:</div>
                <div>{{item[0].salesTaxAmount|currency}}</div>
              </div>
              <div style="display:flex;justify-content:space-between">
                <div>Freight:</div>
                <div>{{item[0].freightAmount|currency}}</div>
              </div>
              <div style="display:flex;justify-content:space-between;padding:1em 0">
                <div>Total:</div>
                <div>{{item[0].orderTotal|currency}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="order-status">
        <h2>Order Status</h2>
        <div class="status-container">
          <div class="icons-container">
            <div style="display:flex">
              <i class="pi pi-warehouse"></i>
              <div style="margin-left:1em;">
                <div>Order Placed</div>
                <div>{{item[0].dateSubmitted | date}}</div>
              </div>
            </div>
            <i class="pi pi-check-circle" style="color:green"></i>
          </div>
          <div class="dotted-vertical-line" [style.border-left]="orderBackground(1,item[0].orderStatus)"></div>
          @if(item[0].orderStatus ==="CAN"){
          <div  class="icons-container">
            <div style="display:flex">
              <i class="pi pi-times-circle" style="color:red"></i>
              <div style="margin-left:1em;">
                <div style="color:red">Cancelled</div>
              </div>
            </div>
            <i class="pi pi-ban" style="color:red"></i>
          </div>
          }
          @else{
          <div class="icons-container">
            <div style="display:flex">
              <i class="pi pi-spinner-dotted"></i>
              <div style="margin-left:1em;">
                <div>In Progress</div>
              </div>
            </div>
  
            @if(item[0].orderStatus ==='PRO' || item[0].orderStatus ==='INV'){
            <i class="pi pi-check-circle" style="color:green"></i>
            }
            @else{
            <i class="pi pi-stopwatch"></i>
            }
          </div>
  
          <div class="dotted-vertical-line" [style.border-left]="orderBackground(2,item[0].orderStatus)"></div>
          <div class="icons-container">
            <div style="display:flex">
              <i class="pi pi-file-check"></i>
              <div style="margin-left:1em;">
                <div>Invoiced</div>
                <a *ngIf="item[0].trackingLink" href={{item[0].trackingLink}}>Track Me</a>
              </div>
            </div>
            @if(item[0].orderStatus ==='INV'){
            <i class="pi pi-check-circle" style="color:green"></i>
            }
            @else{
            <i class="pi pi-stopwatch"></i>
            }
          </div>
          }
        </div>
      </div>
    </div>
    <div class="user-container">
      <h3>Customer Details:</h3>
      <div><strong>Address:</strong> {{item[0].address1}} @if(item[0].address2){, {{item[0].address2}}} , {{item[0].city}} , {{item[0].state}} , {{item[0].zipCode}},{{item[0].country}}</div>
      <div><strong>Contact:</strong> {{item[0].phoneNumber}}</div>
      <div><strong>Mail:</strong> {{item[0].loginEmailAddress}}</div>
    </div>
  </div>
  