<!-- Loading spinner -->
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading...</p>
  </ngx-spinner>
<!-- Loading spinner -->
<div class="bg-container">
    <div class="homeful-gifts">
        <div class="heading">
            <div class="homeful-heading">Homeful Gifts</div>
            <div>
                <button class="button" (click)="toggleTable1()">Orders</button>
                <button class="button" (click)="toggleTable2()">Items</button>
            </div>
        </div>
        <div>
            <div class="table-container">
                <div>
                    <button *ngFor="let x of Headers" class="button1" [style.color]="getColor(x)"
                        [style.backgroundColor]="getBackground(x)" (click)="OnButtonClick1(x)">{{x}}</button>
                </div>
                <div>
                <button *ngIf="value1==='Pending' && selectedOption==='Reqnotsent'" (click)="moveOrders()"
                    class="button1">Move Orders</button>
</div>
            </div>
            <div class="table-cont" *ngFor="let x of Headers">
                @if(value1===x){
                @if(x === "Pending"){
                <div class="radio-group">
                    <label class="req-button">
                        <input type="radio" name="requestOption" value="Reqnotsent" [(ngModel)]="selectedOption"
                            (change)="onRadioChange($event)">
                        <span class="custom-radio"></span> For HomefulGifts
                    </label>
                    <label class="req-button">
                        <input type="radio" name="requestOption" value="Reqsent" [(ngModel)]="selectedOption"
                            (change)="onRadioChange($event)">
                        <span class="custom-radio"></span> For Suppliers
                    </label>
                </div>
                }
                <div class="table-responsive" [style.height]="getHeight()">
                    <table class="table1">
                        <thead>
                            <tr>
                                <th  style="white-space: nowrap;">Order Date</th>
                                <th style="white-space:nowrap;">Recipient Name</th>
                                <th>#Order</th>
                                <th style="white-space:nowrap;">Items Count</th>
                                <th>Order$</th>
                                <th>Discount</th>
                                <th>CouponCode</th>
                                <th>Freight$</th>
                                <th>Sales Tax</th>
                                <th>Net Order$</th>
                                <th>Shipping Address</th>
                                <th>BillAddress</th>
                                <th>Payment Type</th>
                                <th>Email</th>
                                <th>ContactNo</th>
                                <th style="white-space: nowrap;">Suppliers For This Order</th>
                                <th>#Sellers Responded</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody class="data">
                            <tr *ngFor="let x of TableData" (click)="navigateToItem(x.orderId,x.customerNumber)"
                                style="cursor: pointer;">
                                <td>{{x.purchaseDate}}</td>
                                <td style="white-space:nowrap;">{{x.recipientName}}</td>
                                <td  style="white-space:nowrap;">{{x.orderId}}</td>
                                <td>{{x.itemsCount}}</td>
                                <td>${{x.orderTotalAmount | number:'1.2-2'}}</td>
                                <td>${{x.orderDiscountAmount | number:'1.2-2' }}</td>
                                <td>{{x.orderCoupon}}</td>
                                <td>${{x.shippingPrice | number:'1.2-2'}}</td>
                                <td>${{x.shippingTax | number:'1.2-2'}}</td>
                                <td>${{x.orderNetAmount | number:'1.2-2'}}</td>
                                <td style="white-space: nowrap;">{{x.shippingAddress}}</td>
                                <td style="white-space: nowrap;">{{x.billAddress1}}@if(x.billAddress2){,{{x.billAddress2}}},{{x.billCity}},{{x.billState}},{{x.billPostalCode}},{{x.billCountry}}
                                </td>
                                <td>{{x.paymentType}}</td>
                                <td>{{x.buyerEmail}}</td>
                                <td>{{x.shipPhoneNumber}}</td>
                                <td>{{x.suppliersForOrder}}</td>
                                <td>{{x.noOfSuppliersResponded}}</td>
                                <td (click)="report(x.buyerEmail,x.customerNumber,x.recipientName,x.orderId)"><i class="pi pi-download"
                    style="font-size: 1rem"></i></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                }
            </div>
        </div>
    </div>
</div>