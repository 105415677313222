import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/shared/services/data.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-track-order-by-number',
  templateUrl: './track-order-by-number.component.html',
  styleUrls: ['./track-order-by-number.component.css']
})
export class TrackOrderByNumberComponent implements OnInit {
  orderSubmissionForm: FormGroup;
  orderTrackingReq: { "customerNumber": any; "orderNumber": number; "emailAddress": any; };
  orderDetails: any;

  constructor(private router: Router, private fb: FormBuilder, private _dataService: DataService, private spinner: NgxSpinnerService, public titleService : Title) {
    this.titleService.setTitle('Track Order | Homeful Gifts');
    this.orderSubmissionForm = fb.group({
      'orderNumber': [null, Validators.required],
      'email': [null, [Validators.required, Validators.pattern(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/)]]
    });
  }

  ngOnInit(): void {
  }

  trackByOrderNumber() {
    
    localStorage.setItem('guestTrackOrderDecision', "fromTrackByNumber");
    var value = window.btoa(this.orderSubmissionForm.value.orderNumber + "/" + this.orderSubmissionForm.value.email);
    this.router.navigate(['/account/track-orders', value]);
  }

  getOrderTrackingDetails() {

    this.spinner.show();
    this.orderTrackingReq = {
      "customerNumber": null,
      "orderNumber": +this.orderSubmissionForm.value.orderNumber,
      "emailAddress": this.orderSubmissionForm.value.email
    }
    
    this._dataService.post('Order/GetOrderTrackingDetails', JSON.stringify(this.orderTrackingReq))
      .subscribe((response) => {
        
        this.orderDetails = response;
        if (this.orderDetails.length > 0) {
          this.trackByOrderNumber();
        }
        else {
          this._dataService.alert("Please enter the valid order details", '','info');
        }
        this.spinner.hide();
        
      }, (error) => {
        if (error.status == 300) {
          this.orderDetails = [];
        }
        this.spinner.hide();
      });
  }
    // only accept numbers
    numberOnly(event: any) {
      const numberpattern = /[0-9\+\-\ ]/;
      let inputChar = String.fromCharCode(event.charCode);
      if (!numberpattern.test(inputChar)) {
        event.preventDefault();
      }
    }
}
