import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrl: './order-details.component.css'
})
export class OrderDetailsComponent {
  itemId: string = '';
  item: any;
  customerNum = '';
  cancel = false;
  cancelled: any;
  cancelSelected = true;
  notSelected = false;
  reason = '';
  constructor(private router: Router, private route: ActivatedRoute, private _dataService: DataService) { }
  ngOnInit() {
    this.route.params.subscribe(params => {
      this.itemId = params['id'];
      this.customerNum = params['customerNum'];
      this.reloadData();
    })
  }
  reloadData() {
    this._dataService.getOrderDetails(this.itemId, this.customerNum).subscribe((data) => {
      this.item = data;
      console.log(data);
      this.item.map((x: any) => {
        x.selected = false;
      })
    })

  }
  netOrder() {
    let i = 0;
    this.item.forEach((data: any) => {
      i = i + data.extendedPrice;
    })
    return i;
  }

  SelectAll() {
    if (this.item[0].orderStatus === 'CAN') {
      this.item[0].can = true;
    }
    return this.item.every((x: any) => x.selected)
  }
  toggleAll(event: any) {
    this.item.map((x: any) => {
      if (!x.cancelDate) {
        x.selected = event.target.checked
      }
    });
  }
  navigateToHome(): void {
    this.router.navigate(['/admin/admin-page']);
  }

  getBackgroundColor(value: any) {
    if (value) {
      return "black";
    }
    else {
      return
    }

  }
  getColor(value: any) {
    if (value) {
      return "white"
    }
    else {
      return
    }
  }
  cancelButton() {
    this.cancelSelected = this.item.some((value: any) => value.selected === true);
    if (this.cancelSelected) {
      this.cancel = true;
    }
  }
  checkBox(value: any) {
    console.log(value)

  }

  confirmCancel() {
    this.cancelled = this.item.filter((x: any) => x.selected === true)
    this.cancelled.map((value: any) => {
      this._dataService.cancelItem(value.customerNumber, value.orderNumber, value.itemNumber, this.reason).subscribe((res: any) => {
        // this.messageService.add({ severity: 'success', summary: 'Cancelled', detail: res.value, key: 'tm', life: 3000 })
        this._dataService.alert(res.value, "Cancelled","success");
        this.reloadData();
      })
    })
    this.reason = '';
    this.cancel = false;
  }
  notConfirm() {
    this.cancel = false;
  }
  orderBackground(i: number, value: any) {
    if (value === 'CAN') {
      return "3px solid red";
    }
    else if ((i === 1 && value === 'PRO') || (i === 1 && value === "INV") || (i === 2 && value === 'INV')) {
      return "3px solid green";
    }
    else {
      return "3px dotted black";
    }
  }
  errorHandlerForItemImages(event: any) {
    event.target.src = event.target.src.replace("hfg_images/ConsolidatedImages", "images");
  }


}
