<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
<div class="breadcrumb-area pt-10 pb-10 bg-gray-3">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12 nopadding">
                <div class="breadcrumb-content text-left">
                    <ul>
                        <li>
                            <a routerLink="/">Home</a>
                        </li>
                        <li>
                            <a href="javascript:void(0);" routerLink="/account/my-account">My Account</a>
                        </li>
                        <li class="active">Manage Addresses</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="shop-area pt-15 pb-100 section-padding-1 section-product">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-3 col-plgc-3 nopadding">
                <div class="sidebar-style mr-30">
                    <div class="sidebar-widget">
                        <h4 class="title-1">My Account</h4>
                        <div class="sidebar-widget-list sidebar-category mt-20">
                            <ul>
                                <li>
                                    <a routerLink="/account/my-account">Account Info</a>
                                </li>
                                <li>
                                    <a routerLink="/account/my-orders">Orders</a>
                                </li>
                                <li class="active">
                                    <a routerLink="/account/my-address">Manage Addresses</a>
                                </li>
                                <li>
                                    <a routerLink="/account/manage-payment">Manage Payments</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-9 col-plgc-9 nopadding">
                <div class="row" *ngIf="isAddAddress || isEditAddress">
                    <div class="col-lg-12">
                        <div class="billing-info-wrap my-address-block" style="margin-bottom:10px;">
                            <form [formGroup]="shippingAddressForm">
                                <h3 *ngIf="isAddAddress">Add Address</h3>
                                <h3 *ngIf="isEditAddress">Update Address</h3>
                                <div class="row">
                                    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                        <div class="billing-info mb-20">
                                            <label>Contact Name<sup>*</sup></label>
                                            <input type="text" class="form-control" formControlName="contact_Name"
                                                [ngModel]="addressList.contactName | titlecase"
                                                (ngModelChange)="addressList.contactName=$event" name="contact_Name"
                                                maxlength="50">
                                            <show-errors [control]="shippingAddressForm.controls.contact_Name">
                                            </show-errors>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                        <div class="billing-info mb-20">
                                            <label>Email Address<sup>*</sup></label>
                                            <input type="text" class="form-control" formControlName="email"
                                                [(ngModel)]="addressList.emailAddress" name="email" maxlength="250">
                                            <show-errors [control]="shippingAddressForm.controls.email">
                                            </show-errors>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                        <div class="billing-info mb-20">
                                            <label>Phone<sup>*</sup></label>
                                            <input type="text" class="form-control" formControlName="phone_Number"
                                                placeholder="Phone Number" [(ngModel)]="addressList.phone_Number"
                                                name="phone_Number" maxlength="16" autocomplete="doNotAutoComplete" appPhoneMask>
                                            <span style="color: red"
                                                *ngIf="!shippingAddressForm.get('phone_Number').valid && shippingAddressForm.get('phone_Number').touched">
                                                <span class="required--indication"
                                                    *ngIf="shippingAddressForm.get('phone_Number').hasError('minlength') || shippingAddressForm.get('phone_Number').hasError('required')">
                                                    Please enter a valid Phone Number
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                        <div class="billing-info mb-20">
                                            <label>Address 1<sup>*</sup></label>
                                            <input type="text" class="form-control" formControlName="address1"
                                                [ngModel]="addressList.address1 | titlecase"
                                                (ngModelChange)="addressList.address1=$event" name="address1" maxlength="100">
                                            <show-errors [control]="shippingAddressForm.controls.address1">
                                            </show-errors>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                        <div class="billing-info mb-20">
                                            <label>Address 2</label>
                                            <input type="text" class="form-control" formControlName="address2"
                                                [ngModel]="addressList.address2 | titlecase"
                                                (ngModelChange)="addressList.address2=$event" name="address2" maxlength="100">
                                            <show-errors [control]="shippingAddressForm.controls.address2">
                                            </show-errors>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                        <div class="billing-info mb-20">
                                            <label>Town / City<sup>*</sup></label>
                                            <input type="text" class="form-control" formControlName="city"
                                                [ngModel]="addressList.city | titlecase"
                                                (ngModelChange)="addressList.city=$event" name="city" maxlength="50" minlength="3">
                                            <show-errors [control]="shippingAddressForm.controls.city">
                                            </show-errors>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                        <div class="billing-select mb-20">
                                            <label>State<sup>*</sup></label>
                                            <select name="state" [(ngModel)]="addressList.state" formControlName="state"
                                                placeholder="State">
                                                <option *ngFor="let state of stateList" [ngValue]="state.state">{{state.description}}</option>
                                            </select>
                                            <show-errors [control]="shippingAddressForm.controls.state">
                                            </show-errors>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                        <div class="billing-select mb-20">
                                            <label>Country<sup>*</sup></label>
                                            <select name="country" [(ngModel)]="addressList.country"
                                                placeholder="Country" formControlName="country">
                                                <option *ngFor="let country of countries" [ngValue]="country">{{country}}</option>
                                            </select>
                                            <show-errors [control]="shippingAddressForm.controls.country">
                                            </show-errors>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                        <div class="billing-info mb-20">
                                            <label>ZIP<sup>*</sup></label>
                                            <input type="text" class="form-control" formControlName="zipcode"
                                                [(ngModel)]="addressList.zipcode" name="zipcode" maxlength="12"
                                                minlength="5" (keypress)="numberOnly($event)">
                                            <show-errors [control]="shippingAddressForm.controls.zipcode">
                                            </show-errors>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 make-default">
                                        <label class="">
                                            <input type="checkbox" name='email1' [(ngModel)]="isDefaultAddress"
                                                (click)="isDefaultAddress==0?1:0"
                                                [ngModelOptions]="{standalone: true}"><i class="fa fa-square-o"></i><i
                                                class="fa fa-check-square-o"></i>
                                            <span>&nbsp;Make this my default address</span>
                                        </label>
                                    </div>
                                </div>
                            </form>
                            <div class="checkout-account-toggle address-add-btn-2 mb-30">
                                <button class="btn-hover checkout-btn" type="submit" 
                                    (click)="addressValidation(true)" *ngIf="isAddAddress" [disabled]="!shippingAddressForm.valid">Add Address</button>
                                <button class="btn-hover checkout-btn" type="submit"
                                    (click)="addressValidation(true)" *ngIf="isEditAddress"
                                    [disabled]="!shippingAddressForm.valid">Update Address</button>&nbsp;&nbsp;&nbsp;
                                <button class="btn-hover checkout-btn cancel-btn" type="submit"
                                    (click)="cancelClick()">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-9 col-xl-8 pull-left m-no-pad" *ngIf="!(isAddAddress || isEditAddress)">
                    <div class="your-order-area payment-area address-info">
                        <div class="address-title overflow-hidden" style="padding-top: 20px;"
                            *ngIf="!isAddAddress && !isEditAddress">
                            <h3 class="title-1 pull-left">Address List</h3>
                            <div class="cust-btn-2 address-add-btn pull-right">
                                <a class=""
                                    (click)="onAddAddressClick()">Add
                                    Address</a>&nbsp;&nbsp;
                            </div>
                        </div>
                        <div class="payment-options">
                            <div class="cards-list">
                                <div style="padding-left: 50%" *ngIf="bilAddressInfolist?.length == 0">No Address found
                                </div>
                                <table class="table" *ngIf="bilAddressInfolist?.length > 0">
                                    <tbody>
                                        <tr *ngFor="let billAddress of bilAddressInfolist | slice: 0: 15">
                                            <td width="100%">
                                                <div class="address-name overflow-hidden">
                                                    <h4 class="pull-left">{{billAddress.contactName}}
                                                        <span *ngIf="billAddress.defaultAddress == 1"
                                                            class="recommended_txt">&nbsp;&nbsp;(Default
                                                            Address)</span> </h4>
                                                    <a href="javascript:void(0)" *ngIf="billAddress.defaultAddress != 1"
                                                        class="pull-right prd-icons trash"><i class="fa fa-trash"
                                                            (click)="onDeleteClick(billAddress)"></i></a>
                                                    <a href="javascript:void(0)" (click)="onEditClick(billAddress)"
                                                        class="pull-right prd-icons edit"><i class="fa fa-edit"></i></a>
                                                </div>

                                                <div class="addres-content">
                                                    <div class="add-icon">
                                                        <i class="fa fa-map-marker"></i>
                                                    </div>
                                                    <div class="add-text">
                                                        <p>{{billAddress.addressNumber}} {{billAddress.address1}}, {{billAddress.state}} {{billAddress.zipCode}}</p>
                                                    </div>
                                                </div>
                                                <div class="addres-content pull-left">
                                                    <div class="add-icon">
                                                        <i class="fa fa-phone"></i>
                                                    </div>
                                                    <div class="add-text">
                                                        <p>{{billAddress.phoneNumber | phone}}</p>
                                                    </div>
                                                </div>
                                                <div class="addres-content mrg-lft-15 pull-left">
                                                    <div class="add-icon">
                                                        <i class="fa fa-envelope"></i>
                                                    </div>
                                                    <div class="add-text">
                                                        <p>{{(billAddress?.emailAddress == null) || (billAddress?.emailAddress == '') ? 'No Email Provided' : billAddress?.emailAddress}}</p>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #AddressCorrectionPopup let-modal>
    <div class="custom-modal">
        <div class="modal-header">
            <h4 class="page-title">Address Correction<ng-content select="selector"></ng-content></h4>
            <a href="javascript:void(0)" class="close" (click)="closeDialog()" style="color: #fff !important;">
                <i class="fa fa-times" aria-hidden="true"></i>
            </a>
        </div>
        <div class="modal-body">
            <div class="row d-flex">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <div class="card-custom clearfix" [ngClass]="{'selected': selectedAddress === 1}" (click)="selectedAddress = 1">
                        <input type="radio" name="address" value="1" [(ngModel)]="selectedAddress" id="enteredAddress">
                        <label class="ml-5 mt-10 font-weight-bold" for="enteredAddress" (click)="selectedAddress = 1">Entered Address</label>
                        <p class="mt-10">{{finalAddressEntered}}</p>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <div class="card-custom clearfix" [ngClass]="{'selected': selectedAddress === 2}" (click)="selectedAddress = 2">
                        <input type="radio" name="address" value="2" [(ngModel)]="selectedAddress" id="recommendedAddress">
                        <label class="ml-5 mt-10 font-weight-bold" for="recommendedAddress" (click)="selectedAddress = 2">Recommended Address</label>
                        <p class="mt-10">{{finalAddressRecommandation}}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-12 text-center center-block cust-modal-footer mt-20">
                <button type="submit" class="btn bg-theme" id="Cancel" (click)="closeDialog()">Cancel</button>
                <button type="button" class="btn bg-theme ml-5" (click)="saveSelectedAddress()">Save</button>
            </div>
        </div>
    </div>
</ng-template>