import { Component } from '@angular/core';

@Component({
  selector: 'app-coming-soon',
  standalone: true,
  imports: [],
  templateUrl: './coming-soon.component.html',
  styleUrl: './coming-soon.component.css'
})
export class ComingSoonComponent {

  interval = setInterval(this.countdown, 1000);

  countdown() {
    const countToDate = new Date("2024-10-31T00:00:00").getTime(); // Set to Halloween
    const now = new Date().getTime();
    const gap = countToDate - now;

    const second = 1000;
    const minute = second * 60;
    const hour = minute * 60;
    const day = hour * 24;

    const textDay = Math.floor(gap / day);
    const textHour = Math.floor((gap % day) / hour);
    const textMinute = Math.floor((gap % hour) / minute);
    const textSecond = Math.floor((gap % minute) / second);

    document.getElementById('days').innerText = textDay.toString();
    document.getElementById('hours').innerText = textHour.toString();
    document.getElementById('minutes').innerText = textMinute.toString();
    document.getElementById('seconds').innerText = textSecond.toString();

    if (gap < 0) {
      // Stop the countdown
      clearInterval(this.interval);
      document.getElementById('countdown').innerHTML = "Launched!";
    }
  }

}
