import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MustMatch } from 'src/app/shared/validation-utilities/must-match';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataService } from 'src/app/shared/services/data.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ThemePalette } from '@angular/material/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.css']
})
export class MyAccountComponent implements OnInit {

  currentUser: any;
  customerInfo: any;
  personalDataForm: FormGroup;
  changePasswordForm: FormGroup;
  isChangePassword:boolean=false;
  color: ThemePalette = 'accent';
  form: FormGroup;
  constructor(private router: Router, private spinner: NgxSpinnerService, private _dataService: DataService, private _snackBar: MatSnackBar, public titleService : Title,
    private fb: FormBuilder) {
      this.form=fb.group({
        phone:['']
      })
    this.currentUser = JSON.parse(localStorage.getItem('currentHFGUser'));
    this.titleService.setTitle('My Account | Homeful Gifts');
    

    this.personalDataForm = fb.group({
      'first_name': [null, Validators.compose([Validators.required])],
      'last_name': [null],
      'email': [null, [Validators.required, Validators.pattern(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/)]],
      'phone_number': [null, [Validators.required, Validators.minLength(14)]],
    });
    this.changePasswordForm = fb.group({
      'oldPassword': [null, Validators.compose([Validators.required, Validators.minLength(6)])],
      'newPassword': [null, Validators.compose([Validators.required, Validators.minLength(6)])],
      'confirm_password': [null, Validators.compose([Validators.required])]
    },
      {
        validator: MustMatch('newPassword', 'confirm_password')
      });
  }

  ngOnInit(): void {

    this.spinner.show();
    let currentUser = localStorage.getItem('currentHFGUser');
    if (currentUser == null || currentUser == undefined) {
      this.router.navigate(['/login']);
    }
    // this.getUserInfo(this.currentUser.CustomerNumber);
    this.spinner.hide();
  }

  onEditCustomerDetails() {
    
    var req = {
      "indicator": 1,
      "customerNumber": this.currentUser.customerNumber,
      "firstName": this.currentUser.firstName,
      "lastName": this.currentUser.lastName,
      "email": this.currentUser.loginEmailAddress,
      "phoneNumber": this.currentUser.phoneNumber,
      "password": this.currentUser.password,
      "confirmPassword": this.currentUser.password,
      "salesDevice": "WEB",
      "guestCustomer": 0,
      "includeWelcomeCoupon": 0
    }
    
    this.spinner.show();
    this._dataService.post('app/InsertUpdateDeleteCustomerInformation', JSON.stringify(req)).subscribe((response) => {
      this.spinner.hide();
      
      this.getUserInfo();
      this._dataService.alert('Details Updated Successfully','','success');

      // this.router.navigate(['/login']);
    }, (error) => {
      this.spinner.hide();
      this._dataService.alert(error["error"].message,'','error');
    });
  }

  onChangePassword() {
    var req = {
      "customerNumber": this.currentUser.customerNumber,
      
      "oldPassword": this.changePasswordForm.value.oldPassword,
      "newPassword": this.changePasswordForm.value.newPassword
    }

    this.spinner.show();
    this._dataService.post('app/UpdateCustomerPassword', JSON.stringify(req)).subscribe((response) => {
      // this.registerForm.reset();
      if(response.statusCode ==200){
        this.getUserInfo();
        this._dataService.alert('Password Updated Successfully','','success');
      }
      else{
        this._dataService.alert('Updation Failed','','info');

      }
      this.spinner.hide();      
      // this.router.navigate(['/login']);
    }, (error) => {
      this.spinner.hide();
      this._dataService.alert(error["error"].message,'','error');
    });
  }

  getUserInfo() {
    var req = {
      customerNumber: this.currentUser.customerNumber,
    }
    this._dataService.post('app/GetHFCustomerGeneralInfo', req)
      .subscribe((response) => {

        this.customerInfo = response.data[0];
        localStorage.setItem('currentHFGUser', null);
        localStorage.setItem('currentHFGUser', JSON.stringify(this.customerInfo));
        window.location.reload();
      }, (error) => {
        this.spinner.hide();
        this._dataService.alert(error["error"].message,'','error');
      })
  }

}
