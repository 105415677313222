import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataService } from 'src/app/shared/services/data.service';
import * as XLSX from 'xlsx';


@Component({
  selector: 'app-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrl: './admin-page.component.css'
})
export class AdminPageComponent {
    date1: Date = new Date();
  date2: Date = new Date();
  toDate!: Date;
  fromDate!: Date;
  maxDate: Date = new Date();
  Headers = ["Pending", "InProgress", "Invoiced", "All", "Cancelled Orders"];
  value1: string = "Pending";
  TableData: any[] = [];
  selectedOption: string = "Reqnotsent";
    @ViewChild('dt2') dt2!: any;
  selectedGroupBy = 'Order#';

  constructor(private router: Router, private _dataService: DataService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
        this.date2.setDate(this.date1.getDate() - 15);
    this.maxDate = new Date();
    this.fromDate = this.date2;
    this.toDate = this.date1;
    this.updateData("Pending");
  }
  onDateSelect1(date: any) {
    this.fromDate = date;
    this.updateData(this.value1);
  }

  onDateSelect2(date: any) {
    this.toDate = date;
    this.updateData(this.value1);
  }

  updateData(data: any) {
    this._dataService.getTableData(this.selectedOption, data).subscribe((data: any) => {
              this.TableData = data
          .filter((value: any) => {
            const date = new Date(value.purchaseDate);
            const d = new Date(date.getFullYear(),date.getMonth(),date.getDate());
            value.purchaseDate = this.formatDate(date);
            value.shippingAddress = `${value.shipAddress1},${value.shipAddress2?value.shipAddress2 + ',':''}${value.shipAddress3?value.shipAddress3 + ',':''}${value.shipCity},${value.shipState},${value.shipPostalCode},${value.shipCountry}`;
            return d >= this.fromDate && d <= this.toDate;
          })
          .sort(
            (a: any, b: any) =>
              new Date(b.purchaseDate).getTime() -
              new Date(a.purchaseDate).getTime()
          );
          this.spinner.hide();
    })
  }
  private formatDate(date: Date): string {
    return date
      .toLocaleDateString('en-US', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(/\//g, '-');
  }

  onRadioChange(value: any) {
    this.spinner.show();
    this.selectedOption = "";
    this.selectedOption = value.target.value;
    this.updateData("Pending");
    this.selectedGroupBy = 'Order#';
  }
  report(email: any, customerId: any, customer: any, order: any) {
    event?.stopPropagation();
    this._dataService
      .downloadReport({
        emailaddress: email,
        customerNumber: customerId,
        customerName: customer,
        orderNumber: order,
      })
      .subscribe(
        (res: Blob) => {
          const url = window.URL.createObjectURL(res);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'report.pdf';
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        },
        (error) => {
          console.log('Download error', error);
        }
      );
  }

  navigateToItem(itemId: any, customerNum: any): void {
    this.router.navigate(['/admin/order-detail', itemId, customerNum]);
  }

  getColor(color: any) {
    if (color === this.value1) {
      return "#001";
    }
    else {
      return "";
    }
  }

  getBackground(color: any) {
    if (color === this.value1) {
      return "lightgrey";
    }
    else {
      return ""
    }
  }
  getHeight() {
    if (this.value1 === "Pending") {
      return '72vh';
    }
    else {
      return '80vh';
    }
  }

  toggleTable1() {
    this.spinner.show();
    this.router.navigate(['/admin/admin-page'])
    this.spinner.hide();
  }


  toggleTable2() {
    this.spinner.show();
    this.router.navigate(['/admin/item-detail'])
    this.spinner.hide();
  }

  moveOrders() {
    this.spinner.show();
    this._dataService.updateOrders().subscribe((data: any) => {
      this.updateData(this.value1);
      this.spinner.hide();
    },
      (error: any) => {
        this.spinner.hide();
        this._dataService.alert(error.error.message, "", "info")
      })
  }

  OnButtonClick1(value: any) {
    this.spinner.show();
    this.value1 = value;
    this.updateData(value);
  }

    searchInput(event: any) {
    const search = event.target.value;
    if (search) {
      this.dt2.filterGlobal(search, 'contains');
    }
  }
    groupHeader(value: any): string {
    if (this.selectedGroupBy === 'purchaseDate') {
      return `${value.purchaseDate}`;
    } else if (this.selectedGroupBy === 'shippingAddress') {
      return `${value.shipAddress1},${value.shipAddress2?value.shipAddress2 + ',':''}${value.shipAddress3?value.shipAddress3 + ',':''}${value.shipCity},${value.shipState},${value.shipPostalCode},${value.shipCountry}`;
    }
    return '';
  }
  RadioGroup(event: any) {
    this.selectedGroupBy = event;
    this.updateData('Pending');
  }
}
