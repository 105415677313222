import { Component, Inject, OnInit, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.css']
})
export class AlertDialogComponent implements OnInit, AfterViewInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<AlertDialogComponent>) { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const toast = document.querySelector('.toast');
      toast.classList.add('show');
      setTimeout(() => {
        this.closeDialog();
      }, this.data.time ?? 3000);
    }, 100); // Add a slight delay to ensure the toast is visible
  }

  closeDialog() {
    const toast = document.querySelector('.toast');
    // toast.classList.remove('show');
    setTimeout(() => {
      this.dialogRef.close();
    }, 500); // Allow time for the fade-out transition
  }
}
