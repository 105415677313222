<div class="custom-modal success">
    <div class="modal-body d-flex">
        <div class="close-button" (click)="closeDialog()">&times;</div>
        <div class="toast-icon">
            <i class="fa fa-check" aria-hidden="true"></i>
        </div>
        <div class="modal-content" style="border: 0px!important;">
            <p>{{data.message}}</p>
            <div class="d-flex justify-content-center mt-20 pull-right" style="font-size: 10px!important;">
                <button type="button" class="btn btn-primary" (click)="goToCart();closeDialog()">View Cart</button>
            </div>
        </div>
    </div>
</div>