<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
<div class="breadcrumb-area pt-10 pb-10 bg-gray-3">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12 nopadding">
                <div class="breadcrumb-content text-left">
                    <ul>
                        <li>
                            <a href="index.html">Home</a>
                        </li>

                        <li class="active">Returns</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="shop-area pt-15 pb-100 section-padding-1 section-product">
    <div class="container-fluid">
        <div class="row flex-row-reverse">
            <div class="col-lg-9 col-plgc-9" style="padding-right: 5%">
                <div class="row">
                    <h3 class="pb-10 title-2 mt-15 mb-20">Order Details</h3>
                    @for (orderDetail of claimList; track orderDetail ; let index =$index) {
                    <div class="order-area">
                        <div class="order-header">
                            <div class="order-h1">
                                <h3>Order Placed</h3>
                                <p>{{orderDetail.dateSubmitted | date}}</p>
                            </div>
                            <div class="order-h2">
                                <h3>Total</h3>
                                <p>{{orderDetail.extendedPrice | currency}}</p>
                            </div>
                            <div class="order-h2">
                                <h3>Quantity</h3>
                                <p> &nbsp;&nbsp;&nbsp; {{orderDetail.quantity}}</p>
                            </div>
                            <div class="order-h3 text-right">
                                <h3>Order# {{orderDetail.orderNumber}}</h3>

                            </div>
                        </div>
                        <div class="orders-block">
                            <div class="order-b1">
                                <div class="cart-thum">
                                    <div class="cart-thum-body">
                                        <a href="#"><img [src]="orderDetail.primaryImageURL"
                                                alt=""></a>
                                    </div>
                                </div>
                            </div>
                            <div class="order-b2">
                                <!-- <h2>{{orderDetail.itemNumber}}</h2> -->
                                <h3>{{orderDetail.description}}</h3>
                                <p>Seller: Homeful Gifts</p>
                                <div class="cust-btn-4 mt-10 mb-20">
                                    <a href="javascript:void(0);" (click)="addItemToCart(orderDetail)">Buy it again</a>
                                </div>
                            </div>
                            <div class="order-b3">
                                <h2>{{orderDetail.extendedPrice | currency}}</h2>
                            </div>
                            <div class="order-b4" *ngIf="false">
                                <h3><span class="return"><i class="fa fa-circle"></i></span>Returned</h3>
                                <p>Shipment is cancelled</p>
                                <div class="mt-20" *ngIf="orderDetail.itemClaimed==0">
                                    <a class="text-underline" href="">Return or Replace item</a>
                                </div>
                                <div class="mt-20" *ngIf="orderDetail.itemClaimed!=0">
                                    <a class="text-underline">Return Requested</a>
                                </div>
                            </div>
                            <div class="order-b4">
                                <h3><span class="deliver"><i class="fa fa-circle"></i>
                                        <!-- </span>Expected Delivery By {{orderDetail.customerShipDate | date}}</h3> -->
                                    </span>Expected Delivery By November 12, 2020</h3>
                                <!-- <p>Your item has been delivered</p> -->
                                <!-- <a href="#"><i class="fa fa-star"></i> Rate and Review Product</a> -->
                                <div class="mt-20" *ngIf="orderDetail.itemClaimed==0">
                                    <a class="text-underline" href="javascript:void(0);"
                                        (click)="onReturnClick(orderDetail)">Return or Replace item</a>
                                </div>
                                <div class="mt-20" *ngIf="orderDetail.itemClaimed!=0">
                                    <a class="text-underline" href="javascript:void(0);">Return Requested</a>
                                </div>
                                <div class="mt-10 mb-20">
                                    <a class="text-underline" href="javascript:void(0);" (click)="openSnackBar('Module Under Development','Ok')">Track
                                        package</a>
                                </div>
                            </div>
                            <div class="order-b4" *ngIf="false">
                                <h3><span class="deliver"><i class="fa fa-circle"></i></span>Delivered on Feb 24</h3>
                                <p>Your item has been delivered</p>
                                <!-- <a href="#"><i class="fa fa-star"></i> Rate and Review Product</a> -->
                                <div class="mt-20 text-center">
                                    <a href="">Return or Replace item</a>
                                </div>
                                <div class="mt-10 mb-20 text-center">
                                    <a href="">Track package</a>
                                </div>
                            </div>
                        </div>
                        <div class="return-info" *ngIf="false">
                            <h2>Replacement is completed <small>(Replacement Id: 123434534543)</small></h2>
                            <p>We have completed your request for replacement.</p>
                        </div>
                    </div>
                    <div class="order-area" style="padding-left: 50%" *ngIf="claimList.length == 0">No Orders found
                    </div>
                    }</div>
            </div>
            <div class="col-lg-3 col-plgc-3 nopadding">
                <div class="sidebar-style mr-30">

                    <div class="sidebar-widget">
                        <h4 class="title-1">My Account </h4>
                        <div class="sidebar-widget-list sidebar-category mt-20">
                            <ul>
                                <li>
                                    <a routerLink="/account/my-account">Account Info</a>
                                </li>
                                <li class="active">
                                    <a routerLink="/account/my-orders">Orders</a>
                                </li>
                                <li>
                                    <a routerLink="/account/my-address">Manage Addresses</a>
                                </li>
                                <li>
                                    <a routerLink="/account/manage-payment">Manage Payments</a>
                                </li>
                                <!-- <li>
                                    <a routerLink="/account/my-returns">My Returns</a>
                                </li> -->
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>