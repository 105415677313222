import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, isDevMode } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderNavigationComponent } from './shared/header-navigation/header-navigation.component';
import { FooterComponent } from './shared/footer/footer.component';
import { MatDialogModule } from "@angular/material/dialog";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DataService } from './shared/services/data.service';
import { ConfigService } from './shared/services/config.service';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AuthService } from './shared/services/auth.service';
import { AuthGuard } from './shared/services/auth.guard';
import { AuthInterceptor } from './shared/services/auth.interceptor';
import { DisplayItemsModule } from './display-items/display-items.module';
import { UtilityService } from './shared/services/utilityService';
import { ConfirmationDialogComponent } from './shared/confirmation-dialog/confirmation-dialog.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SharedModule } from './shared/shared.module';
import { LoginModule } from './login/login.module';
import { AccountModule } from './account/account.module';
import { SharedService } from './shared/services/shared.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AdminModule } from './admin/admin.module';
import { environment } from 'src/environments/environment';
import { UpdateService } from './shared/services/update.service';

@NgModule({ declarations: [
        AppComponent,
        HeaderNavigationComponent,
        FooterComponent,
        ConfirmationDialogComponent
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA], imports: [BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        MatDialogModule,
        MatSnackBarModule,
        MatTableModule,
        DisplayItemsModule,
        SharedModule,
        LoginModule,
        AccountModule,
        AdminModule,
        NgxSpinnerModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatMenuModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: true })
    ],
    providers: [
        ConfigService,
        HeaderNavigationComponent,
        DataService,
        AuthService,
        UtilityService,
        AuthGuard,
        SharedService,
        DatePipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        provideHttpClient(withInterceptorsFromDi()),
        UpdateService
    ] })
export class AppModule { }
