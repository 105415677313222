<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
	<p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
<div class="breadcrumb-area pt-10 pb-10 bg-gray-3">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12 nopadding">
                <div class="breadcrumb-content text-left">
                    <ul>
                        <li>
                            <a href="index.html">Home</a>
                        </li>

                        <li class="active">Returns</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="shop-area pt-15 pb-100 section-padding-1 section-product">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-3 col-plgc-3 nopadding">
                <div class="sidebar-style mr-30">

                    <div class="sidebar-widget">
                        <h4 class="title-1">My Account </h4>
                        <div class="sidebar-widget-list sidebar-category mt-20">
                            <ul>
                                <li>
                                    <a routerLink="/account/my-account">Account Info</a>
                                </li>
                                <li class="active">
                                    <a routerLink="/account/my-orders">Orders</a>
                                </li>
                                <li>
                                    <a routerLink="/account/my-address">Manage Addresses</a>
                                </li>
                                <li>
                                    <a routerLink="/account/manage-payment">Manage Payments</a>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-lg-9 col-plgc-9 nopadding">
                <!-- <a href="" id="full" style="font-size:14px;text-decoration:underline">Items curated for you</a> -->
                <div class="billing-info-wrap mt-15" style="display: block;">
                    <!-- <h3>Billing Details <span class="pull-right bill-close  cust-icon"><i class="fa fa-close"></i></span></h3> -->
                    <div class="row" id="refund">
                        <div class="col-lg-12 returns-b">
                            <h2 class="returns-head mb-20">{{returnItemDetails.description}}</h2>
                            <ul class="returns-ship mb-20">
                                <div class="row">
                                    <div class="col-lg-4 col-md-4">
                                        <div class="price">
                                            <div>
                                                Price:&nbsp;&nbsp;
                                            </div>
                                            <div>
                                                <li>{{returnItemDetails?.extendedPrice|currency}}</li>
                                            </div>
                                        </div>
                                        <br>
                                        <div class="qty">
                                            <div>
                                                Quantity: {{returnItemDetails.quantity}}&nbsp;&nbsp;
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-center col-lg-8 col-md-8">
                                        <div class="wrap-custom-file" style="margin: 0px !important;">
                                            <div *ngIf="returnItemList.length == 0">
                                                <input type="file" name="image{{i}}" id="image{{i}}"
                                                    accept=".gif, .jpg, .png" (change)="onFileUpload($event, i)"
                                                    style="display: none;" multiple />
                                                <label for="image{{i}}" style="min-height: 118px;">
                                                    <span style="cursor: pointer">Upload Images</span><br><br>
                                                    <i class="fa fa-picture-o fa-3x" style="cursor: pointer"
                                                        aria-hidden="true"></i>
                                                </label>
                                            </div>
                                            <div *ngIf="returnItemList.length > 0">
                                                <!-- <h4>Uploaded Images</h4> -->
                                                <div class="images-container">
                                                    <div *ngFor="let bannerDetails of returnItemList; let i = index">
                                                        <div class="banner-block"
                                                            *ngIf="bannerDetails?.tempFile != null">
                                                            <div class="returns-img">
                                                                <img [src]="bannerDetails?.tempFile">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </ul>
                        </div>
                        <div class="col-lg-12 payment-area">
                            <h3 class="title-1">Refund</h3>
                        </div>
                        <div class="col-lg-3 col-md-3 returns-b">
                            <!-- <h3 class="title-1">Choose item to return</h3> -->
                            <div class="returns">
                                <div class="returns-img">
                                    <img [src]="returnItemDetails.primaryImageURL"
                                        (error)="errorHandlerForItemImages($event)">
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-9 col-md-9 returns-b">
                            <div class="row">
                                <div class="col-lg-7" style="padding-left:40px;">
                                    <div class="billing-select">
                                        <label>Why are you returning this?</label>
                                        <select (change)="onSelectionReason(reasonValue,$event)"
                                            [(ngModel)]="reasonValue">
                                            @for(reason of reasonListParams; track reason){

                                            <!-- <option value="choose">Choose a response</option> -->
                                            <option [ngValue]="reason">
                                                {{reason.claimSubDescription}}</option>
                                            <!-- <option>Damaged due to poor packaging</option>
                                            <option>Wrong item was sent</option>
                                            <option>Item defective</option> -->
                                            }
                                        </select>
                                    </div>
                                    <div class="form-group mt-30">
                                        <label for="exampleFormControlTextarea1">Comments (Required)</label>
                                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="2"
                                            style="resize:none;" [(ngModel)]="claimDetails.comment"
                                            maxlength="1000"></textarea>
                                        <span>{{1000-claimDetails.comment?.length}} characters remaining.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-9 col-md-9 returns-b">
                            <p><strong>Note:</strong> Do not include personal information as these comments may be
                                shared with external service
                                providers to identify product defects.</p>
                        </div>
                        <div class="col-lg-6 payment-area">
                            <div class="returns-summar">
                                <div class="returns-summar-inner">
                                    <div class="blocks-area">
                                        <div class="block-11">
                                            <div class="ret-sum-area">
                                                <div class="ret-sum-inner">
                                                    <img [src]="returnItemDetails.primaryImageURL"
                                                        (error)="errorHandlerForItemImages($event)">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="black-222">
                                            <div class="ret-sum-head pdg-lft">
                                                <h3>{{returnItemDetails?.description}}</h3>
                                                <h4>{{claimDetailsForItem?.listPrice *
                                                    returnItemDetails?.claimQty|currency}}</h4>
                                                <!-- <h4>{{returnItemDetails.listPrice|currency}}</h4> -->
                                                <p>Returns Reason: {{claimDetails?.claimSubDescription}}</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 payment-area">
                            <div class="billing-info-wrap">
                                <div class="payment-options">
                                    <div class="cards-list">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td colspan="4">
                                                        <p style="margin-bottom:4px;"><strong>Card for Refund</strong>
                                                        </p>
                                                    </td>
                                                </tr>
                                                @for (cardDetails of creditCardSelected; track creditCardSelected) {
                                                <tr>
                                                    <td width="50">
                                                        <label>
                                                            <input type="radio" class="option-input radio"
                                                                name="example" [checked]="cardDetails?.isChecked" />
                                                        </label>
                                                    </td>
                                                    <td width="300">
                                                        <h4 style="margin-top:5px">{{cardDetails.creditCard_}}</h4>
                                                    </td>
                                                    <td><img src="assets/images/paypal_2.png" /></td>
                                                    <td class="text-center" width="100" *ngIf="false">
                                                        <p style="color: #f00;margin-bottom: 0px;line-height:5px;">
                                                            Expired</p>
                                                        <a href=""
                                                            style="font-size:10px;text-decoration:underline;">Update
                                                            Card <i class="fa fa-angle-right"></i></a>
                                                    </td>
                                                </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- returns summary -->
                    <div class="row" id="retunrs-summary">
                        <div class="col-lg-6">
                            <div class="returns-summar">
                                <div class="returns-summar-inner">

                                    <h3 *ngIf="returnItemDetails.quantity==1" class="title-1"
                                        style="text-transform: initial;">You are returning
                                        {{returnItemDetails.quantity}} Item in
                                        {{maxQty}}
                                        Package</h3>
                                    <h3 *ngIf="returnItemDetails.quantity>1" class="title-1"
                                        style="text-transform: initial;">You are returning
                                        {{returnItemDetails.quantity}} Items in
                                        {{maxQty}}
                                        Package</h3>
                                    <div class="blocks-area">
                                        <div class="block-11">
                                            <div class="ret-sum-area">
                                                <div class="ret-sum-inner">
                                                    <img [src]="returnItemDetails.primaryImageURL"
                                                        (error)="errorHandlerForItemImages($event)">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="block-222">
                                            <div class="ret-sum-head pdg-lft-15">
                                                <h3>{{returnItemDetails.description}}</h3>
                                                <h4>{{claimDetailsForItem?.listPrice *
                                                    returnItemDetails.claimQty|currency}}</h4>
                                                <p>Returns Reason: {{claimDetails.claimSubDescription}}</p>
                                            </div>
                                        </div>
                                        <div class="block-33">
                                                <div class="ret-sum-amt">
                                                    <h3> {{returnItemDetails?.extendedPrice | currency}}</h3>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="refund-summary">
                                <div class="refund-summary-inn">
                                    <div class="block-44">
                                        <h4 class="refund-sum-tot">Your Refund</h4>
                                    </div>
                                    <div class="block-55 text-center">
                                        <!-- <h4 class="refund-sum-tot">{{returnItemDetails.listPrice|currency}}</h4> -->
                                        <h4 class="refund-sum-tot">{{claimDetailsForItem?.listPrice *
                                            returnItemDetails.claimQty|currency}}</h4>

                                    </div>
                                    <p class="refund-p"><i class="fa fa-info-circle"></i> Your refund will be issued in
                                        3-5
                                        business days after we received the product.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- returns summary -->
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="checkout-account-toggle address-add-btn-2 mt-30 mb-30 text-center">
                                <button class="btn-hover checkout-btn continue-summary" type="submit"
                                    routerLink="/">Continue
                                    shopping</button>
                                <button class="btn-hover checkout-btn continue-next" type="submit"
                                    (click)="addClaim()">Continue</button>&nbsp;
                                <button class="btn-hover checkout-btn continue-next" type="submit"
                                    [routerLink]="['/account/my-orders']">Cancel</button>
                                <button class="btn-hover checkout-btn continue" type="submit"
                                    [disabled]="claimDetails.comment.length<1 || this.maxQty < this.returnItemDetails.claimQty || reasonValue?.id == 0">Continue</button>&nbsp;
                                <button class="btn-hover checkout-btn continue" type="submit"
                                    [routerLink]="['/account/my-orders']">Cancel</button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>