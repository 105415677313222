<!-- <div class="loader"></div> -->
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
	<p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
<div class="breadcrumb-area pt-10 pb-10 bg-gray-3">
	<div class="container-fluid">
		<div class="row">
			<div class="col-lg-12 nopadding">
				<div class="breadcrumb-content text-left">
					<ul>
						<li>
							<a routerLink="/">Home</a>
						</li>
						<li *ngIf="currentUser.guestCustomer != 1">
							<a href="javascript:void(0);" routerLink="/account/my-account">My Account</a>
						</li>
						<li class="active" *ngIf="currentUser.guestCustomer != 1">Manage Orders </li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="shop-area pt-15 pb-100 section-padding-1 section-product">
	<div class="container-fluid">
		<div class="row">
			<div class="col-lg-3 col-plgc-3 nopadding">
				<div class="sidebar-style mr-30" *ngIf="currentUser.guestCustomer != 1">

					<div class="sidebar-widget">
						<h4 class="title-1">My Account </h4>
						<div class="sidebar-widget-list sidebar-category mt-20">
							<ul>
								<li>
									<a routerLink="/account/my-account">Account Info</a>
								</li>
								<li class="active">
									<a routerLink="/account/my-orders">Orders</a>
								</li>
								<li>
									<a routerLink="/account/my-address">Manage Addresses</a>
								</li>
								<li>
									<a routerLink="/account/manage-payment">Manage Payments</a>
								</li>
								<!-- <li>
									<a routerLink="/account/my-returns">My Returns</a>
								</li> -->
							</ul>
						</div>
					</div>

				</div>
			</div>
			<div class="col-lg-9 col-plgc-9" style="padding-right: 5%">
				<div class="row">
					<h3 class="pb-10 title-2 mt-15 mb-20">Order Details</h3>
					<div class="order-area" style="box-shadow: none;">
						<div class="order-header" style="padding-left: 40%" *ngIf="allOrderList.length == 0">
							<h3>No Orders Found</h3>
						</div>
					@for (orderDetail of allOrderList; track orderDetail;let index=$index) {
					<div class="order-area">
						<div class="order-header">
							<div class="order-h1">
								<h3>Order Placed</h3>
								<p>{{orderDetail.dateSubmitted | date}}</p>
							</div>
							<div class="order-h2" style="width: 10% !important;">
								<h3>Item Count</h3>
								<p>{{orderDetail?.items?.length}}</p>
							</div>
							<div class="order-h2">
								<h3>Order Total</h3>
								<p>{{orderDetail.orderTotalAmount | currency}}</p>
							</div>
							<div class="order-h2">
								<h3>Shipping Fee</h3>
								<p *ngIf="orderDetail.freightAmount == 0">FREE</p>
								<p *ngIf="orderDetail.freightAmount > 0">{{orderDetail.freightAmount | currency}}</p>
							</div>
							<div class="order-h2">
								<h3>Sales Tax</h3>
								<p>{{orderDetail.salesTaxAmount | currency}}</p>
							</div>
							<div class="order-h2">
								<h3>Discount</h3>
								<p>{{orderDetail.discountAmount | currency}}</p>
							</div>
							<div class="order-h2">
								<h3>Total</h3>
								<p>{{orderDetail.totalOrderAmount | currency}}</p>
							</div>
							<div class="order-h2" style="width: 22% !important;">
								<h3>Order#<a (click)="generateOrderDetailReport(orderDetail)" title="Download Order Report"><i class="fa fa-download fa-lg pull-right" aria-hidden="true"></i></a>
								</h3>
								<p>{{orderDetail.orderNumber}}</p>
							</div>
						</div>
						@for (item of orderDetail.items; track item;let index=$index) {
						<div class="orders-block">
							<div class="order-b1">
								<h3 *ngIf="item.itemClaimed==0" [ngClass]="{ 'red-font': item.cancelQty != 0 }"><span
										[ngClass]="[item.cancelQty != 0 ? 'red-font' : 'deliver' ]"><i class="fa fa-circle"></i></span>
									{{item.itemStatus}}
								</h3>
								<h3 *ngIf="item.itemClaimed!=0"><span class="return"><i class="fa fa-circle"></i>
									</span>Return Request has been Submitted</h3>
						
								<div class="cart-thum">
									<div class="cart-thum-body">
										<a (click)="onItemClick(item)">
											<img [src]="item.primaryImageURL" alt="" (error)="errorHandlerForItemImages($event)">
										</a>
									</div>
								</div>
							</div>
							<div class="order-b2">
								<h3>{{item.description}}</h3>
								<p>Seller:{{item.supplierName}}</p>
								<p>Quantity:{{item.quantity}}</p>
							</div>
							<div class="order-b3">
								<h2>{{item.sellingPrice | currency}}</h2>
							</div>
							<div class="order-b4" *ngIf="false">
								<h3><span class="return"><i class="fa fa-circle"></i></span>Returned</h3>
								<p style="color: red;">Shipment is cancelled</p>
								<div class="mt-20" *ngIf="item.pendingClaimQty>0">
									<a class="text-underline">Return Requested</a>
								</div>
								<div class="mt-1 mb-1" *ngIf="item.trackingLink != null && item.trackingLink != ''">
									<a class="text-underline" (click)="onTrackOrderClick(item.trackingLink)" href="javascript:void(0);">Track
										package</a>
								</div>
							</div>
							<div class="order-b4">
								<div class="btn btn-outline-secondary mt-1 mb-1 mr-1">
									<a (click)="onItemClick(item)" href="javascript:void(0);">Buy it again</a>
								</div>
								<div class="btn btn-outline-secondary mt-1 mb-1" *ngIf="item.pendingClaimQty>0 && item.invoiceQty>0">
									<a href="javascript:void(0);" (click)="onReturnClick(item)">Return item</a>
								</div>
								<div class="btn btn-outline-secondary mt-1 mb-1" *ngIf="item.pendingClaimQty==0">
									<a href="javascript:void(0);" *ngIf="item.cancelQty!=1">Returned</a>
									<a href="javascript:void(0);" *ngIf="item.cancelQty==1" style="color: red;">Shipment is cancelled</a>
								</div>
								<div class="btn btn-outline-secondary mt-1 mb-1" *ngIf="item.trackingLink != null && item.trackingLink != ''">
									<a href="javascript:void(0);" (click)="onTrackOrderClick(item.trackingLink)"
										href="javascript:void(0);">Track
										package</a>
								</div>
								<div class="btn btn-outline-secondary mt-1 mb-1" *ngIf="item.invoiceQty>0">
									<a (click)="onClicktoReview(item)" href="javascript:void(0);">Write a Product
										Review</a>
								</div>
								<div class="btn btn-outline-secondary mt-1 mb-1" *ngIf="item.pendingClaimQty>0 && (item.trackingLink == '')">
									<a href="javascript:void(0);" (click)="cancelItemConfirmation(item)">Cancel</a>
								</div>
								<p *ngIf="item.couponCode.length>0"><b>Applied Coupon:</b>
									{{item.couponCode}}</p>
							</div>
						</div>
						}
						<div class="return-info" *ngIf="false">
							<h2>Replacement is completed <small>(Replacement Id: 123434534543)</small></h2>
							<p>We have completed your request for replacement.</p>
						</div>
					</div>
					}
				</div>
			</div>

		</div>
	</div>
</div>
<ng-template #itemReviewPopUp let-modal="modal">
	<div class="custom-modal">
		<div class="modal-header">
			<h4 class="page-title">Cancel Order</h4>
			<a href="javascript:void(0)" class="close" (click)="closeModal()" style="color: #fff !important;">
				<i class="fa fa-times" aria-hidden="true"></i>
			</a>
		</div>
		<div class="modal-body">
			<div class="row">
					<div class="col-md-12 col-sm-12 col-xs-12">
						<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dialogRef.close()"><span
								aria-hidden="true">x</span></button>
						<div class="rating-img-b">
							<div class="rating-img">
								<div class="rating-thum">
									<div class="rating-thum-body">
										<a href="#"><img [src]="selectedOrder?.primaryImageURL" alt="" (error)="errorHandlerForItemImages($event)"></a>
									</div>
								</div>
							</div>
							<div class="rating-header">
								<h3>{{selectedOrder?.description}}</h3>
								<p>Seller: {{selectedOrder?.supplierName}}</p>
							</div>
						</div>
						<div class="clearfix"></div>
						<h2 class="title-2">Rate this Product</h2>
						<div class="product-rating mb-20">
							<div class="rate">
								<input type="radio" id="star5" name="rate" value="5" (click)="onClickStar(5)" />
								<label for="star5" title="text">5 stars</label>
								<input type="radio" id="star4" name="rate" value="4" (click)="onClickStar(4)" />
								<label for="star4" title="text">4 stars</label>
								<input type="radio" id="star3" name="rate" value="3" (click)="onClickStar(3)" />
								<label for="star3" title="text">3 stars</label>
								<input type="radio" id="star2" name="rate" value="2" (click)="onClickStar(2)" />
								<label for="star2" title="text">2 stars</label>
								<input type="radio" id="star1" name="rate" value="1" (click)="onClickStar(1)" />
								<label for="star1" title="text">1 star</label>
							</div>
						</div>
						<div class="clearfix"></div>
						<h2 class="title-2">Review this Product</h2>
						<form>
							<div class="row">

								<div class="col-xl-12">
									<div class="form-group">
										<label for="usr">Title (Optional):</label>
										<input type="text" class="form-control" [(ngModel)]="reviewDetails.title"
											[ngModelOptions]="{standalone: true}">
									</div>
								</div>
								<div class="col-xl-12">
									<div class="form-group">
										<label for="usr">Description (Optional):</label>
										<textarea class="form-control" id="exampleFormControlTextarea1" rows="2"
											style="resize:none;" [(ngModel)]="reviewDetails.description"
											[ngModelOptions]="{standalone: true}" maxlength="2000"></textarea>
										<span>{{2000 - reviewDetails.description?.length}} characters
											remaining.</span>
									</div>
								</div>
								<!-- <div class="col-xl-12">
                                    <div class="make-default">
                                      <label class="btn active">
                                        <input type="checkbox" name='email1' checked><i class="fa fa-square-o"></i><i class="fa fa-check-square-o"></i> <span>&nbsp;I want receive email marketing & offers</span>
                                      </label>
                                    </div>
                                </div> -->
								<div class="col-xl-12">
									<button type="button" class="btn bg-theme ml-2 pull-right" (click)="dialogRef.close()">Cancel</button>
									<button type="button"
										[ngClass]="[(reviewDetails.title?.length> 0 && reviewDetails.description?.length== 0) || reviewDetails.rating == 0?'disabled-button btn bg-theme pull-right':'btn bg-theme pull-right']"
										(click)="onItemReviewSubmit();dialogRef.close()">Submit</button>
								</div>
							</div>
						</form>
					</div>
				</div>
		</div>
	</div>
</ng-template>
<ng-template #CancelReasonPopup let-modal="modal">
	<div class="custom-modal">
		<div class="modal-header">
			<h4 class="page-title">Cancel Order</h4>
			<a href="javascript:void(0)" class="close" (click)="closeModal()" style="color: #fff !important;">
				<i class="fa fa-times" aria-hidden="true"></i>
			</a>
		</div>
		<div class="modal-body">
			<div class="row">
				<div class="col-12">
					<p>Please provide the reason for cancelling this Item from the Order. Your feedback is important to
						us and will help us improve our services.</p>
				</div>
				<div class="col-12">
					<div class="form-group">
						<label for="cancelReason" class="font-weight-bold">Reason for Cancellation</label>
						<textarea id="cancelReason" class="form-control mt-2" [(ngModel)]="reasonValue" rows="4"
							placeholder="Enter your reason here..."></textarea>
					</div>
				</div>
			</div>
			<div class="col-12 text-center cust-modal-footer mt-4">
				<button type="button" class="btn bg-theme" (click)="closeModal()">Cancel</button>
				<button type="button" class="btn bg-theme ml-2" (click)="cancelItemFromOrder()">Submit</button>
			</div>
		</div>
	</div>
</ng-template>