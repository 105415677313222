import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminRoutingModule } from './admin-routing.module';
import { AdminPageComponent } from './admin-page/admin-page.component';
import { ItemDetailsComponent } from './item-details/item-details.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { OrderDetailsComponent } from './order-details/order-details.component';


@NgModule({
  declarations: [
    AdminPageComponent,
    ItemDetailsComponent,
    OrderDetailsComponent
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    NgxSpinnerModule,
    FormsModule,
    ScrollingModule
  ],
})
export class AdminModule { }
