<!-- Loading spinner -->
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
<!-- Loading spinner -->
<div class="bg-container">
  <div class="homeful-gifts">
      <div class="heading">
          <div class="homeful-heading">Homeful Gifts</div>
          <div>
              <button class="button" (click)="toggleTable1()">Orders</button>
              <button class="button" (click)="toggleTable2()">Items</button>
          </div>
      </div>
      <div>
        <div class="items-table">
          <input type="file" id="fileInput" accept=".xlsx,.xls" (change)="uploadFile($event)" style="display: none;">
          <label for="fileInput" class="custom-file-upload">
            Choose File
          </label>
          <span id="fileName">No file chosen</span>
          <button (click)="ActiveButton()" [ngClass]="{'active':isActive}" class="more-button" style="margin-left: 40px;">
            <div class="circle" [ngClass]="{'active':isActive}"></div>
              </button>
              <div class="table-responsive2">
                      <table class="table1">
                      <thead>
                          <tr>
                              <th>Item Image</th>
                              <th>SupplierName</th>
                              <th>ItemNumber</th>
                              <th>Description</th>
                              <th>ItemStatus</th>
                              <th>ListPrice</th>
                              <th>CurrentAvailableQty</th>
                              <th>ItemDimensions</th>
                              <th>ItemMaterial</th>
                              <th>VendorSKU</th>
                              <th>NextAvailableDate</th>
                              <th>ItemType</th>
                              <th>HomefulCategories</th>
                              @if(isActive){
                              <th>FullDescription</th>
                              <th>DetailedDescription</th>
                              <th>UPCNumber</th>
                              <th>PrimarySupplier</th>
                              <th>ItemKeyWords</th>
                              <th>GiftpackEligible</th>
                              }
                          </tr>
                      </thead>
                      <tbody class="data">
                          <tr *ngFor="let x of TableData">
                              <td>
                                  <div class="image-container">
                                      <img src={{x.primaryImageUrl}} class="hover-image">
                                      <div class="hover-block">
                                          <img src={{x.primaryImageUrl}}>
                                      </div>
                                  </div>
                              </td>
                              <td>{{x.supplierName}}</td>
                              <td>{{x.itemNumber}}</td>
                              <td>{{x.description}}</td>
                              <td>{{x.itemStatus}}</td>
                              <td>{{x.listPrice|currency}}</td>
                              <td>{{x.currentAvailableQty}}</td>
                              <td>{{x.itemDimensions}}</td>
                              <td>{{x.itemMaterial}}</td>
                              <td>{{x.vendorSKU}}</td>
                              <td>{{x.nextAvailableDate|date:'dd-MM-yyyy'}}</td>
                              <td>{{x.itemType}}</td>
                              <td>{{x.homefulCategory}}</td>
                              @if(isActive){
                              <td>{{x.fullDescription}}</td>
                              <td>{{x.detailedDescription}}</td>
                              <td>{{x.upcNumber}}</td>
                              <td>{{x.primarySupplier}}</td>
                              <td>{{x.itemKeyWords}}</td>
                              <td>{{x.giftpackEligible}}</td>
                              }
                          </tr>
                      </tbody>
                  </table>
              </div>
          </div>
      </div>
  </div>
</div>