import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-confirm-cart-nav',
  templateUrl: './confirm-cart-nav.component.html',
  styleUrl: './confirm-cart-nav.component.css'
})
export class ConfirmCartNavComponent implements OnInit, AfterViewInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<ConfirmCartNavComponent>, private router: Router) { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const toast = document.querySelector('.toast');
      if (toast != null) {
        toast.classList.add('show');
      }
      setTimeout(() => {
        this.closeDialog();
      }, this.data.time ?? 15000);
    }, 1000);
  }

  closeDialog() {
    this.dialogRef.close();
  }
  goToCart() {
    this.router.navigate(['/cart']);

  }
}
