<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
<div class="breadcrumb-area pt-15 pb-15 bg-gray-3">
    <div class="container-fluid">
        <div class="breadcrumb-content text-left">
            <ul>
                <li>
                    <a routerLink="/">Home</a>
                </li>
                <li class="active">Forgot Password </li>
            </ul>
        </div>
    </div>
</div>
<div class="forgotpassword pt-50 pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 ml-auto mr-auto">
                <div class="forgot-block">
                    <h2 class="title-1 mb-30">Forgot Password</h2>

                    <p>Please enter your email address associated with your
                        homefulgifts.com customer account.
                        <!-- Your account number begins with A and can be found on
                        your invoice, packing slip or any other correspondance from Homeful Gifts.  -->
                        Your account will be verified and your password will be emailed to you right away.
                    </p>
                    <p>If you have any queries , please call (800) 323 7799, M - F, 9am - 5pm ET, and one of our
                        customer service representatives will assist you.</p>
                    <div class="forgot-text-block">
                        <div class="forgot-txt">
                            <form [formGroup]="personalDataForm">
                                <div class="billing-info mb-20">
                                    <input type="text" [(ngModel)]="emailAddress" formControlName="email"
                                        style="opacity: 0.7" placeholder="Email Address">
                                    <show-errors [control]="personalDataForm.controls.email"></show-errors>
                                </div>
                            </form>
                            <div class="cust-btn">
                                <a type="submit" (click)="submitEmail()"
                                    [ngClass]="[!personalDataForm.valid?'btn-disabled cust-btn':'cust-btn']">Email
                                    My Password
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>