<div class="toast"
    [ngClass]="{'success': data.type === 'success', 'error': data.type === 'error', 'alert': data.type === 'alert', 'info': data.type === 'info'}">
    <div class="toast-icon">
        <i class="fa" [ngClass]="{
            'fa-check': data.type === 'success', 
            'fa-times': data.type === 'error', 
            'fa-exclamation-triangle': data.type === 'alert',
            'fa-info-circle': data.type === 'info'
        }" aria-hidden="true"></i>
    </div>
    <div class="toast-content">
        <h4 class="toast-title">{{ data.title }}</h4>
        <p>{{ data.message }}</p>
    </div>
    <button class="toast-close" (click)="closeDialog()">&times;</button>
</div>