<div class="login-register-area pt-20 pb-30">
    <div class="container">
        <div class="row">
            <div class="col-xl-5 col-lg-8 col-md-10 ml-auto mr-auto">
                <div class="login-logo text-center mb-30">
                    <img src="assets/img/logo/hg-logo.png" class="img-responsive">
                </div>
                <div class="login-register-wrapper">
                    <div class="login-form-container" id="login">
                        <div class="login-register-form">
                            <h3 class="pb-10 title-2 mb-20">Login</h3>
                            <form [formGroup]="loginForm" (ngSubmit)="logIn()">
                                <div class="mb-20">
                                    <input formControlName="userName" type="text" name="user-name" placeholder="Email ID">
                                    <div *ngIf="loginForm.controls['userName'].invalid && (loginForm.controls['userName'].dirty || loginForm.controls['userName'].touched)"
                                        class="error">
                                        <small *ngIf="loginForm.controls['userName'].errors?.['required']">Email is required.</small>
                                        <small *ngIf="loginForm.controls['userName'].errors?.['pattern']">Invalid email format.</small>
                                    </div>
                                </div>
                                <input formControlName="password" id="password-field" type="password" class="form-control" name="password" placeholder="Password">
                                <div *ngIf="loginForm.controls['password'].invalid && (loginForm.controls['password'].dirty || loginForm.controls['password'].touched)" class="error">
                                    <small *ngIf="loginForm.controls['password'].errors?.['required']">Password is required.</small>
                                </div>
                                <span toggle="#password-field" class="fa fa-fw fa-eye field-icon toggle-password"></span>

                                <div class="button-box">
                                    <div class="login-toggle-btn make-default">
                                        <a routerLink="/login/forgot-password" class="">Forgot Password?</a>
                                    </div>
                                    <button type="submit" class="login-btn" [disabled]="!loginForm.valid">
                                        <span>Login</span>
                                    </button>
                                    <a routerLink="/login/register" class="pull-right guest-link guest-btn" *ngIf="previousPageURL != '/order-tracking'">New to Homeful Gifts?</a>
                                    <a (click)="onContinueGuestClick()" class="pull-right guest-link guest-btn" *ngIf="previousPageURL == '/order-tracking'">Continue as Guest?</a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #orderDetailGatherPopup let-modal>
    <div class="custom-modal">
        <div class="modal-header">
            <h4 class="page-title">Order Details</h4>
            <a href="javascript:void(0)" class="close" (click)="dialogRef.close()" style="color: #fff !important;">
                <i class="fa fa-times" aria-hidden="true"></i>
            </a>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-md-12 form-group">
                    <label for="emailAddress">Email Address:</label>
                    <input type="email" class="form-control" id="emailAddress" [(ngModel)]="customerEmail" placeholder="Enter your email">
                </div>
                <div class="col-md-12 form-group">
                    <label for="orderNumber">Order Number:</label>
                    <input type="text" class="form-control" id="orderNumber" [(ngModel)]="orderNumber" placeholder="Enter order number">
                </div>
            </div>
            <div class="col-md-12 text-center center-block cust-modal-footer mt-20">
                <button type="submit" class="btn bg-theme" id="Cancel" (click)="dialogRef.close()">Cancel</button>
                <button type="button" class="btn bg-theme ml-5" [disabled]="customerEmail == '' || orderNumber == '' || customerEmail == null || orderNumber == null" (click)="getOrderDetails();dialogRef.close()">Find</button>
            </div>
        </div>
    </div>
</ng-template>
