import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminPageComponent } from './admin-page/admin-page.component';
import { ItemDetailsComponent } from './item-details/item-details.component';

const routes: Routes = [
  {
    path: '', children: [
      {
        path: 'admin-page', component: AdminPageComponent
      },
      {
        path: 'item-detail/:id/:customerNum', component: ItemDetailsComponent
      },
      {
        path:'item-detail',component:ItemDetailsComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
