<div class="dialog-header dh-back dh-htxt">
    <h2 mat-dialog-title style="margin: 0px !important;">{{data.title}}
    </h2>
</div>
<mat-dialog-content>
    <div>
        <p [innerHTML]="data.msg"></p>
    </div>
    <div *ngIf="data.isCheckbox">
        <!-- <div class="form-check pad-left-0">
            <label>
                <input type="checkbox" name="check" [(ngModel)]="data.isChecked" #check="ngModel">
                <span class="label-text">{{data.chkMsg}}asd</span>
            </label>
        </div> -->
    </div>
</mat-dialog-content>
<mat-dialog-actions class="pull-right">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 make-default pull-left" style="max-width: 70%" *ngIf="data.isCheckbox">
        <label class="">
            <input type="checkbox" name='check' [checked]="data.isChecked" [(ngModel)]="data.isChecked"><i
                class="fa fa-square-o"></i><i class="fa fa-check-square-o"></i>
            <span>&nbsp;{{data.chkMsg}}</span>
        </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </div>
    <button class="btn btn-primary mb-10" type="reset()" (click)="onCancelClick()" *ngIf="data.cancel">
        <span>
            {{data.cancel}}
        </span>
    </button>&nbsp;
    <button class="btn btn-primary mb-10" type="submit" [mat-dialog-close]="data">
        <span>
            {{data.ok}}
        </span>
    </button>
</mat-dialog-actions>
