import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/shared/services/data.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilityService } from 'src/app/shared/services/utilityService';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-my-address',
  templateUrl: './my-address.component.html',
  styleUrls: ['./my-address.component.css']
})
export class MyAddressComponent implements OnInit {
  isAddAddress: boolean = false;
  currentUser: any;
  bilAddressInfolist: any;
  shippingAddressForm: FormGroup;
  isDefaultAddress: boolean = false;
  addressTypes: any;
  addressList: any = {};
  countries: any;
  stateList: any;
  selectedcountry: any;
  isEditAddress: boolean = false;
  addressCorrectionsList: any;
  finalAddressRecommandation: any;
  suggestedValues: {};
  finalAddressEntered: string;
  @ViewChild('AddressCorrectionPopup') AddressCorrectionPopup: TemplateRef<any>;
  dialogRef: any;
  selectedAddress: number = 2;
  systemDetails: string;

  constructor(
    private router: Router,
    private _dataService: DataService,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    public utilservice: UtilityService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    public titleService : Title
  ) {
    this.spinner.show();
    this.currentUser = JSON.parse(localStorage.getItem('currentHFGUser'));
    this.addressTypes = JSON.parse(localStorage.getItem('types'));
    this.systemDetails = localStorage.getItem('systemUniqueIP');
    this.titleService.setTitle('My Address | Homeful Gifts');

    this.shippingAddressForm = fb.group({
      'contact_Name': [null, Validators.compose([Validators.required, Validators.maxLength(30)])],
      'company': [null, Validators.compose([Validators.maxLength(50)])],
      'address1': [null, Validators.compose([Validators.required])],
      'address2': [null],
      'city': [null, Validators.compose([Validators.required])],
      'state': [null, Validators.compose([Validators.required])],
      'country': [null, Validators.compose([Validators.required])],
      'zipcode': [null, Validators.compose([Validators.required])],
      'phone_Number': [null, Validators.compose([Validators.required, Validators.minLength(10)])],
      'email': [null, [Validators.required, Validators.pattern(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/)]],
      'shipper': '',
      'shippertype': '',
      'mainAddress': '',
      'residentialadd': '',
    });
  }

  ngOnInit(): void {
    let currentUser = localStorage.getItem('currentHFGUser');
    if (currentUser == null || currentUser == undefined) {
      this.router.navigate(['/']);
    }
    this.getCustomerSHPAddressesInfo('SHP');
    this.getCountries();
  }

  /**
   * Fetch customer shipping addresses.
   * @param addtype The type of address to fetch.
   */
  getCustomerSHPAddressesInfo(addtype: string): void {
    this.spinner.show();
    var AddressReq = {
      "customerNumber": this.currentUser.customerNumber,
      "addressType": addtype,
      "deviceID": this.systemDetails,
      "addressNumber": 0
    };

    this._dataService.post('app/GetHFGCustomerAddresses', JSON.stringify(AddressReq))
      .subscribe((response) => {
        this.bilAddressInfolist = response.data;
        this.spinner.hide();
      }, (error) => {
        this.spinner.hide();
      });
  }

  /**
   * Handle the delete address click event.
   * @param addressValue The address value to delete.
   */
  onDeleteClick(addressValue: any): void {
    let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { title: "Address Deletion", msg: "Are you sure you want to delete this Address?", ok: 'Confirm', cancel: 'Cancel' },
      width: 'auto',
      height: 'auto',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        var addressDetails = {
          "customerNumber": this.currentUser.customerNumber,
          "addressNumber": addressValue.hgGenericAddressID
        };
        this.spinner.show();

        this._dataService.post('app/DeleteHFGCustomerAddress', JSON.stringify(addressDetails))
          .subscribe((response) => {
            this.isAddAddress = false;
            this.isEditAddress = false;
            this.addressList = {};
            this.shippingAddressForm.reset();
            this._dataService.alert(response.statusMessage, '', 'success');
            this.getCustomerSHPAddressesInfo('SHP');
          }, (error) => {
            this.spinner.hide();
            this._dataService.alert("Can't delete default address", '', 'error');
          });
      }
    });
  }

  /**
   * Add or update customer shipping address.
   * @param isFirstRequest Indicates if this is the first request.
   */
  addUpdateShpCustomerAddress(isFirstRequest: number): void {
    if (this.shippingAddressForm.status != "INVALID") {
      this.spinner.show();
      var addressDetails = {
        "customerNumber": this.currentUser.customerNumber,
        "addressNumber": this.addressList.hgGenericAddressID == null || undefined ? 0 : this.addressList.hgGenericAddressID,
        "addressType": "SHP",
        "contactName": this.shippingAddressForm.value.contact_Name,
        "mainAddress": this.isDefaultAddress ? 1 : 0,
        "address1": this.shippingAddressForm.value.address1,
        "address2": this.shippingAddressForm.value.address2,
        "city": this.shippingAddressForm.value.city,
        "state": this.shippingAddressForm.value.state,
        "zipCode": this.shippingAddressForm.value.zipcode,
        "country": this.shippingAddressForm.value.country,
        "phoneNumber": this.utilservice.removeSpecials(this.shippingAddressForm.value.phone_Number),
        "alternateEMail": null,
        "eMail": this.shippingAddressForm.value.email,
        "residentialAdd": 0,
        "longitude": 0.0,
        "latitude": 0.0,
        "addToBillingAndShipping": 0,
        "registerGuestCustomer": 0,
        "salesDevice": "WEB",
        "deviceID": this.systemDetails,
        "isFirstRequest": isFirstRequest ? false : true
      };

      this._dataService.post('app/customerShippoValidation', JSON.stringify(addressDetails))
        .subscribe((response) => {
          if (response.statusCode == 200) {
            this.isAddAddress = false;
            this.isEditAddress = false;
            this.addressList = {};
            this.shippingAddressForm.reset();
            if (this.dialogRef)
              this.dialogRef.close();
            this._dataService.alert(response.statusMessage, 'Response', 'success');
            this.getCustomerSHPAddressesInfo('SHP')
          }
          else if (response.length > 0) {
            this.shippoValidationPopup(response);
          }
          this.spinner.hide();
        }, (error) => {
          this.spinner.hide();
          // this.shippingAddressForm.reset();
          this._dataService.alert(error.error ? (error.error.message ? error.error.message : "Please enter a valid Address") : "Please enter a valid Address", '', 'error');
        });
    } else {
      // this.shippingAddressForm.reset();
      this._dataService.alert("Please fill all the required fields", "", 'error');
    }
  }

  /**
   * Handle edit address click event.
   * @param addressValue The address value to edit.
   */
  onEditClick(addressValue: any): void {
    this.onButtonClick();
    this.isAddAddress = false;
    this.isEditAddress = true;
    this.isDefaultAddress = addressValue.defaultAddress == 0 ? false : true;
    this.addressList.contactName = addressValue.contactName;
    this.addressList.address1 = addressValue.address1;
    this.addressList.address2 = addressValue.address2;
    this.addressList.city = addressValue.city;
    this.addressList.state = addressValue.state;
    this.addressList.country = addressValue.country;
    this.addressList.zipcode = addressValue.zipCode;
    this.addressList.phone_Number = addressValue.phoneNumber;
    this.addressList.emailAddress = addressValue.emailAddress;
    this.addressList.hgGenericAddressID = addressValue.hgGenericAddressID;
  }

  /**
   * Fetch list of countries.
   */
  getCountries(): void {
    this._dataService.getAll('Config/GetCountries')
      .subscribe((response) => {
        this.countries = response.data.filter(x => x == 'USA');
        this.getStates();
      });
  }

  /**
   * Fetch list of states.
   */
  getStates(): void {
    this._dataService.getAll('Config/GetStates')
      .subscribe((response) => {
        this.stateList = response.data;
      });
  }

  /**
   * Handle cancel button click event.
   */
  cancelClick(): void {
    this.isAddAddress = false;
    this.isEditAddress = false;
    this.addressList = {};
    this.shippingAddressForm.reset();
  }

  /**
   * Display snackbar message.
   * @param message The message to display.
   * @param action The action to perform.
   */

  /**
   * Scroll to the top of the page.
   */
  onButtonClick(): void {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  /**
   * Allow only number inputs.
   * @param event The keyboard event.
   */
  numberOnly(event: any): void {
    const numberpattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!numberpattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  /**
   * Open Shippo validation popup.
   * @param response The response from the Shippo validation service.
   */
  shippoValidationPopup(response: any): void {
    this.addressCorrectionsList = response;
    var suggestedValues = {};
    this.addressCorrectionsList.forEach((element: any) => {
      suggestedValues[element.columnName] = element.suggestedValues;
    });
    this.suggestedValues = suggestedValues;
    this.finalAddressRecommandation = suggestedValues["address1"] !== undefined ? suggestedValues["address1"].toString() : this.shippingAddressForm.value.address1.toString() + ', ';
    this.finalAddressRecommandation += (suggestedValues["address2"] !== undefined ? suggestedValues["address2"].toString() : this.shippingAddressForm.value.address2) + ', ';
    this.finalAddressRecommandation += (suggestedValues["city"] !== undefined ? suggestedValues["city"].toString() : this.shippingAddressForm.value.city.toString()) + ', ';
    this.finalAddressRecommandation += (suggestedValues["state"] !== undefined ? suggestedValues["state"].toString() : this.shippingAddressForm.value.state.toString()) + ', ';
    this.finalAddressRecommandation += (suggestedValues["zipCode"] !== undefined ? suggestedValues["zipCode"].toString() : this.shippingAddressForm.value.zipcode.toString());
    this.finalAddressRecommandation = this.finalAddressRecommandation.replace('null', '');
    this.finalAddressEntered = `${this.shippingAddressForm.value.address1}, ${this.shippingAddressForm.value.address2}, ${this.shippingAddressForm.value.city}, ${this.shippingAddressForm.value.state}- ${this.shippingAddressForm.value.zipcode}`;

    this.dialogRef = this.dialog.open(this.AddressCorrectionPopup);
  }

  /**
   * Close the dialog popup.
   */
  closeDialog(): void {
    this.dialogRef.close();
  }

  /**
   * Save the recommended address from Shippo validation.
   */
  saveSelectedAddress(): void {
    if (this.selectedAddress == 2) {
      this.shippingAddressForm.patchValue({
        'address1': (this.suggestedValues["address1"] !== undefined ? this.suggestedValues["address1"].toString() : this.shippingAddressForm.value.address1),
        'address2': (this.suggestedValues["address2"] !== undefined ? this.suggestedValues["address2"].toString() : this.shippingAddressForm.value.address2),
        'city': (this.suggestedValues["city"] !== undefined ? this.suggestedValues["city"].toString() : this.shippingAddressForm.value.city),
        'state': (this.suggestedValues["state"] !== undefined ? this.suggestedValues["state"].toString() : this.shippingAddressForm.value.state),
        'zipcode': (this.suggestedValues["zipCode"] !== undefined ? this.suggestedValues["zipCode"].toString() : this.shippingAddressForm.value.zipcode)
      });
    }
    this.shippingAddressForm.markAsTouched();
    // this.addUpdateShpCustomerAddress(1);
    this.insertUpdateCustomerAddress();
  }
  addressValidation(isFirstRequest: boolean) {
    var addressDetails = {
      "contactName": this.shippingAddressForm.value.contact_Name,
      "address1": this.shippingAddressForm.value.address1,
      "address2": this.shippingAddressForm.value.address2,
      "city": this.shippingAddressForm.value.city,
      "state": this.shippingAddressForm.value.state,
      "zipCode": this.shippingAddressForm.value.zipcode,
      "country": this.shippingAddressForm.value.country,
      "phoneNumber": this.utilservice.removeSpecials(this.shippingAddressForm.value.phone_Number),
      "eMail": this.shippingAddressForm.value.email,
      "longitude": 0.0,
      "latitude": 0.0,
      "isFirstRequest": isFirstRequest
    };
    this.spinner.show();
    this._dataService.post('app/customerShippoValidationSimpler', JSON.stringify(addressDetails))
      .subscribe((response) => {
        if (response.statusCode == 200) {
          this.insertUpdateCustomerAddress();
        }
        else if (response.statusCode === 409) {
          if (!response) {
            this.spinner.hide();
            this._dataService.alert("Please enter the valid details", "", 'info');
          } else {
            this.router.navigate(['/login']).then(() => {
              window.location.reload();
            });
          }
        }
        else if (response.length > 0) {
          this.spinner.hide();
          this.shippoValidationPopup(response);
        }
      }, (error) => {
        this.spinner.hide();
        let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          data: { title: "Address Deletion", msg: "The Provided Address is Invalid. Do you want to Continue?", ok: 'Continue', cancel: 'Cancel' },
          width: 'auto',
          height: 'auto',
          disableClose: true,
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result != undefined) {
            this.insertUpdateCustomerAddress();
          }
        });
        // this._dataService.alert(error.error, "", 'info');
      })
  }
  insertUpdateCustomerAddress() {
    var addressDetails = {
      "customerNumber": this.currentUser.customerNumber,
      "addressNumber": this.isEditAddress ? this.addressList.hgGenericAddressID : 0,
      "addressType": "SHP",
      "contactName": this.shippingAddressForm.value.contact_Name,
      "mainAddress": this.isDefaultAddress ? 1 : 0,
      "address1": this.shippingAddressForm.value.address1,
      "address2": this.shippingAddressForm.value.address2,
      "city": this.shippingAddressForm.value.city,
      "state": this.shippingAddressForm.value.state,
      "zipCode": this.shippingAddressForm.value.zipcode,
      "country": this.shippingAddressForm.value.country,
      "phoneNumber": this.utilservice.removeSpecials(this.shippingAddressForm.value.phone_Number),
      "phoneNumberExt": "",
      "eMail": this.shippingAddressForm.value.email,
      "residentialAdd": 0,
      "longitude": 0.0,
      "latitude": 0.0,
      "addToBillingAndShipping": 0,
      "registerGuestCustomer": 0,
      "deviceID": this.systemDetails
    };

    this._dataService.post('app/InsertUpdateCustomerAddress', JSON.stringify(addressDetails)).subscribe((response) => {
      this.isAddAddress = false;
      this.isEditAddress = false;
      this.addressList = {};
      this.shippingAddressForm.reset();
      if (this.dialogRef)
        this.dialogRef.close();
      this._dataService.alert(response.statusMessage.message, '', 'success');
      this.getCustomerSHPAddressesInfo('SHP');
      localStorage.setItem('isFromPayment', JSON.stringify(false));
    }, (error) => {
      this.spinner.hide();
    });
  }
  onAddAddressClick(){
    this.addressList.country = "USA";
    this.isAddAddress = !this.isAddAddress; 
    this.addressList.state = '';
  }
}
