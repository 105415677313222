<div class="login-register-area pt-20 pb-30">
    <div class="container">
        <div class="row">
            <div class="col-xl-5 col-lg-8 col-md-10 ml-auto mr-auto">
                <div class="login-logo text-center mb-30">
                    <img src="assets/img/logo/logo-b.png" class="img-responsive">
                </div>
                <div class="login-register-wrapper">
                    <div class="login-form-container" id="login">
                        <div class="login-register-form">
                            <h3 class="pb-10 title-2 mb-20">Order Status</h3>
                            <form action="#" method="post" [formGroup]="orderSubmissionForm">
                                <input formControlName="orderNumber" type="text" name="user-name" placeholder="Order Number*" (keypress)="numberOnly($event)" maxlength="5">

                                <input formControlName="email" id="password-field" type="text"
                                    class="form-control" name="email" placeholder="Email*" maxlength="100">
                                    <!-- <input formControlName="email" id="password-field" type="text"
                                    class="form-control" name="email" placeholder="Email /  Phone Number*" maxlength="100"> -->
                                
                                <div class="button-box text-center">
                                    <button type="submit" [disabled]="!orderSubmissionForm.valid" class="login-btn find-btn" (click)="getOrderTrackingDetails()"><span>Find Order</span></button>
                                </div>
                                <div class="or-block">
                                    <div style="border-top:1px solid #dedede"></div>
                                    <p>OR</p>
                                </div>
                                <div class="return-block">
                                    <h2>Returning Customer?</h2>
                                    <p>Sign in to your homefulgifts.com to view your order history.</p>
                                </div>
                                <div class="button-box text-center">
                                    <button type="submit" class="login-btn find-btn signin-btn" routerLink="/login"><span>Sign In</span></button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>