import {Injectable} from '@angular/core';


@Injectable()

export class UtilityService {

  replaceHyphen(name :any){ 
    if(name!=null){   
    let replace = /[\/\\#,+()$~%.'":*?<>{} ]/gi;
    var result = name.replace(replace, "-");
    return result;
    } 
  }
  
  removeHyphen(name :any){ 
    if(name!=null){
      let replace = /-/g;
      var result = name.replace(replace, " ");
      return result;
    }
  }
  removeSpaces(name :any){ 
    if(name!=null){
      let replace = /[\s]/g;
    //  return string.replace(/[\s]/g, '');
      var result = name.replace(replace,'' );
      return result;
    }
  }


  removeSpecials(name :any){ 
    if(name!=null){   
    let replace = /[\/\-\#,+()$~%.'":*?<>{} ]/gi;
    var result = name.replace(replace, '');
    return result;
    }
  }

  removeStrtSpace(name :any){ 
    if(name!=null){
      let replace = /^\s+/g;
    //  return string.replace(/[\s]/g, '');
      var result = name.replace(replace,'' );
      return result;
    }

  }
  creditcard(plainCreditCard: string): string {
    var result= plainCreditCard.replace(/\s+/g, '').replace(/(\d{4})/g, '$1 ').trim();
    return result;
  }

}